import * as React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ActivityIndicator, ScrollView } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons"
import { YAxis, XAxis, Grid } from 'react-native-svg-charts'
import * as shape from 'd3-shape'
import moment from 'moment'

const suffixForAgeRanges = {
  avg:"AverageScore",
  std:"ScoreStandardDeviation",
}

const dataPoints = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]
const barChartHeight = 300

function BarChart (props){
  return(
    <View style={{flexDirection: 'row', width: '100%', height: barChartHeight, marginTop: 16 }}>
      <View style={{ height: barChartHeight - 20, justifyContent: 'space-between' }}>
        {
          dataPoints.map((item, index)=> {
            return (
              <Text key={item + 'x' + index} style={[FontStyles.caption2, { color: Colors.dark.alpha1 }]}>
                {item}
              </Text>
            )
          })
        }
      </View>
      <ScrollView 
        style={[styles.barChartContainer, {} ]}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {
          props.data.map((item, index)=> {
            return (
              <View key={index + 's' + item.value } style={{ marginLeft: 16, justifyContent: 'flex-end', height: '100%', alignItems: 'center' }}>
                <View style={[styles.bar, { height: (item.value / 100) * (barChartHeight - 20) }]}/>
                <Text style={[FontStyles.caption1, { fontSize: 10, textAlign: "center", color: Colors.gray.alpha1, height: 12, marginTop: 8 }]}>
                  {moment.unix(item.date).format('MMM D')}
                </Text>
              </View>

            )
          })
        }
      </ScrollView>
    </View>
    
  )
}

function TrackingGamesChart(props) {

  const fill = Colors.greenLight.alpha1

  return (
    <View style={[styles.chartContainer, props.containerStyle]}>
      <View style={{flexDirection: "row", alignItems: "center"}}>
        <Text style={[FontStyles.subhead, {flex: 1, fontWeight: "600", color: Colors.grayDark.alpha1 }]}>
          {props.title}
        </Text>
      </View>
      
      <View style={{ flexDirection: "row", marginTop: 10, alignItems: "center" }}>
        <View style={{alignItems: "center"}}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            {props.ageKey ?  (props.ageKey.text + " Yaş Ortalaması: ") : "Genel Ortalama: "}
          </Text>
          <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats ? props.ageKey ? props.genericStats[props.ageKey.key + suffixForAgeRanges.avg] ? props.genericStats[props.ageKey.key + suffixForAgeRanges.avg].toFixed(1) : "-" :  props.genericStats.generalAverageScore.toFixed(1) :"-"}
          </Text>
        </View>

        <View style={{alignItems: "center", marginLeft: "5%"}}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            {"Kullanıcı Ortalaması: "}
          </Text>
          <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats ? props.genericStats.userAverageScore ? props.genericStats.userAverageScore.toFixed(1) : "-" : "-"}
          </Text>
        </View>

        <View style={{alignItems: "center", marginLeft: "5%"}}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            {"Standart Sapma: "}
          </Text>
          <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats ? props.ageKey ? props.genericStats[props.ageKey.key + suffixForAgeRanges.std] ? props.genericStats[props.ageKey.key + suffixForAgeRanges.std].toFixed(1) : "-" :  "Yaş girmemiş." :"-"}
          </Text>
        </View>
      </View>

      {
        !props.data ? 
        <ActivityIndicator style={{ height: 300 }} color={Colors.greenLight.alpha1} size="large" />
        :
        <BarChart 
          data={props.data} 
          />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  chartContainer: {
    backgroundColor: Colors.white.alpha1,
    paddingHorizontal: 15,
    paddingVertical: 15,
    margin: 15,
    alignSelf: "baseline",
    borderRadius: 15,
    flex: 1,
    minWidth: 300
  },
  bar: {
    width: 30,
    backgroundColor: Colors.greenLight.alpha1,
    borderRadius: 3,
  },
  barChartContainer: {
    flex: 1,
    height: barChartHeight,
  }
})

export default TrackingGamesChart;