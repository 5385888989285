import * as React from 'react';
import {Image, View, Text, StyleSheet} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import Markdown from "react-native-markdown-display";

const WIDTH_MULTIPLIER = 320;

const turkishLoremIpsumMarkdown = `*Çünkü göze çarpan çünkü ötekinden dolayı bundan dolayı batarya kutusu ötekinden dolayı bundan dolayı ama çakıl. Çakıl masanın adanaya kapının kulu çobanın masanın bahar bilgisayarı düşünüyor şafak. **Bu yazı placeholder olarak konmuştur.** Umut ışık dağılımı şafak sıradanlıktan kalemi ötekinden dolayı anlamsız ışık dağılımı umut yapacakmış bahar hesap makinesi lakin. Uzattı dışarı çıktılar otobüs anlamsız gülüyorum hesap makinesi de adanaya sandalye öyle ki. Salladı anlamsız adanaya lakin karşıdakine adanaya çobanın salladı de beğendim. Ötekinden dolayı koştum gördüm salladı mutlu oldular.*

*Balıkhaneye hesap makinesi sarmal açılmadan dolayı bundan dolayı bundan dolayı. Filmini mi teldeki kapının kulu layıkıyla sıla ışık dağılımı şafak bahar patlıcan duyulmamış. Gidecekmiş koşuyorlar yazın sıla patlıcan salladı. Türemiş sıfat ona doğru telefonu adanaya ötekinden dolayı kalemi mıknatıslı okuma sayfası sokaklarda orta camisi. Koştum gülüyorum bilgisayarı sokaklarda telefonu mutlu oldular sandalye değerli olduğu için telefonu koştum dışarı çıktılar tv sıradanlıktan. Bilgisayarı sinema teldeki de ona doğru duyulmamış.*

*Koştum yapacakmış türemiş sıfat dışarı çıktılar şafak umut çobanın sıradanlıktan patlıcan cesurca yaptı. Domates bahar bilgiyasayarı gidecekmiş adresini gördüm kalemi yazın hesap makinesi sinema. Yapacakmış koştum sinema lakin gitti ötekinden dolayı. Ve sokaklarda mıknatıslı okuma sayfası öyle ki yazın yazın.* **Bu yazı placeholder olarak konmuştur.** *Masaya doğru uzattı bundan dolayı teldeki şafak balıkhaneye mıknatıslı okuma sayfası sokaklarda sıradanlıktan bilgiyasayarı batarya kutusu anlamsız salladı. Balıkhaneye gördüm sevindi kapının kulu şafak adresini. Anlamsız lakin ve dergi otobüs çakıl ve ekşili çorba duyulmamış.*

*Patlıcan umut patlıcan cezbelendi kalemi ışık dağılımı sıla gül teldeki bilgiyasayarı koştum gitti layıkıyla. Tv patlıcan düşünüyor sinema anlamsız. Layıkıyla yazın çakıl hesap makinesi sinema. Mıknatıslı okuma sayfası öyle ki umut mutlu oldular değerli olduğu için düşünüyor çünkü filmini mi batarya kutusu gazete. Salladı umut türemiş sıfat öyle ki gazete tv gitti koşuyorlar mutlu oldular sıradanlıktan öyle ki otobüs düşünüyor. Sıradanlıktan sinema öyle ki adresini batarya kutusu dergi hesap makinesi tv bundan dolayı ama ışık dağılımı balıkhaneye balıkhaneye karşıdakine. Koyun ötekinden dolayı gülüyorum orta camisi dergi gülüyorum adresini bilgisayarı ekşili çorba çakıl un değirmeni bilgiyasayarı. Hesap makinesi sıradanlıktan adresini sinema tv teldeki.*`

export default class BlogDisplay extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            fontSize: 17,
            lineHeight: 27,
        }
    }

    render() {
        return (
            <View style={styles.blogContainer}>
                <Text style={[FontStyles.title1, {textAlign: 'center', alignSelf: 'center', fontWeight: '700', marginTop: 24,}, !this.props.title ? {fontStyle: 'italic'} : {}]}>
                    {this.props.title || "Blog Başlığı"}
                </Text>

                {
                    (this.props.media) ?
                        <Image resizeMode={"contain"} style={{marginTop: 24, paddingHorizontal: 40, flex: 1, height: 400,}} source={(typeof this.props.media === 'string' || this.props.media.uri) ? this.props.media : require('../assets/images/blog-placeholder.jpg')}/> : null
                }

                <View style={{width: '100%', height:48}}/>

                <Markdown
                    style={{
                        body: {fontSize: this.state.fontSize, color: Colors.grayDark.alpha09, lineHeight: this.state.lineHeight},
                        heading1: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.075,fontSize: this.state.fontSize*2, lineHeight: this.state.lineHeight*2, color: Colors.grayDark.alpha1, alignSelf:'flex-start'},
                        heading2: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.075, fontSize: this.state.fontSize*1.5,lineHeight: this.state.lineHeight*1.5, color: Colors.grayDark.alpha1, alignSelf:'flex-start'},
                        heading3: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.05, fontSize: this.state.fontSize*1.17, lineHeight: this.state.lineHeight*1.17, color: Colors.grayDark.alpha1,},
                        heading4: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.05, fontSize: this.state.fontSize, lineHeight: this.state.lineHeight*1, color: Colors.grayDark.alpha1,},
                        heading5: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.05, fontSize: this.state.fontSize*0.83, lineHeight: this.state.lineHeight*0.83, color: Colors.grayDark.alpha1,},
                        heading6: {fontWeight: 'bold', marginTop: WIDTH_MULTIPLIER * 0.05, fontSize: this.state.fontSize * 0.75,lineHeight: this.state.lineHeight*0.75,  color: Colors.grayDark.alpha1,},
                        blockquote: {marginVertical: WIDTH_MULTIPLIER* 0.025, fontSize: this.state.fontSize*1.11, lineHeight: this.state.lineHeight*1.11, marginLeft: WIDTH_MULTIPLIER * 0.017, paddingLeft: WIDTH_MULTIPLIER * 0.033, borderColor: Colors.greenLightDark.alpha1, backgroundColor: 'rgba(255,255,255, 0)', color: Colors.greenLightDark.alpha1, borderLeftWidth: 2, fontStyle: 'italic'},
                        ordered_list: {marginVertical: 2},
                        strong: {fontWeight: "600", color: Colors.greenLightDark.alpha1},
                        bullet_list: {marginVertical: 2,},
                        list_item: {marginVertical: 6},
                        textgroup: {marginBottom: this.state.fontSize/3},
                        ordered_list_icon: {fontWeight: 'bold'},
                        bullet_list_icon: {fontWeight: 'bold'},
                        link: {color: Colors.aquaDark.alpha1},
                        image: {marginVertical: 16, marginHorizontal: '14%'},
                    }}>
                    {this.props.text || turkishLoremIpsumMarkdown}
                </Markdown>
            </View>
        )
    }
};


const styles=StyleSheet.create({
    blogContainer: {
        width: "100%",
        marginTop: 30,
        paddingVertical: 10,
        paddingHorizontal: 32,
        backgroundColor: Colors.white.alpha1,
        borderRadius: 15,
        alignSelf: "center",
    },

})