import i18n from 'i18n-js';
import AsyncStorage from '@react-native-community/async-storage';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Device from 'expo-device';

const BASE_PATH = "https://api.beynex.com/api/v1"
const STORAGE_SESSION_PATH = 'beynexPanel/currentSessionId';
const STORAGE_USER_PATH = 'beynexPanel/currentUser';
const STORAGE_USER_FORUM_LANGUAGE_PATH = 'beynexPanel/currentUser/forumLanguage';
const STORAGE_LOCAL_LANGUAGE_PATH = 'beynexPanel/localLanguage';
const STORAGE_LAST_SESSION_RENEWAL_DATE_PATH = 'beynexPanel/lastSessionRenewalDate';

const API = {
	_storage: {
		getItem: AsyncStorage.getItem,
		setItem: AsyncStorage.setItem,
		removeItem: AsyncStorage.removeItem,
	},

	DEFAULT_LANGUAGE: 'tr',

	/*--------- BASE REQUEST FUNCTION ------------*/
	makeHttpRequest: function(relativePath, method, params = null) {
		return Promise.all([
			this.getCurrentSessionId(),
			this.getLocalLanguage()
		]).then(([sessionId, localLanguage]) => {
			if (params === undefined || params === null || (typeof params) !== 'object') {
				params = null;
			}
			let fetchOptions = {
				method: method,
				headers: {
					'Accept': 'application/json',
					'Api-Language': 'tr',
				}
			};
			if (sessionId) {
				fetchOptions.headers['Cache-Id'] = sessionId;
			}
			// GET requests do not use body, so we do not add Content-Type header or body to the request
			if (method !== 'GET') {
				fetchOptions.headers['Content-Type'] = 'application/json';
				if (params) {
					fetchOptions.body = JSON.stringify(params);
				} else {
					fetchOptions.body = '{}';
				}
			}

			if (relativePath.substr(0, 1) === '/') {
				relativePath = relativePath.substr(1, relativePath.length);
			}

			return fetch(BASE_PATH + '/' + relativePath, fetchOptions)
				.then((response) => { //this then includes catch as well.
					if (response.status < 400) {
						if ((relativePath === 'auth/login' || relativePath === 'auth/signup') && response.headers && typeof response.headers.get('Cache-Id') === 'string' && response.headers.get('Cache-Id').length > 0) {
							return this.setCurrentSessionId(response.headers.get("Cache-Id"))
								.then(() => {
									return response.json()
										.then((body) => {
											return Promise.all([this.setCurrentUser(body.user), this.setLocalLanguage(body.user.language)])
												.then(() => {
													return Promise.resolve(body);
												})
												.catch(() => {
													return Promise.resolve(body);
												});
										})
										.catch((e) => {
											return this.throwServerError(e);
										});
								})
						} else if (relativePath === 'panel/neutec-campaign-users-data-download' || relativePath === 'panel/neutec-research-campaign-users-data-download'){
							return response.blob()
								.then(myBlob => {
									let objectURL = URL.createObjectURL(myBlob);
									return Promise.resolve({
										file: objectURL,
										filename: response.headers.get('Content-Disposition')?.split("filename")?.[1]?.replaceAll("\"", "")?.replace(/\s/g, "")?.replaceAll("=", ""),
									});
								})
								.catch(e => {
									console.log(e);
									return this.throwServerError(e);
								});
						} else if (relativePath === 'auth/renew-current-session' && response.headers && typeof response.headers.get('Cache-Id') === 'string' && response.headers.get('Cache-Id').length > 0) {
							return this.setCurrentSessionId(response.headers.get("Cache-Id"))
								.then(() => {
									return Promise.resolve({});
								})
						} else {
							if (response.headers && typeof response.headers.get('Content-Type') === 'string' && response.headers.get('Content-Type').includes('application/json')) {
								return response.json()
									.then((body) => {
										if (relativePath.startsWith('users/current') && body.user) {
											return Promise.all([
													this.setCurrentUser(body.user),
													this.setLocalLanguage(body.user.language)
												])
												.then(() => {
													return Promise.resolve(body);
												})
												.catch(() => {
													return Promise.resolve(body);
												});
										} else if(relativePath.startsWith('training/games') && relativePath.split('/').length == 2 && body.currentUser) {
											return Promise.all([
													this.setCurrentUser(body.currentUser),
													this.setLocalLanguage(body.currentUser.language)
												])
												.then(() => {
													return Promise.resolve(body);
												})
												.catch(() => {
													return Promise.resolve(body);
												});
										} else {
											return Promise.resolve(body);
										}
									})
									.catch((e) => {
										return this.throwServerError(e);
									});
							} else {
								return Promise.resolve({});
							}
						}
					} else if (response.status === 401) { // unauthorized, session should have been expired
						return Promise.all([
								this.setCurrentUser(null),
								this.setCurrentSessionId(null)
							])
							.then(() => {
								if (response.headers && typeof response.headers.get('Content-Type') === 'string' && response.headers.get('Content-Type').includes('application/json')) {
									return response.json()
										.then((body, error) => { // this then includes catch as well.
											if (error) {
												return this.throwServerError(error);
											} else {
												return Promise.reject(body);
											}
										})
								} else {
									return Promise.reject({errorTitle: "Hata", errorMessage: "İnternet bağlantısı yok."});
								}
							})
					} else {
						if (response.headers && typeof response.headers.get('Content-Type') === 'string' && response.headers.get('Content-Type').includes('application/json')) {
							return response.json()
								.then((body, error) => { // this then includes catch as well.
									if (error) {
										return this.throwServerError(error);
									} else {
										return Promise.reject(body);
									}
								})
						} else {
							return Promise.reject({errorTitle: "Hata", errorMessage: "İnternet bağlantısı yok."});
						}
					}
				})
				.catch((error) => {
					if(!error.errorCode && !error.errorMessage){
							return this.throwServerError(error);
					} else {
							return Promise.reject(error);
					}
				})
		})
	},//ok


	/*--------- LOCAL SESSION ----------*/
	getCurrentSessionId: function() {
		return this._storage.getItem(STORAGE_SESSION_PATH)
	},//ok
	setCurrentSessionId: function(id) {
		if ((typeof id) !== 'string' || id.length === 0) {
			return this._storage.removeItem(STORAGE_SESSION_PATH)
				.then(() => {
					return Promise.resolve(true);
				})
		} else {
			return this._storage.setItem(STORAGE_SESSION_PATH, id)
				.then((val) => {
					return Promise.resolve(val);
				})
		}
	},//ok



	/*--------- LOCAL LANGUAGE ----------*/
	setLocalLanguage: function(language) {
		if (language) {
			i18n.locale = language;
			return this._storage.setItem(STORAGE_LOCAL_LANGUAGE_PATH, language);
		} else {
			i18n.locale = this.DEFAULT_LANGUAGE;
			return this._storage.removeItem(STORAGE_LOCAL_LANGUAGE_PATH);
		}
	},//ok
	// only if the first parameter is given as `true`, null results will be returned as they are.
	// otherwise, the default language will be returned in error or null cases.
	getLocalLanguage: function(revealNullCases = false) {
		return this._storage.getItem(STORAGE_LOCAL_LANGUAGE_PATH)
			.then((language) => {
				if (language) {
					return Promise.resolve(language);
				} else {
					return Promise.resolve(revealNullCases ? null : this.DEFAULT_LANGUAGE);
				}
			})
			.catch(() => {
				return Promise.resolve(revealNullCases ? null : this.DEFAULT_LANGUAGE);
			});
	},

	// if `this.DEFAULT_LANGUAGE` changes, update this part, too
	throwServerError: function(e) {
		let message = 'Bir bağlantı veya sunucu hatası yaşandı.';
		let title = 'Hata';
		return this.getLocalLanguage() // this function never throws.
			.then((language) => {
				// if the language is 'en' or unknown, we will show an Turkish message.
				if (language === 'en') {
					message = 'A connection or server error occured.';
					title = "Error";
				}
				return Promise.reject({
					errorCode: 601,
					errorTitle: title,
					errorMessage: message
				});
			})
	},



	/*--------- CURRENT USER LOCAL ----------*/
	getCurrentUser: function() {
		return this._storage.getItem(STORAGE_USER_PATH).then(val => {
			if (val) {
				return JSON.parse(val);
			} else {
				return Promise.resolve(null);
			}
		})
	},//ok
	setCurrentUser: function(user) {
		if (user) {
			return this._storage.setItem(STORAGE_USER_PATH, JSON.stringify(user))
				.then((val) => {
					return Promise.resolve(val);
				})
		} else {
			return this._storage.removeItem(STORAGE_USER_PATH)
				.then(() => {
					return Promise.resolve(true);
				})
		}
	},//ok
	getCurrentUserForumLanguage: function() {
		return this._storage.getItem(STORAGE_USER_FORUM_LANGUAGE_PATH).then(val => {
			if (val) {
				return val;
			} else {
				return Promise.resolve(null);
			}
		})
	},//ok
	setCurrentUserForumLanguage: function(language) {
		if (language) {
			return this._storage.setItem(STORAGE_USER_FORUM_LANGUAGE_PATH, language)
				.then((val) => {
					return Promise.resolve(val);
				})
		} else {
			return this._storage.removeItem(STORAGE_USER_FORUM_LANGUAGE_PATH)
				.then(() => {
					return Promise.resolve(true);
				})
		}
	},//ok



	/*----------------- AUTHENTICATION / USER -----------------*/
	login: function(identifier, password, mfaCode) {
		let devicePlatform = Platform.OS
    devicePlatform = devicePlatform.toUpperCase()
		let body = {
			identifier: identifier,
			password: password,
			mfaCode: mfaCode,
			devicePlatform: devicePlatform,
			installationId: Constants.installationId,
			deviceName: (Device.brand || Device.modelName) ? (Device.brand + " | " + Device.modelName) : null,
		}

		return this.makeHttpRequest('/auth/login', 'POST', body)
			.then((respBody) => {
				return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString())
					.then(()=>{
						return Promise.resolve(respBody);
					})
					.catch(()=>{
						return Promise.resolve(respBody);
					});
			})
	},//ok
	logout: function() {
		return this.makeHttpRequest('/auth/logout', 'POST', {}).then(() => {
			return Promise.all([
				this.setCurrentUser(null),
				this.setCurrentSessionId(null)
			])
		})
	},//ok
	logoutFromAllDevices: function() {
		return this.makeHttpRequest('/auth/logout-all', 'POST', {}).then(() => {
			return Promise.all([
				this.setCurrentUser(null),
				this.setCurrentSessionId(null)
			]);
		})
	},//ok
	getUserFromServer: function() {
		return this.makeHttpRequest('/users/current', 'GET', null);
	},//ok
	renewSession: function() {
		return this._storage.getItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH)
			.then((value)=>{
				if(value == null){
					return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString());
				}
				else{
					value = new Date(value);
					let oneMonthAgo = new Date();
					oneMonthAgo.setMonth(new Date().getMonth() - 1);
					
					if(value <= oneMonthAgo){
						let devicePlatform = Platform.OS
						devicePlatform = devicePlatform.toUpperCase()
						return this.makeHttpRequest('/auth/renew-current-session', 'POST', {devicePlatform: devicePlatform,	installationId: Constants.installationId,	deviceName: (Device.brand + " | " + Device.modelName)})
							.then(()=>{
								return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString());
							});
					}
					else{
						return Promise.resolve(true);
					}
				}
			});
	},


	//dashboard
	getStats:function() {
		return this.makeHttpRequest('/panel/stats?language=tr', 'GET', null);
	},//ok
	getUsers:function() {
		return this.makeHttpRequest('/panel/users', 'GET', null);
	},//ok
	getUser: function(id) {
		return this.makeHttpRequest('/panel/users/' + id, 'GET', null);
	},//ok
	getPackets: function() {
		return this.makeHttpRequest('/panel/packets?language=tr', 'GET', null);
	},//ok
	sendPush:  function(text, language, audience) {
		return this.makeHttpRequest('/panel/push-notifications', 'POST', {text, language, audience});
  },//ok
  getSupportTickets : function(limit, lessThan,getOnlyActiveTickets){
		let url = `/panel/support-tickets?limit=${limit}`;
		if(lessThan){
			url += `&lt=${lessThan}`
		}
		if(getOnlyActiveTickets !== null){
			url += `&is_conversation_going=${getOnlyActiveTickets}`
		}
    return this.makeHttpRequest(url, 'GET', null);
  },
  getMessages: function(ticketId){
    return this.makeHttpRequest(`/panel/support-tickets/${ticketId}/messages`, 'GET', null);
  },
  sendMessage : function(ticketId,text){
    return this.makeHttpRequest(`/panel/support-tickets/${ticketId}/messages`, 'POST', {text:text});
  },
  changeSupportTicketStatus : function(ticketId,isOpen){
    return this.makeHttpRequest(`/panel/support-tickets/${ticketId}/is-open`, 'PUT', {isOpen:isOpen});
  },
  changeUserLightModeStatus: function(userId,lightMode){
    return this.makeHttpRequest(`/panel/users/${userId}/light-mode`, 'PUT', {lightMode:lightMode});
  },
  createNewSupportTicket: function(clientId,title,text){
    return this.makeHttpRequest(`/panel/support-tickets`, 'POST', {clientId:clientId,title:title,text:text});
  },
	changeConversationStatus: function(ticketId,isConversationGoing){
    return this.makeHttpRequest(`/panel/support-tickets/${ticketId}/is-conversation-going`, 'PUT', {isConversationGoing:isConversationGoing});
  },
	getUsersDiscount: function(userId){
    return this.makeHttpRequest(`/panel/personal-discounts?user_id=${userId}`, 'GET', null);
  },
	getDiscounts: function(){
    return this.makeHttpRequest(`/panel/personal-discounts`, 'GET', null);
  },
	createNewDiscount: function(userId,discountPercentage){
    return this.makeHttpRequest(`/panel/personal-discounts`, 'POST', {userId:userId,discountPercentage:discountPercentage});
  },
	deleteDiscount: function(discountId){
    return this.makeHttpRequest(`/panel/personal-discounts/${discountId}`, 'DELETE', null);
  },

	createNewMembership: function (userId, source, interval, intervalCount, enableDementiaTest){
		return this.makeHttpRequest(`/panel/users/${userId}/membership`, 'POST', {source, interval, intervalCount, enableDementiaTest});
	},
	deleteUser: function (userId, mfaCode){
		return this.makeHttpRequest(`/panel/users/${userId}`, 'DELETE', {mfaCode});
	},
	getAllBlogPostsSummary: function (){
		return this.makeHttpRequest(`/panel/blog/posts`, 'GET', null);
	},
	getSingleBlogPost: function (slug, language){
		return this.makeHttpRequest(`/blog/posts?slug=${slug}&summary=false&language=${language}`, 'GET', null);
	},
	uploadBlogInnerPhoto: function (photo){
		return this.makeHttpRequest(`/panel/blog/inner-photo`, 'POST', {photo});
	},
	createBlogPost: function (title, subtitle, authorName, text, createdAt, slug, media, language){
		const params = {
			title, subtitle, authorName, text, createdAt, slug, media, language,
			mediaType: 'IMAGE',
		};
		console.log(params);
		return this.makeHttpRequest(`/panel/blog/posts`, 'POST', params);
	},
	deleteBlogPost: function (id){
		return this.makeHttpRequest(`/panel/blog/posts/${id}`, 'DELETE');
	},
	downloadNeutecCampaignData: function (){
		return this.makeHttpRequest(`/panel/neutec-campaign-users-data-download`, 'GET', {});
	},
	downloadNeutecResearchCampaignData: function (){
		return this.makeHttpRequest(`/panel/neutec-research-campaign-users-data-download`, 'GET', {});
	},
	getUserSupportTicket: function(userId){
		return this.makeHttpRequest(`/panel/users/${userId}/support-tickets`, 'GET', null);
	},

	getPlannedPushNotifications: function (){
		return this.makeHttpRequest(`/panel/planned-push-notifications`, 'GET', null);
	},

	createPlannedPushNotification: function (data){
		return this.makeHttpRequest(`/panel/planned-push-notifications`, 'POST', data);
	},

	updatePlannedPushNotification: function (id, data){
		return this.makeHttpRequest(`/panel/planned-push-notifications/${id}`, 'PUT', data);
	},

	deletePlannedPushNotification: function (id, data){
		return this.makeHttpRequest(`/panel/planned-push-notifications/${id}`, 'DELETE', null);
	},

	verifyEmailAddressOfUser: function (id) {
		return this.makeHttpRequest(`/panel/users/${id}/email-verified`, 'PUT', {emailVerified: true})
	},

	getNewAssessmentPushNotifications: function() {
		return this.makeHttpRequest(`/panel/new-assessment-push-notifications`, 'GET', null)
	},

	updateAllNewAssessmentPushNotifications: function(notifications) {
		return this.makeHttpRequest(`/panel/new-assessment-push-notifications`, 'PATCH', {
			newAssessmentPushNotifications: notifications
		})
	},

	submitActivationCodeForUser: function (userId, activationCode) {
		return this.makeHttpRequest(`/panel/users/${userId}/activation-code`, 'POST', {code: activationCode});
	},

	getAverageSubsDuration: function(filter){
		return this.makeHttpRequest(`/panel/data/average-premium-lifetime/${filter}`, 'GET', null)
	},
	
	checkActivationCode: function(code){
		return this.makeHttpRequest(`/panel/check-activation-codes`, 'POST', {code})
	}
	
};

export default API;
