import * as React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, ScrollView} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import {Feather} from "@expo/vector-icons";
import * as Device from 'expo-device';
import API from '../utils/API';
import moment from "moment"
import {CommonActions} from "@react-navigation/native";
import Modal from '../components/Modal';
import Loading from "../components/Loading";
import BlogCreate from "./BlogCreate";
import BlogDisplay from "../components/BlogDisplay";

export default class BlogDetail extends React.PureComponent {
    constructor(props) {
        super(props)
        this.trainingDataEmpty = true
        this.state={
            mediaBasePath: "",
            blogPost: {},
            device: "DESKTOP",
        };
    }

    componentDidMount() {
        this.loading2 && this.loading2.hide()
        if(this.props.route.params.slug !== "create"){
            this.getDeviceType().then(()=>{}).catch(()=>{});
            this.getBlogPost();
        }
    }

    getBlogPost=() => {
        const slug = this.props.route.params.slug.split('---')[1];
        const language = this.props.route.params.slug.split('---')[0];
        API.getSingleBlogPost(slug, language).then((response) => {
            this.loading && this.loading.hide();
            this.setState({
                mediaBasePath: response.mediaBasePath,
                blogPost: response.posts.length > 0 ? response.posts[0] : {},
            })
        }).catch((e) => {
            this.loading && this.loading.hide();
            if(e.errorCode === 7){
                alert(e.errorMessage);
                this.props.navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: "Login" }]
                    })
                )
            }
        })
        
    }
    
    deleteBlogPost = () => {
        if(new Date(this.state.blogPost.createdAt) < new Date() && !confirm('Bu blog gönderisi halihazırda yayında. Yine de silmek istiyor musunuz?')){
            return;
        }
        
        if(!confirm('Blog gönderisi silinecek. Onaylıyor musunuz?')){
            return;
        }
        
        this.loading2 && this.loading2.show();
        
        API.deleteBlogPost(this.state.blogPost.id).then(()=>{
            alert('Blog gönderisi başarıyla silindi.')
            this.loading2 && this.loading2.hide();
            this.goBackToBlog(true)
        })
            .catch((e) => {
                this.loading2 && this.loading2.hide();
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                } else {
                    alert('Blog gönderisi silinirken bir hata oluştu.')
                }
            })
    }

    getDeviceType=async () => {
        let device=await Device.getDeviceTypeAsync();
        this.setState({ device: Device.DeviceType[device] })
    }

    goBackToBlog = (forceBlogRefresh) => {
        let routesInStack = this.props.navigation.dangerouslyGetState().routes;
        if(routesInStack.length > 1 && routesInStack[routesInStack.length-2].name === "Blog"){
            let navigationReference = this.props.navigation;
            navigationReference.goBack();
            if(forceBlogRefresh){
                setTimeout(()=>{
                    navigationReference.replace("Blog");
                },500);
            }
        }
        else{
            this.props.navigation.replace('Blog');
        }
    }

    displayTimeWithTimezone = (time) => {
        let m = moment(time);
        let timezone = m.format('Z').split(":")[0];
        if (timezone.substr(1,1) === "0") {
            timezone = timezone.substr(0,1) + timezone.substr(2);
        }
        return m.format("Do MMMM YYYY , HH:mm") + " (" + timezone + ")"
    }

    render() {
        if(this.props.route.params.slug === "create"){
            return <BlogCreate navigation={this.props.navigation} goBackToBlog={this.goBackToBlog}/>
        }

        return (
            <View style={{ flex: 1 }}>
                <ScrollView
                    style={{ flex: 1}}
                    contentContainerStyle={[styles.container, {paddingBottom: 30, marginBottom: 30}]}
                >
                    <View style={[styles.header, { height: this.state.device==="PHONE"? 56:70 }]}>
                        <TouchableOpacity style={styles.goBackButton} onPress={() => this.goBackToBlog()} activeOpacity={0}>
                            <Feather name={"arrow-left"} size={23} color={Colors.greenLight.alpha1} />
                            <Text numberOfLines={1} style={[FontStyles.footnote, { marginLeft: 10, color: Colors.greenLight.alpha1, fontWeight: "600" }]}>
                                Tüm Bloglar
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{marginRight: 6,}} onPress={this.deleteBlogPost} activeOpacity={0.7}>
                            <Feather name={"trash-2"} size={21} color={Colors.red.alpha1} />
                        </TouchableOpacity>
                    </View>

                    <View style={styles.blogContainer}>
                        <BlogDisplay text={this.state.blogPost.text} media={this.state.mediaBasePath + this.state.blogPost.media} title={this.state.blogPost.title}/>
                    </View>
                    
                </ScrollView>
                <Loading ref={ref => this.loading = ref} text={"Yazı yükleniyor"} />
                <Loading ref={ref => this.loading2 = ref} text={"Yazı siliniyor"} initiallyClosed={true}/>

                <Modal ref={ref => this.editBlogModal = ref} containerStyle={{maxWidth: 400, maxHeight: 300}}>

                </Modal>
            </View>
        )
    }
};


const styles=StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 20,
        maxWidth: 800,
        alignSelf: 'center',
    },
    header: {
        height: 70,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: Colors.white.alpha1,
        borderRadius: 15,
        alignSelf: "center",
        justifyContent: "space-between",
        overflow: "visible"
    },
    goBackButton: {
        height: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.white.alpha1,
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 15,
    },
    blogContainer: {
        width: "100%",
        marginTop: 30,
        paddingVertical: 10,
        paddingHorizontal: 64,
        backgroundColor: Colors.white.alpha1,
        borderRadius: 15,
        alignSelf: "center",
    },

})