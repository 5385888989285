import * as React from 'react';
import { View, Text, TouchableOpacity, Animated,Switch, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { FlatList } from 'react-native-gesture-handler';
import * as Device from 'expo-device';
import API from '../utils/API'
import Loading from "../components/Loading";
import UserRow from "../components/UserRow"
import Modal from '../components/Modal'
import LightAdmins from '../utils/LightAdmins.json';
import {CommonActions} from "@react-navigation/native";

const dropDownMenu = [
  "Tüm Kullanıcılar",
  "Ücretsiz",
  "Özel Kullanıcılar",
  "Neutec Research",
  "Neutec 5000",
  "Revenue Cat Subscription",
  "Omnipemf Satışı",
  'Iyzico Subscription',
  'Bağımsız Araştırmalar',
  'Haver Sitikolin 2500',
];

const filterData = [
  "Tüm",
  null,
  "SPECIAL",
  "NEUTEC-RESEARCH-CAMPAIGN",
  "NEUTEC-CAMPAIGN",
  "REVENUE-CAT-SUBSCRIPTION",
  "OMNIPEMF-ORDER",
  'IYZICO-SUBSCRIPTION',
  'INDEPENDENT-RESEARCH-CAMPAIGN',
  'HAVER-CAMPAIGN',
]

let desiredData = [
  { key: "username", direction: false, disableSort: false, title: "Kullanıcı Adı", type: "string", flex: 3 },
  { key: "createdAt", direction: false, disableSort: false, title: "Kayıt Tarihi", type: "int", flex: 3 },
  { key: "lastMembershipCreatedAt", direction: false, disableSort: false, title: "P. Başlangıç", type: "int", flex: 2 },
  { key: "lastMembershipValidUntil", direction: false, disableSort: false, title: "P. Bitiş", type: "int", flex: 2 },
  { key: "lastMembershipSource", disableSort: true, direction: false, title: "Paket", type: "special", flex: 2 },
  { key: "birthYear", direction: false, disableSort: false, title: "Yaş", type: "int", flex: 1 },
  { key: "language", direction: false, disableSort: false, title: "Dil", type: "string", flex: 1 },
  // { key: "educationLevel", direction: false, disableSort: true, title: "Eğitim Durumu", type: "string" },
  // { key: "email", direction: false, disableSort: false, title: "E-Posta", type: "string" },
];

export default class Users extends React.PureComponent {
  constructor(props) {
    super(props)
    this.dropDownValue = new Animated.Value(0);
    this.filterUsersData = []
    this.inputText = "";
    this.timeout = null
    this.currentUserIndex = 0
    this.discountVal = 35
    this.welcomeTicketBody = "Merhabalar, ben Beynex adına Melisa, nasılsınız?\n\nUmarım çok iyisinizdir ve her şey yolundadır. Sizi Beynex’te görmekten büyük bir memnuniyet duyuyoruz. Aramıza hoş geldiniz.\n\nBeynimizi aktif tutmanın, hele ki bu korona döneminde oldukça güçleştiği zamanlarda, sizinle geçireceğimiz süreçte size bir katkımız olursa ne mutlu bize.\n\nBeynex ile ilgili aklınıza takılan her türlü konuda yardım etmek adına sizin için buradayım. :)\n\n(bu bir otomatik mesaj değildir.)"
    this.firstSelectedUserIndex = null
    this.numberOfUsers = 0

    this.isSortChangedFromDefault = false


    this.state = {
      shouldDropDown: false,
      dropDownSelected: 0,
      dropDownLoading: false,
      device: "DESKTOP",
      data: [],
      searchData: [],
      packets: [],
      welcomeMessageLoading: false,
      hasDiscount: false,
      welcomeHeader: 'Hoş geldiniz!',
      welcomeBody: this.welcomeTicketBody,
      welcomeLanguage: 'tr',
      isFirstMessage: true,
      lightAdmin: true,
      countOfUserToSendMessage: 0,
      isUserSelectionRange: false,
      usersToSendWelcomeMessage: [],
    }
    this.welcomeTicketBodyDiscount = `Merhabalar, ben Beynex adına Melisa, nasılsınız?\n\nUmarım çok iyisinizdir ve her şey yolundadır. Sizi Beynex’te görmekten büyük bir memnuniyet duyuyoruz. Aramıza hoş geldiniz.\n\nBeynimizi aktif tutmanın, hele ki bu korona döneminde oldukça güçleştiği zamanlarda, sizinle geçireceğimiz süreçte size bir katkımız olursa ne mutlu bize.\n\nSon olarak size özel 1 gün süre ile %${this.discountVal} indirim yakaladınız. İlgilenirseniz anasayfadan inceleyebilirsiniz. Beynex ile ilgili aklınıza takılan her türlü konuda yardım etmek adına sizin için buradayım. :)\n\n(bu bir otomatik mesaj değildir.)`

  }

  componentDidMount() {
    this.getDeviceType();
    this.getPackets()
  }

  componentWillUnmount(){
    if(this.timeout){
      clearTimeout(this.timeout)
    }
  }

  getPackets = () => {
    API.getPackets().then((response) => {
      this.setState({ packets: response.packets }, () => {
        this.getUsers()
      })
    }).catch((e) => {
      if(e.errorCode === 7){
        this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Login" }]
            })
        )
      }
      this.getUsers()
    })
  }

  getDeviceType = async () => {
    let device = await Device.getDeviceTypeAsync();
    this.setState({ device: Device.DeviceType[device] })
  }

  generateData = (d) => {
    let users = d
    for (let i = 0; i < users.length; i++) {
      if (users[i].latestMembership) {
        users[i]["lastMembershipCreatedAt"] = users[i].latestMembership.createdAt
        users[i]["lastMembershipValidUntil"] = users[i].latestMembership.validUntil
        users[i]["lastMembershipSource"] = users[i].latestMembership.source
        users[i]["activeMembershipStatus"] = users[i].latestMembership.status
      } else {
        users[i]["lastMembershipCreatedAt"] = null
        users[i]["lastMembershipValidUntil"] = null
        users[i]["lastMembershipSource"] = null
        users[i]["activeMembershipStatus"] = null
      }
    }
    return users
  }


  // this function is used to import some emails and find the BPIs of these users.
  /*secretMission = async () => {
    let emails = await import('../emails.json');
    let emailsSet = {};
    for(let email of emails.default){
      emailsSet[email[0]] = true;
    }

    let processedCounter = 0;

    for(let user of this.state.data){
      if(emailsSet.hasOwnProperty(user.email)){
        emailsSet[user.email] = {id: user.id};
        let response = await API.getUser(user.id);

        emailsSet[user.email] = {
          id: user.id,
          email: user.email,
          lightMode: response.user.lightMode,
          beynexPerformanceIndex: response.beynexPerformanceIndex
        }
        processedCounter ++;
        if(processedCounter % 10 === 1){
          console.log(processedCounter, ". is done!");
        }
      }
    }

    let resArray = [];

    for(let [email, props] of Object.entries(emailsSet)){
      resArray.push(props);
    }

    console.log(resArray);
  }*/


  getUsers = () => {
    API.getCurrentUser().then((currUser)=>{
      let lightAdmin = LightAdmins.includes(currUser.id)
      this.setState({
        lightAdmin: lightAdmin,
      })
      API.getUsers().then((response) => {
        let users = response.users;
        users.sort((a,b)=> a.createdAt - b.createdAt)
        if (lightAdmin) {
          this.filterNeutecResearch(this.generateData(users))
        } else {
          this.filterUsersData = this.generateData(users)
          this.setState({
            data: this.generateData(users).reverse(),
            searchData: this.generateData(users),
          }, () => {
            this.loading.hide();
            //this.secretMission();
          })
        }
      }).catch((e) => {
        this.loading.hide();

      })
    }).catch(e=>{

    })

  }

  filterNeutecResearch = (users) => {
    let neutecUsers = users.filter(user => {
      return (
        user.lastMembershipSource === "NEUTEC-RESEARCH-CAMPAIGN"
      );
    });
    this.setState({ data: neutecUsers,searchData: neutecUsers }, () => {
      this.loading.hide()
    })
  }

  filterByName = (text) => {
    text = text.trim();
    let filteredCharacters = this.state.searchData.filter(user => {
      return (
          (user.username || "").toLowerCase().includes(text.toLowerCase()) ||
        user.id.toLowerCase().includes(text.toLowerCase()) ||
        user.email.toLowerCase().includes(text.toLowerCase())
      );
    });
    this.setState({ data: filteredCharacters})
  }

  animateDropDown = () => {
    this.setState({ shouldDropDown: !this.state.shouldDropDown })
  }

  onUserPressed = (userId, index) => {
    let arr = [...this.state.usersToSendWelcomeMessage]
    if(this.state.isUserSelectionRange){
      if(this.firstSelectedUserIndex === null){
        arr.push(userId)
        this.firstSelectedUserIndex = index
      }else{
        if(this.firstSelectedUserIndex === index){
          this.firstSelectedUserIndex = null
          arr.length = 0
        }else{

          const max = Math.max(this.firstSelectedUserIndex, index)
          const min = Math.min(this.firstSelectedUserIndex, index)

          arr = this.state.data.slice(min, max + 1)
        }
      }
    }else{
      if(!arr.includes(userId)){
        arr.push(userId)
      }else{
        arr.splice(arr.indexOf(userId), 1)
      }
    }

    this.setState({usersToSendWelcomeMessage: arr})
  }

  sendWelcomeMessageToUsers = () => {
    if(this.state.usersToSendWelcomeMessage.length === 0){
      alert('Mesaj gönderebilmek için kullanıcı eklemeniz gerek. Kullanıcı adının üzerine tıklayarak ekleyebilirsiniz.')
      return 
    }

    this.setState({welcomeMessageLoading: true}, () => {
      console.log("BOMBA ARRAY: " + this.state.usersToSendWelcomeMessage.length)
      if(confirm(`Hoş geldiniz mesajını ${this.state.usersToSendWelcomeMessage.length} kişiye yollamak istediğinize emin misiniz?`)){
        console.log("FILTERED FINAL ARRAY: " + this.state.usersToSendWelcomeMessage.length)
        this.sendMessage(this.state.usersToSendWelcomeMessage)
        
      }else{
        this.setState({welcomeMessageLoading: false})
      }
    })
  }

  sendMessage = (userArr) => {
    if(userArr[this.currentUserIndex]){
      if(userArr[this.currentUserIndex].language !== this.state.welcomeLanguage){ // this user should be skipped due to the language choice.
        this.currentUserIndex++
        this.timeout = setTimeout(() => {
          this.sendMessage(userArr);
        }, 1);
        return;
      }

      API.getUserSupportTicket(userArr[this.currentUserIndex].id).then(({supportTickets})=>{
        if(supportTickets.length === 0){
          API.createNewSupportTicket(userArr[this.currentUserIndex].id, this.state.welcomeHeader, this.state.welcomeBody).then(()=>{
            console.log(`${this.currentUserIndex+1}/${userArr.length} başarılı.`)
            this.currentUserIndex++;
            this.numberOfUsers++
            this.timeout = setTimeout(() => {
              this.sendMessage(userArr);
            }, 400);
          }).catch((e)=> {
            console.log(`\n\n\n*****\n${this.currentUserIndex}/${userArr.length} BAŞARISIZ\n*****\n\n\n`);
            if(e.errorCode === 157){
              this.currentUserIndex++;
              this.timeout = setTimeout(() => {
                this.sendMessage(userArr);
              }, 400);
            }else{
              this.setState({usersToSendWelcomeMessage: [], welcomeMessageLoading: false});
              alert(`Mesaj ${(this.currentUserIndex + 1)}. kullanıcıdan itibaren gitmedi. Tekrar deneyin.`);
            }
            
          })
        }else{
          if(!this.state.isFirstMessage){
            API.sendMessage(supportTickets[0].id, this.state.welcomeBody).then(()=>{
              this.currentUserIndex++;
              this.numberOfUsers++;
              this.timeout = setTimeout(() => {
                this.sendMessage(userArr);
              }, 400);
            }).catch(()=> {
              const failedUser = this.state.usersToSendWelcomeMessage[this.currentUserIndex]
              this.setState({usersToSendWelcomeMessage: [], welcomeMessageLoading: false});
              alert(`Mesaj ${failedUser.username ? failedUser.username + ' adlı' :  failedUser.email + ' e-posta adresli'} kullanıcıdan itibaren gitmedi. Tekrar deneyin.`);
            })
          }else{
            this.currentUserIndex++;
            this.sendMessage(userArr);
          }
        }
      }).catch(()=>{
        const failedUser = this.state.usersToSendWelcomeMessage[this.currentUserIndex]
        this.setState({usersToSendWelcomeMessage: [], welcomeMessageLoading: false});
        alert(`Mesaj ${failedUser.username ? failedUser.username + ' adlı' :  failedUser.email + ' e-posta adresli'} kullanıcıdan itibaren gitmedi. Tekrar deneyin.`);
      })

    }else{
      if(this.timeout){
        clearTimeout(this.timeout);
      }
      alert(`Hoş geldiniz mesajı ${this.numberOfUsers} kullanıcıya gönderildi.`);
      this.currentUserIndex = 0;
      this.numberOfUsers = 0;
      this.setState({usersToSendWelcomeMessage:[], welcomeMessageLoading: false});
    }
  }

  renderRow = ({ item, index }) => {
    return (
      <UserRow usersToSendWelcomeMessage={this.state.usersToSendWelcomeMessage} index={index} key={index + 'user'} packets={this.state.packets} navigation={this.props.navigation} item={item} desiredData={desiredData} selectedUsers={this.state.usersToSendWelcomeMessage} onUserPressed={this.onUserPressed}/>
    )
  }

  sortList = (obj, ind) => {
    if (obj.type == "string") {
      let sortArr = this.state.data.sort((a, b) => {
        if (obj.direction) {
          if (!a[`${obj.key}`] || !b[`${obj.key}`]) {
            return null
          }
          if (a[`${obj.key}`].toUpperCase() < b[`${obj.key}`].toUpperCase()) {
            return -1
          }
          if (a[`${obj.key}`].toUpperCase() > b[`${obj.key}`].toUpperCase()) {
            return 1
          }
          return 0
        } else {
          if (!a[`${obj.key}`] || !b[`${obj.key}`]) {
            return null
          }
          if (a[`${obj.key}`].toUpperCase() > b[`${obj.key}`].toUpperCase()) {
            return -1
          }
          if (a[`${obj.key}`].toUpperCase() < b[`${obj.key}`].toUpperCase()) {
            return 1
          }
          return 0
        }
      });
      desiredData[ind].direction = !desiredData[ind].direction
      this.setState({ data: sortArr })
      this.forceUpdate()
    } else if (obj.type == "special") {
      let sortArr = this.state.data.sort((a, b) => {
        if (obj.direction) {
          if (a[`${obj.key}`] < b[`${obj.key}`]) {
            return -1
          }
          if (a[`${obj.key}`] > b[`${obj.key}`]) {
            return 1
          }
          return 0
        } else {
          if (a[`${obj.key}`] > b[`${obj.key}`]) {
            return -1
          }
          if (a[`${obj.key}`] < b[`${obj.key}`]) {
            return 1
          }
          return 0
        }
      });
      desiredData[ind].direction = !desiredData[ind].direction
      this.setState({ data: sortArr })
      this.forceUpdate()
    } else {
      let sortArr = this.state.data.sort((a, b) => {
        if (obj.direction) {
          return b[`${obj.key}`] - a[`${obj.key}`]
        } else {
          return a[`${obj.key}`] - b[`${obj.key}`]
        }
      });
      desiredData[ind].direction = !desiredData[ind].direction
      this.setState({ data: sortArr })
      this.forceUpdate()
    }
    this.isSortChangedFromDefault = true;
  }

  ListHeaderComponent = () => {
    return (
      <View style={styles.listHeaderContainer}>
        {
          desiredData.map((obj, index) => {
            return (
              <TouchableOpacity key={"faf" + index} disabled={obj.disableSort} onPress={() => this.sortList(obj, index)} activeOpacity={0.9} style={[{ flex: obj.flex, flexDirection: "row", alignItems: "center", borderRightWidth: 1, borderRightColor: Colors.grayLight.alpha1, justifyContent: "center", paddingVertical: 8, borderLeftColor: Colors.grayLight.alpha1, borderLeftWidth: 1 }]}>
                <Text style={[FontStyles.footnote, { color: Colors.dark.alpha1, fontWeight: "600" }]} numberOfLines={1}>
                  {obj.title}
                </Text>
                {
                  !obj.disableSort && <Feather name={obj.direction ? "chevron-down" : "chevron-up"} size={15} color={Colors.dark.alpha1} style={{ marginLeft: 8 }} />
                }
              </TouchableOpacity>
            )
          })
        }
        <View style={[styles.rowButton, { opacity: 0, paddingVertical: 0 }]} activeOpacity={0.9}>
          <Text style={[FontStyles.caption2, { fontWeight: "600" }]}>
            İncele
          </Text>
        </View>
      </View>
    )
  }

  footerComponent = () => {
    return (
      <TouchableOpacity style={styles.footer}>
        <Text style={[FontStyles.caption2, { color: Colors.white.alpha1, fontWeight: "600" }]}>
          Daha Fazla Yükle
        </Text>
      </TouchableOpacity>
    )
  }

  usersEmpty = () => {
    return(
      <Text style={[FontStyles.body,{color:Colors.dark.alpha05,textAlign:"center",marginTop:32}]}>
        {"Kullanıcı bulunamadı."}
      </Text>
    )
  }

  downloadUserData = () => {
    let promise;
    if(this.state.dropDownSelected === filterData.indexOf("NEUTEC-CAMPAIGN")){
      promise = API.downloadNeutecCampaignData();
    } else if(this.state.dropDownSelected === filterData.indexOf("NEUTEC-RESEARCH-CAMPAIGN")){
      promise = API.downloadNeutecResearchCampaignData();
    } else {
      promise = Promise.reject({errorMessage: 'Bu kullanıcı grubu için toplu veri indirimi yoktur.'})
    }

    promise
        .then((response)=>{
          if(response.filename){
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
    
            a.href = response.file;
            
            const now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            
            a.download = response.filename.substring(0, response.filename.lastIndexOf('_')) + '_' + now.toISOString().substring(0,10) + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(response.file);
            a.remove();
          }
          else {
            window.open(response.file, "_blank");
          }
        })
        .catch((e)=>{
          alert(e.errorMessage);
        });
  }

  dropDownItemPressed = (index) => {
    if (index === 0) {
      this.setState({
        data: this.filterUsersData,
        shouldDropDown: !this.state.shouldDropDown,
        dropDownSelected: index,
        searchData: this.filterUsersData
      })
    } else {
      const filteredCharacters = this.filterUsersData.filter(user => {
        return (
          user.lastMembershipSource == filterData[index]
        );
      });
      this.setState({
        dropDownSelected: index,
        shouldDropDown: !this.state.shouldDropDown,
        data: filteredCharacters,
        searchData:filteredCharacters
      });

    }
  }

  openMessageModal = ()=>{
    if(!this.isSortChangedFromDefault){
      this.modal.openModal()
    } else {
      alert('Kullanıcıların sırası değiştirildikten sonra toplu mesaj özelliği kullanılamaz. Lütfen sayfayı yenileyerek tekrar ayarlayın.')
    }
  }

  discountValEntered = (text) => {
    this.discountVal = text
    const body = `Merhabalar, ben Beynex adına Melisa, nasılsınız?\n\nUmarım çok iyisinizdir ve her şey yolundadır. Sizi Beynex’te görmekten büyük bir memnuniyet duyuyoruz. Aramıza hoş geldiniz.\n\nBeynimizi aktif tutmanın, hele ki bu korona döneminde oldukça güçleştiği zamanlarda, sizinle geçireceğimiz süreçte size bir katkımız olursa ne mutlu bize.\n\nSon olarak çok kısa bir süreye özel %${this.discountVal} indirim zamanımızı yakaladınız. İlgilenirseniz anasayfadan inceleyebilirsiniz. Beynex ile ilgili aklınıza takılan her türlü konuda yardım etmek adına sizin için buradayım. :)\n\n(bu bir otomatik mesaj değildir.)`
    this.setState({welcomeBody: body})
  }

  /*discountSwitch = (value) => {
    this.setState({hasDiscount:value})
    if(value){
      const filteredUsers = this.state.usersToSendWelcomeMessage.filter((user)=>{
        return user.lastMembershipSource == null
      })
      this.setState({
        welcomeBody:this.welcomeTicketBodyDiscount,
        usersToSendWelcomeMessage: filteredUsers
      })
    }else{
      this.setState({
        welcomeBody:this.welcomeTicketBody,
        usersToSendWelcomeMessage: this.usersToSendWelcomeMessage
      })
    }
  }*/

  switchMessageSelection = (value) => {
    this.firstSelectedUserIndex = null
    this.setState({isUserSelectionRange: value, usersToSendWelcomeMessage: []})
  }

  render() {
    return (
      <View style={[styles.container, { paddingTop: this.state.device === "PHONE" ? 10 : 20 }]}>
        <View style={[styles.header, { }]}>
          {!this.state.lightAdmin &&
            <TouchableOpacity style={styles.filterButton} onPress={this.animateDropDown} activeOpacity={0.9}>
              <Text numberOfLines={1} style={[FontStyles.footnote, { color: Colors.white.alpha1, fontWeight: "600" }]}>
                {dropDownMenu[this.state.dropDownSelected] + " (" + this.state.data.length + ")"}
              </Text>
              <TouchableOpacity style={{ padding: 5 }} activeOpacity={0.9} onPress={this.animateDropDown}>
                {
                  this.state.dropDownLoading ?
                    <ActivityIndicator color={Colors.white.alpha1} />
                    :
                    <Feather name={"chevron-down"} size={23} color={Colors.white.alpha1} />
                }
              </TouchableOpacity>
              {
                this.state.shouldDropDown &&
                <View style={styles.openedDropDown}>
                  <TouchableOpacity style={{ alignSelf: "flex-end", padding: 5 }} onPress={this.animateDropDown}>
                    <Feather name={"chevron-up"} size={23} color={Colors.white.alpha1} />
                  </TouchableOpacity>
                  {
                    dropDownMenu.map((item, index) => {
                      return (
                        <TouchableOpacity key={"index"+index} activeOpacity={0.9} style={{ width: "100%", paddingVertical: 10, borderTopWidth: 1, borderColor: Colors.white.alpha05 }} onPress={() => this.dropDownItemPressed(index)}>
                          <Text style={[FontStyles.footnote, { color: Colors.white.alpha1, fontWeight: "600", }]}>
                            {item}
                          </Text>
                        </TouchableOpacity>
                      )
                    })
                  }
                </View>
              }
            </TouchableOpacity>
          }
          <View style={[styles.search, { flexDirection: "row", backgroundColor: Colors.grayLight.alpha05 }]}>
            <Feather name={"search"} size={20} color={Colors.dark.alpha1} />
            <TextInput
              style={[FontStyles.caption1, styles.searchInner]}
              placeholder={"Tüm Kullanıcılarda Ara"}
              placeholderTextColor={Colors.dark.alpha1}
              selectionColor={Colors.grayLight.alpha1}
              onChangeText={this.filterByName}
            />
          </View>
          {
            !this.state.lightAdmin &&
            <View style={{alignItems: 'center'}}>
              <TouchableOpacity style={styles.sendWelcomeMessageButton} onPress={this.openMessageModal}>
                <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                  Toplu Mesaj
                  <Text style={[FontStyles.footnote,{color:Colors.white.alpha1,marginLeft:4}]}>
                    {'(' + this.state.usersToSendWelcomeMessage.length + ')'}
                  </Text>
                </Text>
              </TouchableOpacity>
              <View style={{flexDirection: 'row', marginTop: 12}}>
                <Text style={[FontStyles.footnote, {color: Colors.dark.alpha1}]}>
                  {'Aralık Seç'}
                </Text>
                <Switch
                  trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                  value={this.state.isUserSelectionRange}
                  onValueChange={this.switchMessageSelection}
                  style={{marginLeft:8}}
                />
              </View>
            </View>

          }

        </View>

        {
          [filterData.indexOf("NEUTEC-CAMPAIGN"), filterData.indexOf("NEUTEC-RESEARCH-CAMPAIGN")].includes(this.state.dropDownSelected) &&
              <View style={{height: 40, marginTop: 18, width: '100%', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <TouchableOpacity style={styles.sendWelcomeMessageButton} onPress={this.downloadUserData}>
                  <FontAwesome name="file-excel-o" size={21} color={Colors.white.alpha1} style={{ marginRight: 8}} />
                  <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                    Araştırma Verisini İndir
                  </Text>
                </TouchableOpacity>
              </View>
        }

        <FlatList
          style={{ flex: 1, zIndex: -5, marginTop: this.state.device === "PHONE" ? 10 : 20, borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
          data={this.state.data}
          keyExtractor={(item) => (item.id + "x")}
          renderItem={this.renderRow}
          showsVerticalScrollIndicator={true}
          ListHeaderComponent={this.ListHeaderComponent}
          ListEmptyComponent={this.usersEmpty}
          contentContainerStyle={{ paddingBottom: 20 }}
          stickyHeaderIndices={[0]}
          //ListFooterComponent={this.footerComponent}
          windowSize={10}
        />
        <Loading ref={ref => this.loading = ref} text={"Kullanıcılar getiriliyor"} />
        <Modal ref={ref => this.modal = ref}>
          <View style={styles.inputContainer}>
            <View style={{flexDirection:'row',marginBottom:16,width:'100%',alignItems:'center'}}>
              <View style={{width:'25%'}}>
                {/*<View style={{flexDirection:'row',justifyContent:'space-between'}}>
                  <Text style={[FontStyles.body]}>
                    İndirimli Mesaj
                  </Text>
                  <Switch
                    trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                    value={this.state.hasDiscount}
                    onValueChange={this.discountSwitch}
                    style={{marginLeft:8}}
                  />
                </View>*/}
                <View style={{flexDirection:'row',marginTop:12,justifyContent:'space-between'}}>
                  <Text style={[FontStyles.body]}>
                    İlk Mesaj
                  </Text>
                  <Switch
                    trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                    value={this.state.isFirstMessage}
                    onValueChange={(value)=> {this.setState({isFirstMessage:value})}}
                    style={{marginLeft:8}}
                  />
                </View>

                <View style={[styles.buttonContainer, {marginTop: 10}]}>
                  <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({welcomeLanguage: "tr"})} style={[styles.languageButton, {backgroundColor: this.state.welcomeLanguage == "tr" ? Colors.greenLight.alpha1 : "transparent"}]}>
                    <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.welcomeLanguage == "tr" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                      Türkçe
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({welcomeLanguage: "en"})} style={[styles.languageButton, {backgroundColor: this.state.welcomeLanguage == "en" ? Colors.greenLight.alpha1 : "transparent"}]}>
                    <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.welcomeLanguage == "en" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                      İngilizce
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>



              {
                this.state.hasDiscount &&
                <TextInput
                  style={[FontStyles.caption1,{backgroundColor:Colors.grayLight.alpha05,marginLeft:16,borderRadius:6,padding:8}]}
                  placeholder={'İndirim Tutarı'}
                  onChangeText={this.discountValEntered}
                />
              }
              <View style={{flex:1}}/>
              <TouchableOpacity disabled={this.state.welcomeMessageLoading} style={{paddingHorizontal:16,paddingVertical:12,backgroundColor:Colors.greenLight.alpha1,borderRadius:8}} onPress={this.sendWelcomeMessageToUsers}>
                {
                  this.state.welcomeMessageLoading ?
                  <ActivityIndicator color={'white'}/>
                  :
                  <Text style={[FontStyles.body,{color:Colors.white.alpha1}]}>
                    {'Gönder (' + this.state.usersToSendWelcomeMessage.length + ')'}
                  </Text>
                }
              </TouchableOpacity>
            </View>
            <TextInput 
              style={[FontStyles.body,styles.headerInput]}
              placeholder={'Başlık yazınız..'}
              value={this.state.welcomeHeader}
              onChangeText={(text)=>this.setState({welcomeHeader:text})}
            />
            <TextInput
              style={[FontStyles.body,styles.bodyInput]}
              multiline
              value={this.state.welcomeBody}
              placeholder={'Mesaj yazınız..'}
              onChangeText={(text)=>this.setState({welcomeBody:text})}
            />
          </View> 
        </Modal>
      </View>
    )
  }
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20
  },
  header: {
    width: "100%",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    alignSelf: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  filterButton: {
    flex: 3,
    maxWidth: 300,
    minWidth: 140,
    height: "80%",
    alignSelf: 'center',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.greenLight.alpha1,
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 16,
  },
  search: {
    width: "50%",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 10,
    alignItems: "center",
    height: "80%",
    alignSelf: 'center',
    marginLeft: 16
  },
  searchInner: {
    width: "100%",
    marginLeft: 8,
    paddingVertical: 5,
    outline: "none",
    outlineWidth: 0,
  },
  listHeaderContainer: {
    flexDirection: "row",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: "hidden",
    borderBottomColor: Colors.grayLight.alpha1,
    borderBottomWidth: 1,
    backgroundColor: Colors.grayLight.alpha1,
    borderTopWidth: 1,
    borderTopColor: Colors.grayLight.alpha1
  },

  footer: {
    alignItems: "center",
    paddingVertical: 15,
    width: "100%",
    justifyContent: 'center',
    backgroundColor: Colors.greenLight.alpha1,
    alignSelf: "center",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  },
  openedDropDown: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 5,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: Colors.greenLight.alpha1,
    borderRadius: 10,
    zIndex: 10,
  },
  textBox: {
    color: Colors.dark.alpha08,
    flex: 3,
    textAlign: "center",
    height: "100%",
    paddingVertical: 8,
    borderRightColor: Colors.grayLight.alpha1,
    borderRightWidth: 1
  },
  rowContainer: {
    flex: 1,
    paddingVertical: 10,
    borderRightWidth: 1,
    borderRightColor: Colors.grayLight.alpha1,
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayLight.alpha1,
    alignItems: "center",
    borderLeftColor: Colors.grayLight.alpha1,
    borderLeftWidth: 1
  },
  rowButton: {
    padding: 10,
    paddingHorizontal: 20,
    borderBottomColor: Colors.grayLight.alpha1,
    borderBottomWidth: 1,
    backgroundColor: Colors.greenLight.alpha1
  },
  sendWelcomeMessageButton:{
    borderRadius:10,
    backgroundColor:Colors.greenLight.alpha1,
    paddingHorizontal:16,
    justifyContent:'center',
    alignItems:'center',
    marginLeft:16,
    flexDirection:'row',
    paddingVertical: 12
  },
  inputContainer:{
    width:'80%',
    alignSelf:'center',
    height:'100%',
    paddingVertical:'5%',
  },
  headerInput:{
    backgroundColor:Colors.grayLight.alpha05,
    height: 60,
    borderRadius:10,
    paddingHorizontal:16,
    outline:'none',
    outlineWidth:0
  },
  bodyInput:{
    flex:1,
    backgroundColor:Colors.grayLight.alpha05,
    marginTop:16,
    borderRadius:10,
    outline:'none',
    padding:16,
    outlineWidth:0
  },
  categoryButton:{
    width:'50%',
    height:'100%'
  },
  buttonContainer: {
    backgroundColor: Colors.grayLight.alpha05,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  languageButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 34,
    width: 100,
    borderRadius: 5,
  },
})