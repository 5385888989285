import * as React from 'react';
import { Image, View, Text, TouchableOpacity, Dimensions, Animated, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons";
import {LinearGradient} from "expo-linear-gradient";

const SCREEN_HEIGHT = Dimensions.get('screen').height
const SCREEN_WIDTH = Dimensions.get('screen').width

export default class Modal extends React.PureComponent{
  constructor(props){
    super(props)
    this.animVal = new Animated.Value(0)

    this.transformBg = {
      transform:[
        {
          translateY: this.animVal.interpolate({
            inputRange:[0,0.001],
            outputRange:[0,-SCREEN_HEIGHT * 2],
            extrapolate:'clamp'
          })
        }
      ]
    }

    this.bgOpacity = {
      opacity: this.animVal.interpolate({
        inputRange:[0,1],
        outputRange:[0,1]
      })
    }

    this.transformModal = {
      transform:[
        {
          translateY: this.animVal.interpolate({
            inputRange:[0,1],
            outputRange:[0,-SCREEN_HEIGHT * 2 - 40]
          })
        }
      ]
    }
  }

  openModal = () => {
    Animated.timing(this.animVal,{
      toValue:1,
      duration:300,
      useNativeDriver:true
    }).start()
  }

  closeModal = () => {
    if(typeof this.props.onRequestClose === 'function'){
      this.props.onRequestClose();
    }
    Animated.timing(this.animVal,{
      toValue:0,
      duration:300,
      useNativeDriver:true
    }).start()
  }

  render(){
    return(
      <View style={[styles.container]}>
        <Animated.View style={[styles.bg,this.bgOpacity,this.transformBg]}>
          <LinearGradient colors={['rgba(100,100,100,0.8)', "rgba(242,242,242, 1)"]}
                          start={{x: 1, y: 0.5}}
                          end={{x: 0, y: 0.5}}
                          style={styles.linearBackground}/>
        </Animated.View>

          <Animated.View style={[styles.modalContainer,this.transformModal,this.props.containerStyle]}>
            <TouchableOpacity style={styles.closeButton} onPress={this.closeModal}>
              <Feather name='x' size={30}/>
            </TouchableOpacity>
            {this.props.children}
          </Animated.View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container:{
    height:SCREEN_HEIGHT ,
    left:0,
    right:0,
    top:SCREEN_HEIGHT * 2,
    position: 'absolute',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:Colors.white.alpha04,
  },
  linearBackground: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: 'center'
  },
  bg:{
    width:'100%',
    height:'100%',
    backgroundColor: 'transparent',
    position: 'absolute',
  },
  modalContainer:{
    width:'90%',
    height:'90%',
    backgroundColor: 'rgba(255,255,255,0.92)',
    borderRadius:10,
  },
  closeButton:{
    position: 'absolute',
    right:'1.5%',
    top:'1.5%',
    padding:20,
    justifyContent:'center',
    alignItems:'center'
  }
})

