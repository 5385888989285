import React, { PureComponent } from 'react';
import {FlatList, StyleSheet, View, Text, TouchableOpacity, Image, ScrollView} from 'react-native';
import * as Device from "expo-device";
import Loading from "../components/Loading";
import PlannedPushNotificationEdit from "../components/PlannedPushNotificationEdit"
import API from "../utils/API";
import {CommonActions} from "@react-navigation/native";
import Colors from "../utils/Colors";
import {Hoverable} from "react-native-web-hover";
import Modal from "../components/Modal";
import {Feather} from "@expo/vector-icons";
import FontStyles from "../utils/FontStyles";

const tabs = [
    {
        id: "ACCOUNT-SPAN-DEPENDENT",
        trName: "Hesap süresi bazlı",
    },
    {
        id: "DAY-OF-WEEK-DEPENDENT",
        trName: "Haftanın günü bazlı",
    },
    {
        id: "EXACT-DATE-DEPENDENT",
        trName: "Tam tarihi belirlenmiş",
    },
    {
        id: "ACCOUNT-SPAN-DEPENDENT-RECURRING",
        trName: "Hesap süresi bazlı ve tekrarlı",
    }
];

const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
const daysOfWeekTrTranslations = {
    "SUNDAY": "Pazar",
    "MONDAY": "Pazartesi",
    "TUESDAY": "Salı",
    "WEDNESDAY": "Çarşamba",
    "THURSDAY": "Perşembe",
    "FRIDAY": "Cuma",
    "SATURDAY": "Cumartesi"
}

export default class PlannedPushNotification extends PureComponent {
    constructor(props) {
        super(props)
        this.state={
            device: "DESKTOP",
            plannedPushNotifications: [],
            tabIndex: 0,
            selectedIndex: null,
        }
    }

    refreshPlannedPushNotifications = () => {
        this.loading && this.loading.show();
        setTimeout(()=>{
            API.getPlannedPushNotifications()
                .then((res)=>{
                    res.plannedPushNotifications.sort((a,b)=>{
                        let aDayIndex = a.dayOfWeek ? daysOfWeek.indexOf(a.dayOfWeek) : -1;
                        let bDayIndex = b.dayOfWeek ? daysOfWeek.indexOf(b.dayOfWeek) : -1;
                        let aSpan = a.accountSpanInDays || 0;
                        let bSpan = b.accountSpanInDays || 0;
                        let aDate = a.exactDate || "";
                        let bDate = b.exactDate || "";
                        if( a.type < b.type ||
                            (a.type === b.type && aDayIndex < bDayIndex) ||
                            (a.type === b.type && aDayIndex === bDayIndex && aSpan < bSpan) ||
                            (a.type === b.type && aDayIndex === bDayIndex && aSpan === bSpan && aDate < bDate) ||
                            (a.type === b.type && aDayIndex === bDayIndex && aSpan === bSpan && aDate === bDate && a.timeOfDay < b.timeOfDay) ||
                            (a.type === b.type && aDayIndex === bDayIndex && aSpan === bSpan && aDate === bDate && a.timeOfDay === b.timeOfDay && a.language < b.language) ||
                            (a.type === b.type && aDayIndex === bDayIndex && aSpan === bSpan && aDate === bDate && a.timeOfDay === b.timeOfDay && a.language === b.language || a.id < b.id)
                        ) {
                            return -1;
                        }
                        else{
                            return 1;
                        }
                    });

                    console.log(res);

                    this.setState({
                        plannedPushNotifications: res.plannedPushNotifications
                    }, ()=>{
                        this.loading && this.loading.hide();
                    })

                }).catch(e => {
                this.loading && this.loading.hide();
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                }else{
                    console.log(e.errorMessage);
                }
            })
        }, 500);
    }

    componentDidMount() {
        this.getDeviceType();
        this.refreshPlannedPushNotifications();
    }

    getDeviceType = async () => {
        let device=await Device.getDeviceTypeAsync();
        this.setState({ device: Device.DeviceType[device] })
    }

    changeTab = (index) => {
        this.setState({tabIndex: index});
    }

    displayHeader = () => {
        return <View>
            <View style={{flexDirection: "row", flexWrap: 'wrap', paddingHorizontal: 12, borderBottomWidth: 1, borderColor: Colors.grayDark.alpha02}}>
                {
                    tabs.map((item, idx)=>{
                        return <TouchableOpacity key={"tab"+idx} activeOpacity={0.7} style={{marginRight: 40, marginBottom: 12}} disabled={this.state.tabIndex === idx} onPress={()=>this.changeTab(idx)}>
                            <Text style={{
                                color: this.state.tabIndex === idx ? Colors.greenLightDark.alpha1 : Colors.grayDark.alpha1,
                                fontSize: 20,
                                fontWeight: this.state.tabIndex === idx ? 'bold' : "500",
                            }}>
                                {item.trName}
                            </Text>
                        </TouchableOpacity>
                    })
                }
            </View>
            <TouchableOpacity style={{alignSelf: 'flex-end', marginTop: 20, marginRight: 8}} activeOpacity={0.7} onPress={()=>{
                this.setState({selectedIndex: -1}, ()=>{
                    this.plannedPushNotificationEditModal.openModal();
                })

            }}>
                <Feather name={"plus"} size={28} color={Colors.green.alpha1}/>
            </TouchableOpacity>
        </View>;
    }

    displayListEmptyComponent = () => {
        return <Text style={[FontStyles.caption1, {marginTop: 50, color:Colors.grayDark.alpha08, alignSelf: 'center', textAlign: 'center'}]}>
            Bu tipte planlanmış bildirim bulunmuyor. Sağ üstteki artı tuşundan oluşturmaya başlayabilirsiniz.
        </Text>
    }

    translateNumericalFilterToHumanReadable = (phrase, filter) => {
        let parts = filter.split('=');
        if(parts[0] === 'gt'){
            return phrase + " > " + parts[1];
        }
        else if(parts[0] === 'gte'){
            return phrase + " ≥ " + parts[1];
        }
        else if(parts[0] === 'lt'){
            return phrase + " < " + parts[1];
        }
        else if(parts[0] === 'lte'){
            return phrase + " ≤ " + parts[1];
        }
        else if(parts[0] === 'eq'){
            return phrase + " = " + parts[1];
        }
        else if(parts[0] === 'neq'){
            return phrase + " ≠ " + parts[1];
        }
        else if(parts[0] === 'btw'){
            let numbers = parts[1].split('~');
            if(parseInt(numbers[0]) > parseInt(numbers[1])){ // if numbers are in wrong order, swap it first
                numbers = [numbers[1], numbers[0]];
            }

            return phrase + " " +  numbers[0] + "-" + numbers[1] + " arası";
        }
        else {
            return "???";
        }
    }

    onPressNotification = (index) => {
        this.setState({
            selectedIndex: index
        }, ()=>{
            this.plannedPushNotificationEditModal.openModal();
        })
    }

    deletePlannedNotification = (id) => {
        if(!confirm("Bu bildirim planını kalıcı olarak silmek istediğinize emin misiniz?")){
            return;
        }
        this.loading && this.loading.show();
        API.deletePlannedPushNotification(id)
            .then(()=>{
                this.refreshPlannedPushNotifications(); // this will also hide the loading.
            })
            .catch(e=>{
                this.loading && this.loading.hide();
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                }
                else{
                    alert("Bir hata oluştu: " + e.errorMessage);
                }
            })
    }

    renderPlannedPushNotificationRows = ({ item, index }) => {

        let filterTexts = [];

        if(item.premiumStatusFilter){
            filterTexts.push(item.premiumStatusFilter === "PREMIUM" ? "Premium" : "Non-Premium");
        }
        if(item.ageFilter){
            filterTexts.push(this.translateNumericalFilterToHumanReadable("Yaş", item.ageFilter));
        }
        if(item.assessmentGamePlayedCountFilter){
            filterTexts.push(this.translateNumericalFilterToHumanReadable("Tracking sayısı", item.assessmentGamePlayedCountFilter));
        }
        if(item.trainingGamePlayedCountFilter){
            filterTexts.push(this.translateNumericalFilterToHumanReadable("Training sayısı", item.trainingGamePlayedCountFilter));
        }

        let when = ""
        if(item.type === "ACCOUNT-SPAN-DEPENDENT"){
            when = `Kayıttan ${item.accountSpanInDays} gün sonra`;
        }
        else if(item.type === "DAY-OF-WEEK-DEPENDENT"){
            when = `Her ${daysOfWeekTrTranslations[item.dayOfWeek]} günü`;
        }
        else if(item.type === "EXACT-DATE-DEPENDENT"){
            when = item.exactDate.split("-")[2] + "." + item.exactDate.split("-")[1] + "." + item.exactDate.split("-")[0] + " tarihinde";
        }
        else if(item.type === "ACCOUNT-SPAN-DEPENDENT-RECURRING"){
            when = `Kayıttan ${item.accountSpanInDays} gün sonra (ve sonra her ${item.recurrenceFrequencyInDays} günde bir)`;
        }

        return <View key={item.id} style={{marginTop: 10, marginBottom: 10,}}>
            <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity activeOpacity={0.7} onPress={() => {this.onPressNotification(index)}} style={[styles.rowContainer,
                        hovered ? {backgroundColor: Colors.greenLight.alpha01} :
                            { backgroundColor: Colors.white.alpha1}
                    ]}
                    >
                        <View style={{flexDirection: "column", flex: 1, alignSelf: 'stretch', alignItems: 'stretch'}}>
                            <Text style={{
                                fontWeight: "600",
                                fontSize: 18,
                            }}>
                                {when
                                + " " + String(item.timeOfDay).padStart(2, '0') + ":00'da"
                                + ", " + (item.language === 'tr' ? " Türkçe" : " İngilizce")  + " dilinde"
                                + ", " + (['OCCASIONALLY', 'FREQUENTLY'].includes(item.minimumNotificationFrequency) ? (item.minimumNotificationFrequency === 'OCCASIONALLY' ? ', en az "ARA SIRA" sıklığı seçenlere' : ', sadece "SIK SIK" seçenlere') : (', tüm sıklık seviyelerine'))
                                }

                            </Text>
                            <Text style={{marginTop: 8, fontSize: 16}}>
                                <Text style={{fontWeight: "600"}}>Filtreler: </Text>
                                {
                                    filterTexts.join(" , ") || "-"
                                }
                            </Text>
                            {
                                item.text.map((it,index)=>{
                                    return (
                                        <Text key={"pushtext-"+item.id+"-"+index} style={{
                                            marginTop: 8,
                                            fontWeight: "400",
                                            fontSize: 16,
                                            fontStyle: 'italic'
                                        }}>
                                            {"\" " + it + " \""}
                                        </Text>
                                    );
                                })
                            }

                        </View>

                        <TouchableOpacity style={{paddingHorizontal:12,paddingVertical:8,backgroundColor:Colors.red.alpha1,borderRadius:5, alignSelf: 'flex-end', flexDirection:'row',alignItems:'center'}} onPress={()=>this.deletePlannedNotification(item.id)}>
                            <Feather name={"trash-2"} size={16} color={Colors.white.alpha1}/>
                            <Text style={[FontStyles.caption2, {marginLeft:8, color: Colors.white.alpha1}]}>
                                Planı sil
                            </Text>
                        </TouchableOpacity>

                    </TouchableOpacity>
                )}
            </Hoverable>
        </View>;
    }

    render() {

        let filteredPlannedPushNotifications = this.state.plannedPushNotifications.filter(item=>item.type === tabs[this.state.tabIndex].id);

        return <View style={{flex:1, paddingTop: this.state.device === "PHONE" ? 10 : 20 }}>
            <FlatList style={{flex: 1, alignSelf: 'stretch'}}
                      contentContainerStyle={styles.container}
                      ref={ref=>this.flatList=ref}
                      data={filteredPlannedPushNotifications}
                      renderItem={this.renderPlannedPushNotificationRows}
                      keyExtractor={(item)=>item.id}
                      ListEmptyComponent={this.displayListEmptyComponent()}
                      ListHeaderComponent={this.displayHeader()}
            />
            <Modal ref={ref => this.plannedPushNotificationEditModal = ref}
                   containerStyle={{maxWidth: 700, maxHeight: 700, paddingTop: 80, backgroundColor: 'white'}}
                   onRequestClose={()=>{this.setState({selectedIndex: null})}}>
                <ScrollView contentContainerStyle={{paddingBottom: 20}}>
                    {
                        this.state.selectedIndex !== null &&
                            <PlannedPushNotificationEdit
                                navigation={this.props.navigation}
                                data={this.state.selectedIndex >= 0 ? filteredPlannedPushNotifications[this.state.selectedIndex] : {}}
                                type={tabs[this.state.tabIndex].id}
                                onSubmit={()=>{
                                    this.plannedPushNotificationEditModal.closeModal();
                                    this.refreshPlannedPushNotifications();
                                }}
                            />
                    }
                </ScrollView>
            </Modal>
            <Loading ref={ref => this.loading = ref} text={"Bildirim planları getiriliyor"} />
        </View>;
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20
    },
    rowContainer: {
        flexDirection: 'row',
        padding: 10,
        borderRadius: 5,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        overflow: 'hidden',
        borderColor: 'transparent',
    },
})