import React from 'react';
import {View, Text, Modal, StyleSheet, Animated, Dimensions, ActivityIndicator} from 'react-native';
import Colors from '../utils/Colors.js';
import FontStyles from '../utils/FontStyles.js';
const sw = Dimensions.get("screen").width;
import {LinearGradient} from "expo-linear-gradient";

export default class Loading extends React.PureComponent {
	constructor(props){
		super(props);
		this.animValue = new Animated.Value(props.initiallyClosed ? 0 : 1)
		this.transform = {
		  transform: [
			{
			  translateX: this.animValue.interpolate({
				inputRange: [0, 0.000001],
				outputRange: [sw, 0],
				extrapolate: 'clamp'
			  })
			}
		]}
		this.state = {
			text: this.props.text,
			dots: this.props.text ? "." : null
		}
	}z

	startDotsIfNecessary = () => {
		if(this.state.dots && !this.dotsInterval){
			this.dotsInterval = setInterval(()=>{
				if([1,2].includes(this.state.dots.length)){
					this.setState({dots: this.state.dots+"."});
				}
				else{
					this.setState({dots: "."});
				}
			}, 300)
		}
	}

	componentDidMount = () => {
		this.startDotsIfNecessary();
	}

	show = () => {
		Animated.timing(this.animValue, {
			toValue: 1,
			duration: 300,
			useNativeDriver: true,
		}).start()

		this.startDotsIfNecessary();
	}

	hide = () => {
		Animated.timing(this.animValue, {
			toValue: 0,
			duration: 350,
			useNativeDriver: true,
			delay: 500
		}).start()

		this.stopDotsIfNecessary();
	}

	stopDotsIfNecessary = () => {
		if(this.dotsInterval){
			clearInterval(this.dotsInterval);
			this.dotsInterval = null;
		}
	}

	componentWillUnmount = () => {
		this.stopDotsIfNecessary();
	}


	render(){
		return (
			<Animated.View style={[styles.container, this.transform, {opacity: this.animValue}, this.props.containerStyle]}>
				<LinearGradient colors={['rgba(200,200,200,0.9)', "rgba(242,242,242, 1)"]}
								start={{x: 1, y: 0.5}}
								end={{x: 0, y: 0.5}}
								style={styles.linearBackground}>
					<ActivityIndicator size="large" color={this.props.spinColor || Colors.dark.alpha06}/>
					{
						this.state.text &&
						<Text style={[FontStyles.body, styles.txt, this.props.textStyle]}>
							{this.state.text + (this.state.dots ? " " : "")}
							{
								this.state.dots?
								[1,2,3].map((item, index)=>{
									return <Text key={"d"+index} style={[FontStyles.body, styles.txt, this.props.textStyle, index < this.state.dots.length ? {} : {color:'transparent'}]}>{"."}</Text>
								})
									:
									null
							}
						</Text>
					}
				</LinearGradient>

			</Animated.View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		bottom: 0,
		top: 0,
		left: 0, 
		right: 0,
		backgroundColor: Colors.white.alpha04,
		justifyContent: "center",
		alignItems: 'center'
	},
	linearBackground: {
		position: "absolute",
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		justifyContent: "center",
		alignItems: 'center'
	},
	txt: {
		fontWeight: "500",
		color: Colors.dark.alpha06,
		marginTop: 20,
		zIndex: 1001,
	}
});
