import * as React from 'react';
import {ActivityIndicator, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import * as Device from 'expo-device';
import API from '../utils/API';
import {CommonActions} from '@react-navigation/native';
import {Feather} from "@expo/vector-icons";
import * as ImagePicker from 'expo-image-picker';
import Modal from "../components/Modal";
import * as ImageManipulator from "expo-image-manipulator";
import accents from 'remove-accents';
import BlogDisplay from '../components/BlogDisplay';
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";

function cleanSingleLineText(text){
    return text.replace(/\s/g, ' ').replace(/^\s+|\s+$/g, '').replace(/[ ]{2,}/g, ' ').replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');  // last two replaces remove smart quotes
}

function cleanMultilineText(text){
    return text.replace(/^\s+|\s+$/g, '').replace(/\t/g, ' ').replace(/[ ]{2,}/g, ' ').replace(/ \n/g, '\n').replace(/\n /g, '\n').replace(/[\n]{2,}/g, '\n\n').replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');  // last two replaces remove smart quotes
}

function prepareTimeInMilliseconds(date, time){
    const isoString = date + 'T' + time + ':00' + "+03:00";
    return (new Date(isoString)).getTime();
}


export default class BlogCreate extends React.PureComponent {
    constructor(props) {
        super(props)
        this.text = "";
        this.title = "";
        this.subtitle = "";
        this.slug = "";
        this.date = null;
        this.time = null;
        this.state={
            device: "DESKTOP",
            language: "tr",
            loading: false,
            checked: false,
            photo: null,
            photoConversionInfo: {
                sizeBefore: 0,
                sizeAfter: 0,
            },
            previewInfo: {},
            photoPreviewUri: null,
            innerPhotos: [],
        }
    }

    onDateChange = (event) => {
        this.date = event.target.value;
        if(this.state.checked){
            this.setState({checked:false});
        }
    }

    onTimeChange = (event) => {
        this.time = event.target.value;
        if(this.state.checked){
            this.setState({checked:false});
        }
    }

    onSlugChange = (text) => {
        this.slug = text;
        if(this.state.checked){
            this.setState({checked:false});
        }
    }

    updateSlugByTitle = () => {
        let newSlug = accents.remove(this.title).normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "").replace(/[^ -~]/g, '')
            .replace(/[^0-9A-Za-z-]/g, "-").toLowerCase()
            .replace(/[-]{2,}/g, '-').replace(/^-/, '').replace(/-$/, '');
        this.slugInput.setNativeProps({text: newSlug});
        this.slug = newSlug;
    }

    onPhotoButtonClicked = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
        });
        result.base64 = result.uri; // this notation is clearer

        if(!result.cancelled){
            if(result.width === 1200 && result.height === 630){
                let iterationCount = 0;
                const compressionBase = 0.85;
                const sizeBefore = result.base64.length * 0.75 / 1024;
                while(iterationCount < 8){
                    let result2 = await ImageManipulator.manipulateAsync(result.base64, [], {compress: compressionBase - (iterationCount*0.05), format: ImageManipulator.SaveFormat.JPEG})

                    if(result2 && result2.width === 1200 && result2.height === 630){
                        const sizeAfter = result2.base64.length * 0.75 / 1024;

                        if(sizeAfter > 170 || this.calculateCompressionPercentage({sizeBefore, sizeAfter}) < -15){
                            iterationCount++;
                            continue;
                        }

                        this.setState({
                            photo: result2.base64,
                            photoConversionInfo: {
                                sizeBefore,
                                sizeAfter,
                            },
                            checked: false,
                        });
                        return;
                    }else{
                        alert("Yüklediğiniz resim işlenirken bir hata oluştu.");
                    }
                }
                alert("Bu görsel 170 KB boyutun altına kalite bozulmadan indirilemiyor. Lütfen görseli değiştiriniz veya harici bir sıkıştırma yöntemi kullanınız.");
            } else {
                alert("Görsel boyutu 1200x630 olmak zorundadır.");
            }
        }
    }
    
    onInnerPhotoButtonClicked = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
        });
        result.base64 = result.uri; // this notation is clearer
        
        if(!result.cancelled){
            if(result.width >= 540 && result.height >= 540){
                let iterationCount = 0;
                const compressionBase = 0.85;
                const sizeBefore = result.base64.length * 0.75 / 1024;
                
                const resizeFactor = Math.max(result.width, result.height) / 1280;
                const actions = (resizeFactor <= 1) ? [] : [{
                    resize: {
                        width: Math.round(result.width / resizeFactor),
                        height: Math.round(result.height / resizeFactor),
                    },
                }];
                
                while(iterationCount < 8){
                    let result2 = await ImageManipulator.manipulateAsync(result.base64, actions, {compress: compressionBase - (iterationCount*0.05), format: ImageManipulator.SaveFormat.JPEG})
                    
                    if(result2 && result2.width <= 1280 && result2.height <= 1280){
                        const sizeAfter = result2.base64.length * 0.75 / 1024;
                        
                        if(sizeAfter > 170 || this.calculateCompressionPercentage({sizeBefore, sizeAfter}) < -15){
                            iterationCount++;
                            continue;
                        }
                        
                        this.setState({
                            innerPhotos: [...this.state.innerPhotos, {
                                photo: result2.base64,
                                photoConversionInfo: {
                                    sizeBefore,
                                    sizeAfter,
                                },
                                order: this.state.innerPhotos.length + 1,
                                checked: false,
                                photoDimensions: {
                                    width: result2.width,
                                    height: result2.height,
                                }
                            }],
                        });
                        return;
                    }else{
                        alert("Yüklediğiniz resim işlenirken bir hata oluştu.");
                    }
                }
                alert("Bu görsel 170 KB boyutun altına kalite bozulmadan indirilemiyor. Lütfen görseli değiştiriniz veya harici bir sıkıştırma yöntemi kullanınız.");
            } else {
                alert("Görselin boyutlarından herhangi biri 540px'in altında olamaz.");
            }
        }
    }

    componentDidMount() {
        this.getDeviceType().then(()=>{}).catch(()=>{});
    }

    sendBlogPost = async () => {
        const title = cleanSingleLineText(this.title);
        if(!title){
            return alert("Başlık boş olamaz!");
        }

        const subtitle = cleanSingleLineText(this.subtitle);
        if(!subtitle){
            return alert("Kategori boş olamaz!");
        }

        const text = cleanMultilineText(this.text);
        if(!text){
            return alert("Metin (markdown) boş olamaz!");
        }
        
        if(/!\[]\([^\n]*\)/.test(text)){
            return alert("Resimlere mutlaka alt text girilmelidir!");
        }

        const authorName = cleanSingleLineText(this.authorName);
        if(!authorName){
            return alert("Yazar boş olamaz!");
        }

        if(typeof this.date !== 'string' || ! /^\d{4}-\d{2}-\d{2}$/.test(this.date)){
            return alert("Tarih seçilmelidir!");
        }
        if(typeof this.time !== 'string' || ! /^\d{2}:\d{2}$/.test(this.time)){
            return alert("Saat seçilmelidir!");
        }
        const createdAt = prepareTimeInMilliseconds(this.date, this.time);

        const slug = this.slug.replace(/[^0-9A-Za-z-]/g, '');
        if(!slug){
            return alert("Slug boş olamaz!");
        }

        if(!this.state.photo){
            return alert("Ana görsel seçilmelidir!");
        }

        if(createdAt < (new Date()).getTime()){
            if(!prompt("Geçmiş tarihli bir blog ekliyorsunuz. Blog eklendiği anda yayında olacak. Emin misiniz?")){
                return;
            }
        }
        
        const innerPhotosUsed = this.state.innerPhotos.map(()=> false);
        
        const matches = this.text.match(/]\(\/?\d+\.jpg\)/g) || [];
        
        for(const substring of matches){
            if(!substring.includes('/')){
                const incorrectInnerImageNotation = substring.split('(')[1].split(')')[0];
                alert(`${incorrectInnerImageNotation} geçerli bir iç resim değil. Başına / işareti eklemeyi unutmuş olabilirsiniz.`)
                return;
            }
            const order = parseInt(substring.split('.')[0].substring(3));
            if(order <= this.state.innerPhotos.length){
                innerPhotosUsed[order-1] = true;
            } else {
                const incorrectInnerImageNotation = '/' + order + '.jpg';
                alert(`${incorrectInnerImageNotation} geçerli bir iç resim değil.`);
                return;
            }
        }
        
        let alteredText;
        
        if(innerPhotosUsed.includes(false)){
            if(!confirm('Yüklenip kullanılmayan resimler var. Yine de devam etmek istiyor musunuz?')){
                return;
            }
        }
        
        if(!confirm("Blogu göndermek istediğinize emin misiniz?\nBu aksiyon geri alınamaz!\nİyice kontrol ediniz.")){
            return;
        }
        
        this.setState({checked: false});
        
        if(innerPhotosUsed.includes(true)){
            const innerPhotoFileNames = this.state.innerPhotos.map(()=> null);
            
            let uploadFunctions = [];
            for(let i = 0; i < this.state.innerPhotos.length; i++){
                if(innerPhotosUsed[i] === true){
                    uploadFunctions.push(async () => {
                        const {fileName} = await API.uploadBlogInnerPhoto(this.state.innerPhotos[i].photo)
                        innerPhotoFileNames[i] = fileName;
                    });
                }
            }
            
            while (uploadFunctions.length > 0) {
                // 3 at a time
                await Promise.all( uploadFunctions.splice(0, 3).map(f => f()) )
            }
            
            alteredText = this.text.replaceAll(/]\(\/\d+\.jpg\)/g, (substring => {
                const order = parseInt(substring.split('.')[0].substring(3));
                if(order <= this.state.innerPhotos.length){
                    return '](/' + innerPhotoFileNames[order-1] + ')';
                } else {
                    return substring;
                }
            }));
        } else {
            alteredText = this.text;
        }
        
        this.setState({loading: true}, () => {
            API.createBlogPost(title, subtitle, authorName, alteredText, createdAt, slug, this.state.photo, this.state.language).then((response) => {
                this.setState({loading: false}, () => {
                    alert("Blog yazısı başarıyla yaratıldı!");
                    this.props.goBackToBlog(true);
                })
            }).catch((e) => {
                this.setState({loading: false}, () => {
                    if(e.errorCode === 7){
                        alert('Oturumunuz kapatılmış veya süresi dolmuş. Tekrar giriş yapmak için yönlendiriliyorsunuz.');
                        this.props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: "Login" }]
                            })
                        )
                    }
                    else{
                        alert(e.errorMessage);
                    }
                })
            })
        })
        
    }

    getDeviceType = async () => {
        let device=await Device.getDeviceTypeAsync();
        this.setState({ device: Device.DeviceType[device] })
    }

    openPhotoModal = (order) => {
        this.setState({
            photoPreviewUri: (order === 0) ? this.state.photo : this.state.innerPhotos[order-1].photo,
        }, this.photoModal.openModal);
        
    }

    openBlogDisplayModal = () => {
        let incorrectInnerImageNotation = null;
        
        let alteredText = this.text.replaceAll(/]\(\/?\d+\.jpg\)/g, (substring => {
            if(!substring.includes('/')){
                incorrectInnerImageNotation = substring.split('(')[1].split(')')[0];
                return substring;
            }
            const order = parseInt(substring.split('.')[0].substring(3));
            if(order <= this.state.innerPhotos.length){
                return '](' + this.state.innerPhotos[order-1].photo + ')';
            } else {
                incorrectInnerImageNotation = '/' + order + '.jpg';
                return substring;
            }
        }));
        
        if(incorrectInnerImageNotation !== null){
            if(!incorrectInnerImageNotation.includes('/')){
                alert(`${incorrectInnerImageNotation} geçerli bir iç resim değil. Başına / işareti eklemeyi unutmuş olabilirsiniz.`);
            } else {
                alert(`${incorrectInnerImageNotation} geçerli bir iç resim değil.`);
            }
            
            return;
        }
        
        this.setState({
            previewInfo: {
                text: cleanMultilineText(alteredText),
                title: cleanSingleLineText(this.title),
                media: {uri: this.state.photo},
            }
        }, this.blogDisplayModal.openModal);
    }

    calculateCompressionPercentage(conversionInfo){
        let before = conversionInfo.sizeBefore;
        let after = conversionInfo.sizeAfter;

        let rate = (before - after) / before * 100;
        return Math.ceil(rate);
    }

    render() {
        return (
            <View style={{flex: 1}}>
                <ScrollView
                    style={{flex: 1}}
                    contentContainerStyle={{paddingBottom: 30}}
                >
                    <View style={[styles.container, { paddingTop: this.state.device==="PHONE"? 10:20 }]}>
                        <View style={[styles.header, { height: this.state.device==="PHONE"? 56:70 }]}>
                            <TouchableOpacity style={styles.goBackButton} onPress={() => this.props.goBackToBlog()} activeOpacity={0}>
                                <Feather name={"arrow-left"} size={23} color={Colors.greenLight.alpha1} />
                                <Text numberOfLines={1} style={[FontStyles.footnote, { marginLeft: 10, color: Colors.greenLight.alpha1, fontWeight: "600" }]}>
                                    Tüm Bloglar
                                </Text>
                            </TouchableOpacity>
                        </View>

                        <View style={[styles.languageContainer,{marginTop: 16}]}>
                            <Text style={[FontStyles.subhead, {flex: 1, color:Colors.dark.alpha1}]}>
                                Blog Dili:
                            </Text>

                            <View style={styles.buttonContainer}>
                                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({language: "tr",checked:false})} style={[styles.languageButton, {backgroundColor: this.state.language == "tr" ? Colors.greenLight.alpha1 : "transparent"}]}>
                                    <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.language == "tr" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                                        Türkçe
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({language: "en",checked:false})} style={[styles.languageButton, {backgroundColor: this.state.language == "en" ? Colors.greenLight.alpha1 : "transparent"}]}>
                                    <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.language == "en" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                                        İngilizce
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={[styles.pushContainer, {marginBottom: 0}]}>
                            <TextInput
                                style={[styles.textInputText, {height: 30, minHeight: 30, flex: 1, fontWeight: '700'}]}
                                placeholder={"Blog başlığını buraya giriniz (en fazla 100 karakter)."}
                                maxLength={100}
                                selectionColor={Colors.grayLight.alpha1}
                                onChangeText={text => {this.title = text; if(this.state.checked){this.setState({checked:false})} this.updateSlugByTitle()}}
                            />
                        </View>

                        <View style={[styles.pushContainer, {marginBottom: 0}]}>
                            <TextInput
                                style={[styles.textInputText, {color: Colors.grayDark.alpha07, height: 30, minHeight: 30, flex: 1, fontWeight: '600'}]}
                                placeholder={"Blog kategorisini buraya giriniz (en fazla 40 karakter, ÖRNEKLER: " + (this.state.language === 'tr' ? "Psikoloji, Demans, Sinirbilim" : "Psychology, Dementia, Neuroscience") + ")."}
                                maxLength={40}
                                selectionColor={Colors.grayLight.alpha1}
                                onChangeText={text => {this.subtitle = text; if(this.state.checked){this.setState({checked:false})}}}
                            />
                        </View>

                        <View style={[styles.pushContainer, {marginBottom: 0}]}>
                            <TextInput
                                style={[styles.textInputText, {color: Colors.grayDark.alpha05, height: 30, minHeight: 30, flex: 1}]}
                                placeholder={"Blog yazarını buraya giriniz (en fazla 40 karakter, ÖRNEKLER: " + (this.state.language === 'tr' ? "Prof. Dr. Türker Şahiner, Evrim Ağacı" : "Prof. Dr. Türker Şahiner, Tree of Evolution") + ")."}
                                maxLength={40}
                                selectionColor={Colors.grayLight.alpha1}
                                onChangeText={text => {this.authorName = text; if(this.state.checked){this.setState({checked:false})}}}
                            />
                        </View>

                        <View style={styles.pushContainer}>
                            <TextInput
                                multiline
                                placeholder={"Blog metnini `markdown` olarak buraya giriniz."}
                                style={styles.textInputText}
                                defaultValue={this.text}
                                selectionColor={Colors.grayLight.alpha1}
                                onChangeText={text => {this.text = text; if(this.state.checked){this.setState({checked:false})}}}
                            />
                            <TouchableOpacity onPress={this.openBlogDisplayModal} style={styles.previewButton}>
                                <Text style={[FontStyles.caption1]}>Önizleme</Text>
                            </TouchableOpacity>

                        </View>
                        <View style={styles.dateContainer}>
                            <Text style={[FontStyles.subhead, {flex: 1, color:Colors.dark.alpha1}]}>
                                Yayına Giriş Zamanı:
                            </Text>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <DatePicker onChange={this.onDateChange}/>
                                <View style={{width: 12}}/>
                                <TimePicker onChange={this.onTimeChange}/>
                                <Text style={[FontStyles.caption1, {lineHeight:'100%', marginLeft: 6}]}>(GMT+3)</Text>
                            </View>
                        </View>
                        <View style={[styles.dateContainer, {justifyContent: 'space-between'}]}>
                            <Text style={[FontStyles.subhead, {color:Colors.dark.alpha1}]}>
                                Slug:
                            </Text>

                            <TextInput
                                ref={ref=>this.slugInput=ref}
                                style={[styles.textInputSlug]}
                                selectionColor={Colors.grayLight.alpha1}
                                onChangeText={this.onSlugChange}
                                maxLength={100}
                            />
                        </View>
                        <View style={[styles.photoContainer]}>
                            <TouchableOpacity onPress={this.onPhotoButtonClicked} style={[styles.photoButton, this.state.photo ? {width: 150} : {} ]} activeOpacity={0.9}>
                                <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600",textAlign: 'center' }]}>
                                    {this.state.photo ? "Başka Ana Görsel Seç (1200x630)" : "Ana Görsel Seç (1200x630)"}
                                </Text>
                            </TouchableOpacity>
                            {this.state.photo && <TouchableOpacity activeOpacity={0.8} onPress={() => this.openPhotoModal(0)}>
                                <Image resizeMode={"contain"} style={{width: 225, height: 118, flex: 1, marginTop: 12}} source={{uri: this.state.photo}}/>
                            </TouchableOpacity>}
                            {this.state.photo && <Text style={[FontStyles.caption1, {textAlign: 'right', marginTop: 6}]}>
                                {"İlk boyut: " + Math.ceil(this.state.photoConversionInfo.sizeBefore) + " KB" +
                                "\nSon Boyut: " +  Math.ceil(this.state.photoConversionInfo.sizeAfter) + " KB" +
                                ("\nKüçülme Oranı: %" + this.calculateCompressionPercentage(this.state.photoConversionInfo))}
                            </Text>}
                        </View>
                        
                        <View style={[styles.photoContainer]}>
                            <TouchableOpacity disabled={this.state.innerPhotos.length >= 10} onPress={this.onInnerPhotoButtonClicked} style={[styles.photoButton, this.state.photo ? {width: 150} : {} ]} activeOpacity={0.9}>
                                <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600",textAlign: 'center' }]}>
                                    {this.state.innerPhotos.length >= 10 ? 'Limit Doldu' : 'İç Görsel Ekle (Yatay)'}
                                </Text>
                            </TouchableOpacity>
                            {this.state.innerPhotos.map((item, index) => <View key={index+"ExtraPhoto"}>
                                {index !== 0 && <View style={{height: 2, marginTop: 18, width: 250, backgroundColor: Colors.grayLight.alpha07}}/>}
                                <TouchableOpacity activeOpacity={0.8} onPress={() => this.openPhotoModal(item.order)}>
                                    <Image resizeMode={"contain"} style={{width: 250, height: 120, marginTop: (index === 0 ? 12: 24)}} source={{uri: item.photo}}/>
                                </TouchableOpacity>
                                <Text style={[FontStyles.caption1, {textAlign: 'right', marginTop: 6}]}>
                                    {"İlk boyut: " + Math.ceil(item.photoConversionInfo.sizeBefore) + " KB" +
                                        "\nSon Boyut: " +  Math.ceil(item.photoConversionInfo.sizeAfter) + " KB" +
                                        ("\nKüçülme Oranı: %" + this.calculateCompressionPercentage(item.photoConversionInfo)) +
                                        "\nSon ölçü: " + item.photoDimensions.width + "x" + item.photoDimensions.height +
                                        "\nMarkdown: ![](/" + item.order + ".jpg)"}
                                </Text>
                            </View>)}
                            
                        </View>

                        <View style={styles.lastBitsContainer}>
                            <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}} activeOpacity={0.9} onPress={()=>{this.setState({checked: !this.state.checked})}}>
                                <Text style={FontStyles.caption1}>Blog metnini ve bilgilerini kontrol ettim, önizledim.</Text>
                                <View style={{marginLeft: 8, borderRadius: 5, height: 20, width: 20, paddingBottom: 4, paddingHorizontal: 2, borderColor: Colors.dark.alpha03, borderWidth: 0.5}}>
                                    {
                                        this.state.checked ? <Text style={[{fontSize: 17, fontWeight: 'bold', color: Colors.greenLight.alpha1}]}>✓</Text> : <Text> </Text>
                                    }
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.sendButton, {marginTop: 10}]} onPress={this.sendBlogPost} activeOpacity={0.9}>
                                {
                                    this.state.loading ?
                                        <ActivityIndicator color={"white"}/>
                                        :
                                        <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                                            {"Oluştur"}
                                        </Text>
                                }
                            </TouchableOpacity>
                        </View>

                    </View>
                </ScrollView>

                <Modal ref={ref => this.photoModal = ref} containerStyle={{maxWidth: 1360, maxHeight: 790}}>
                    <Image resizeMode={"contain"} style={{flex: 1, margin:80, maxWidth: 1200, maxHeight: 630}} source={{uri: this.state.photoPreviewUri}}/>
                </Modal>
                <Modal ref={ref => this.blogDisplayModal = ref} containerStyle={{maxWidth: 800, maxHeight: 1080, paddingTop: 80, backgroundColor: 'white'}}>
                    <ScrollView contentContainerStyle={{paddingBottom: 100, paddingHorizontal: 64,}}>
                        <BlogDisplay {...this.state.previewInfo} />
                    </ScrollView>
                </Modal>
            </View>
        )
    }
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20
    },
    buttonContainer: {
        backgroundColor: Colors.grayLight.alpha05,
        borderRadius: 5,
        flexDirection: "row",
        alignItems: "center",
    },
    languageButton: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: 34,
        width: 100,
        borderRadius: 5,
    },
    languageContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: Colors.white.alpha1,
    },

    sendButton: {
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        width: 120,
        borderRadius: 5,
        backgroundColor: Colors.greenLight.alpha1,
        paddingHorizontal: 10,
    },
    previewButton: {
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 80,
        borderRadius: 5,
        backgroundColor: Colors.greenLight.alpha02,
        paddingHorizontal: 5,
        position: 'absolute',
        right: 10,
        bottom: 10
    },
    photoButton: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.grayDark.alpha05,
        textAlign: 'center',
        height: 50,
        width: 130,
        borderRadius: 5,
        paddingHorizontal: 10,
    },
    pushContainer: {
        marginBottom: 20,
        backgroundColor: Colors.white.alpha1,
        marginTop: 20,
        borderRadius: 10
    },
    textInputText: {
        minHeight: 300,
        paddingHorizontal: 15,
        paddingVertical: 15,
        color:Colors.dark.alpha1,
        fontWeight: "400"
    },
    textInputSlug: {
        minHeight: 30,
        height: 30,
        paddingHorizontal: 15,
        paddingVertical: 15,
        color:Colors.dark.alpha1,
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: 'transparent',
        fontWeight: "400",
        flex: 1,
        marginLeft: 16,
        textAlign: 'right'
    },

    header: {
        height: 70,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: Colors.white.alpha1,
        borderRadius: 15,
        alignSelf: "center",
        justifyContent: "space-between",
        overflow: "visible"
    },
    goBackButton: {
        height: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.white.alpha1,
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 15,
    },
    dateContainer: {
        marginTop: 20,
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: Colors.white.alpha1,
    },
    photoContainer: {
        marginTop: 20,
        flexDirection: "column",
        alignItems: "flex-end",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: Colors.white.alpha1,
    },
    lastBitsContainer: {
        marginTop: 20,
        flexDirection: "column",
        alignItems: "flex-end",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: Colors.white.alpha1,
    },

})