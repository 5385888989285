import * as React from 'react';
import { View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"

export default class UserRow extends React.PureComponent {
  constructor(props){
    super(props)
  }

  getSignUpDate = (dateObj, isPacketDate) => {
    const date = new Date(dateObj);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); //months from 1-12
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    return isPacketDate ? (day + "/" + month + "/" + year) : (day + "/" + month + "/" + year + "  " + hour + ":" + minute);
  }

  renderItem = (item,key) => {
    if(key === "createdAt"){
      return this.getSignUpDate(item[key]);
    }

    if((key === "lastMembershipCreatedAt" || key === "lastMembershipValidUntil")){
      if(!item[key]){
        return "---"
      }
      return this.getSignUpDate(item[key],true);
    }

    else if(key === "birthYear"){
      if(item[key]){
        return  new Date().getFullYear() - item[key];
      }else{
        return "---";
      }
    }

    if(key === "educationLevel" && !item[key]){
      return "---";
    }
    
    if(key === "lastMembershipSource" && !item[key]){
      return "FREE";
    }

    if(key === "lastMembershipSource" && item[key] && item[key] === "IYZICO-PAYMENT")
      if(this.props.packets){
        for (let i = 0; i < this.props.packets.length; i++) {
          if(this.props.packets[i].id === this.props.item.activePacketId){
            return this.props.packets[i].name
          }
        }
      }

      if(key === "lastMembershipSource" && item[key] && item[key] === "IYZICO-SUBSCRIPTION")
      if(this.props.packets){
        // for (let i = 0; i < this.props.packets.length; i++) {
        //   if(this.props.packets[i].id === this.props.item.activePacketId){
        //     return this.props.packets[i].name
        //   }
        // }
      }

    return item[key] || item.email;
  }

  onUserPressed = () => {
    this.props.onUserPressed(this.props.item, this.props.index)
  }

  render() {
    
    return (
      <View style={{ flexDirection: "row" }}>
      {
        this.props.desiredData.map((obj, index) => {
          if(Object.keys(this.props.item).includes(obj.key)){
            if(index === 0){
              return (
                <TouchableOpacity key={"a"+index} style={[styles.rowContainer, { flex: obj.flex, backgroundColor: this.props.usersToSendWelcomeMessage.find( item => item.id == this.props.item.id) ? Colors.greenLight.alpha06 : index % 2 == 0 ? Colors.white.alpha1 : Colors.white.alpha07, paddingHorizontal: 8 }]} onPress={this.onUserPressed} >
                  <Text style={[FontStyles.caption1, {color: Colors.dark.alpha08}]} numberOfLines={1} selectable={true} >
                    {this.renderItem(this.props.item, obj.key)}
                  </Text>
                </TouchableOpacity>
              )
            }
            return (
              <View key={"a"+index} style={[styles.rowContainer, { flex: obj.flex, backgroundColor: index % 2 == 0 ? Colors.white.alpha1 : Colors.white.alpha07 }]}>
                <Text style={[FontStyles.caption1, {color: Colors.dark.alpha08}]} numberOfLines={1} selectable={true}>
                  {this.renderItem(this.props.item, obj.key)}
                </Text>
              </View>
            )
          }
          return null;
        })
      }
      <TouchableOpacity onPress={() => this.props.navigation.navigate("UserDetail", {userId: this.props.item.id})} style={styles.rowButton} activeOpacity={0.9}>
        <Text style={[FontStyles.caption2, { fontWeight: "600", color: Colors.white.alpha1 }]}>
          İncele
        </Text>
      </TouchableOpacity>
    </View>
    )
  }
};


const styles = StyleSheet.create({
  rowContainer: {
    paddingVertical: 10,
    borderRightWidth: 1,
    borderRightColor: Colors.grayLight.alpha1,
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayLight.alpha1,
    alignItems: "center",
    borderLeftColor:Colors.grayLight.alpha1,
    borderLeftWidth:1
  },
  rowButton: {
    padding: 10,
    paddingHorizontal: 20,
    borderBottomColor: Colors.grayLight.alpha1,
    borderBottomWidth: 1,
    backgroundColor: Colors.greenLight.alpha1
  },
})