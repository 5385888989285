import { memo, useState } from "react";
import { StyleSheet, TextInput, View, Text, Pressable, ActivityIndicator, FlatList } from "react-native";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import Feather from "@expo/vector-icons/Feather";
import API from "../utils/API";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import * as Clipboard from 'expo-clipboard';

function generateData(arr){
  let used = []
  let unused = []
  for(let i = 0 ; i < arr.length; i++){
    if(arr[i].issuedUserId){
      used.push(arr[i])
    }else{
      unused.push(arr[i])
    }
  }

  return {used, unused}
}

function generateCopyString(codes){
  console.log(codes)
  let codesAsString = codes.reduce((acc, current, index) => {
    return acc + `${current.code}${index !== codes.length - 1 ? ";" : ""}`
  },"")

  console.log(codesAsString)
  return codesAsString
}

function UsedCodeRow({code}){
  const navigation = useNavigation()
  const [showDetails, setShowDetails] = useState(false)

  return (
    <View style={styles.codeContainer} >
      <Pressable onPress={()=> setShowDetails(showDetails => !showDetails)} style={{flexDirection: "row", alignItems: "center"}}>
        <Feather
          name={showDetails ? "chevron-down" : "chevron-right"}
          color={Colors.greenLight.alpha1}
          size={16}
        />
        <Text selectable style={[FontStyles.subhead, { color: Colors.greenLight.alpha08, marginRight: 4, flex: 1}]}>
          {code.code}
        </Text>
      </Pressable>
      {
        showDetails &&
        <>
          <Text style={[FontStyles.caption1, { color: Colors.gray.alpha08, fontWeight: "500"}]}>
            {"Source: "}
            <Text selectable style={[FontStyles.caption2, { color: Colors.gray.alpha05}]}>
              {code.source}
            </Text>
          </Text>
          <Text style={[FontStyles.caption1, { color: Colors.gray.alpha08, fontWeight: "500"}]}>
            {"Aktifleştirme Tarihi: "}
            <Text selectable style={[FontStyles.caption2, { color: Colors.gray.alpha05}]}>
              {moment(code.issuedAt).format("DD/MM/YYYY")}
            </Text>
          </Text>
          <Pressable style={{alignSelf: "baseline"}} onPress={()=> navigation.navigate("UserDetail", {userId: code.issuedUserId})}>
            <Text style={[FontStyles.caption1, { color: Colors.gray.alpha08, fontWeight: "500"}]}>
              {"Kullanıcı: "}
              <Text selectable style={[FontStyles.caption2, { color: Colors.gray.alpha05, textDecorationLine: "underline"}]}>
                {code.issuedUserId}
              </Text>
            </Text>
          </Pressable>
        </>
        
      }
      
    </View>
  )
}

function UnusedCodeRow({code}){
  return (
    <View style={styles.codeContainer}>
      <Text selectable style={[FontStyles.subhead, { color: Colors.red.alpha08 }]}>
        {code.code}
      </Text>
    </View>
  )
}

function CodeList({title, codes, titleColor }){
  const [copied, setCopied] = useState(false)

  const copyCodes = async () => {
    if(!codes || !codes.length){
      return 
    }
    let str = generateCopyString(codes)
    await Clipboard.setStringAsync(str)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }

  const renderCodes = ({item, index}) => {
    if(!item.issuedUserId){
      return <UnusedCodeRow code={item}/>
    }

    return (
      <UsedCodeRow code={item}/>
    )
  }

  return (
    <View style={{flex: 1, marginLeft: 12}}>
      <View style={{flexDirection: "row", alignItems: "center"}}>
        <Text style={[FontStyles.callout, {color: titleColor, fontWeight: "500"}]}>
          {title}
        </Text>
        <Pressable onPress={copyCodes} style={{marginLeft: 12}}>
          <Feather
            name={"clipboard"}
            size={14}
            color={Colors.gray.alpha05}
          />
        </Pressable>
        <Text style={[FontStyles.caption2, {marginLeft: 2, color: Colors.gray.alpha05, fontWeight: "500"}]}>
          {copied ? "Kopyalandı!" : "Kopyala"}
        </Text>
      </View>

      <FlatList
        data={codes || []}
        renderItem={renderCodes}
        style={{height: 400}}
        keyExtractor={item => item.code}
      />
    </View>
  )
}

function ActivationCodeCheck(){
  const [text, setText] = useState("")
  const [codes, setCodes] = useState(null)
  const [loading, setLoading] = useState(false)

  const onSend = async () => {
    if(text.length){
      let c = text.split(";")
      try{
        setLoading(true)
        const {codes} = await API.checkActivationCode(c)
        const obj = generateData(codes)
        setCodes(obj)
        setLoading(false)
      }catch(e){
        setLoading(false)
        alert(e.errorMessage)
      }
    }
  }
  
  return (
    <View style={styles.container}>
      <Text style={[FontStyles.subhead, { fontWeight: "600", color: Colors.grayDark.alpha1, marginBottom: 16 }]}>
        {"Aktivasyon Kodu Kontrol Alanı"}
      </Text>
      <View style={{flexDirection: "row", width: "100%"}}>
        <View style={{flex: 1}}>
          <TextInput
            style={styles.input}
            autoCapitalize={"characters"}
            autoComplete="none"
            autoCorrect={false}
            onChangeText={text => setText(text)}
            multiline
            placeholder="En az 1 aktivasyon kodu ekleyin. 1'den fazla kod eklenirse aralarına noktalı virgül konmalıdır."
            placeholderTextColor={Colors.gray.alpha08}
          />
          <Pressable 
            style={styles.sendButton} 
            onPress={onSend}
          >
            {
              !loading ?
              <Text style={[FontStyles.subhead, {color: "white"}]}>
                {"Gönder"}
              </Text>
              :
              <ActivityIndicator
                color={"white"}
              />
            }
          </Pressable>
        </View>
        <CodeList
          title={"Kullanılmış"}
          codes={codes?.used}
          titleColor={Colors.greenLight.alpha1}
        />
        <CodeList
          title={"Kullanılmamış"}
          codes={codes?.unused}
          titleColor={Colors.red.alpha1}

        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: "97%",
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 15,
    marginBottom: 15,
  },
  input: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.gray.alpha005,
    borderRadius: 5,
    paddingHorizontal: 12,
    paddingVertical: 12,
    outline: "none",
    lineHeight: 22,
    alignSelf: "baseline"
  },
  sendButton: {
    width: 200,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.greenLight.alpha1,
    borderRadius: 100,
    marginTop: 16
  },
  codeContainer: {
    backgroundColor: Colors.gray.alpha005,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 10,
    marginTop: 10,
    rowGap: 8,
    width: "70%"
  }

})

export default memo(ActivationCodeCheck)