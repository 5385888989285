import * as React from 'react';
import { Image, View, Text,Animated, Switch, TouchableOpacity, StyleSheet, TextInput,Pressable, ActivityIndicator, Dimensions} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons"
import { FlatList, ForceTouchGestureHandler } from 'react-native-gesture-handler';
import API from '../utils/API'
import Loading from "../components/Loading";
import * as Device from 'expo-device';
import moment from 'moment'
import {CommonActions} from "@react-navigation/native";

const SCREEN_WIDTH = Dimensions.get("window").width
const SCREEN_HEIGHT = Dimensions.get("window").height
const PHONE_VIEW_UPPER_WIDTH_BOUND = 850

export default class DirectMessage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.message = "";
    this.newTicketTitle= "" 
    this.newTicketBody = ""
    this.users = null
    this.originalDeviceType = ""
    this.refreshTimeout = null
    this.shouldRefreshMessage = true
    this.ticketsForSearch = []
    this.prevTicket = null;
    this.shouldGetAllTickets = true
    this.filteredTicketSearch = []

    this.state = {
      selectedRow: 0,
      data:[],
      mediaBasePath: "",
      messages:[],
      messageSendLoading: false,
      newTicket:false,
      userToSend: null,
      users: [],
      device: "",
      newTicketLoading: false,
      selectedTicketFilter:1,
      ticketFilterLoading: false,
    };
  }

  componentDidMount() {
    this.getSupportTickets(true).then(()=>{
    }).catch(()=>{});
    this.getDeviceType();
    Dimensions.addEventListener("change", this.onChange);
    this._unsubscribeBlur = this.props.navigation.addListener('blur',this.screenClosed)
    this._unsubscribeFocus = this.props.navigation.addListener('focus',this.screenOpened)
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this.onChange)
    this._unsubscribeBlur();
    this._unsubscribeFocus();
  }

  getDeviceType = async () => {
    let device=await Device.getDeviceTypeAsync();
    this.windowWidth = Dimensions.get('window').width
    this.originalDeviceType = Device.DeviceType[device]
    this.setState({ device: this.windowWidth < PHONE_VIEW_UPPER_WIDTH_BOUND ? "PHONE" : this.originalDeviceType })
  }

  filterByName = (text) => {

    if(text.length === 0){
      this.setState({users:[]})
    }else{
      let filteredCharacters = this.users.filter(user => {
        return (
          user.username.toLowerCase().includes(text.toLowerCase()) || user.userId.toLowerCase().includes(text.toLowerCase()) || user.email.toLowerCase().includes(text.toLowerCase())
        );
      });
      this.setState({ users: filteredCharacters })
    }
  }

  trimMultilineText(text){
    return text.replace(/\t/g, ' ').replace(/ \n/g, '\n').replace(/\n /g, '\n').replace(/\n{3,}/g, '\n\n').replace(/ {2,}/g, ' ').replace(/^\s{1,}/, '').replace(/\s{1,}$/, '')
  }

  convertDate = (dateObj,isForMessage) => {
    if(isForMessage){
      return moment(dateObj).format("DD/MM HH:mm")
    }
    return moment(dateObj).format("DD/MM HH:mm")

  }

  onChange = ({ window }) => {
    this.windowWidth = window.width
    this.setState({ device: this.windowWidth < PHONE_VIEW_UPPER_WIDTH_BOUND ? "PHONE" : this.originalDeviceType })
  }

  screenClosed = () => {
    if(this.refreshTimeout){
      this.shouldRefreshMessage = false;
      clearTimeout(this.refreshTimeout);
    }
  }

  screenOpened = () => {
    this.shouldRefreshMessage = true;
    this.refreshMessages();
  }

  getUsers = () => {
    API.getUsers().then((response) => {
      this.users = response.users;
    }).catch((e) => {
      alert(e.errorMessage)
      if(e.errorCode === 7){
        this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Login" }]
            })
        )
      }
    })
  }

  createNewTicket = () => {
    if(this.state.userToSend){
      if(confirm("Bu ticket'ı açmak istediğinize emin misiniz?")){
        this.setState({newTicketLoading:true})
        API.createNewSupportTicket(this.state.userToSend.id,this.newTicketTitle,this.newTicketBody).then((response)=>{
          this.getSupportTickets();
          this.newTicketBodyRef.clear();
          this.newTicketTitleRef.clear();
          this.setState({newTicketLoading:false,userToSend: null},()=>{
            this.newTicketTitle = "";
            this.newTicketBody = "";
          })
        }).catch((e)=>{
          this.setState({newTicketLoading:false})
          alert(e.errorMessage);
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          }
        })
      }
    }else{
      alert("Ticket açmak için bir kullanıcı seçmelisiniz.");
    }
  }

  ticketOnPress = (ticketId,index) => {
    if(index !== this.state.selectedRow){   
      this.setState({newTicket: false,selectedRow: index},()=>{
        this.getMessages(ticketId)
      })
    }
  }

  getSupportTickets = async (getActivesOnly = null) => {
    try {
      this.ticketsForSearch = [];
      let mediaBasePath;
      while(true){
        let response; 
        if(this.ticketsForSearch.length === 0){
          response = await API.getSupportTickets(4000, null, getActivesOnly);
        }
        else {
          response = await API.getSupportTickets(4000, this.ticketsForSearch[this.ticketsForSearch.length - 1].lastMessageAt,getActivesOnly);
        }
        this.ticketsForSearch.push(...response.supportTickets);
        mediaBasePath = response.mediaBasePath;
        if(!response.morePagesExist){
          this.filteredTicketSearch = [...this.ticketsForSearch]
          break;
        }
      }

      this.setState({
        data: this.ticketsForSearch,
        mediaBasePath: mediaBasePath,
        selectedRow:0
      },()=>{
        if(this.state.data.length > 0){
          this.getMessages(this.state.data[this.state.selectedRow].id);
        }
        
        this.getUsers();
      })
      this.loading.hide();
    }
    catch(e){
      this.ticketsForSearch = [];
      alert(e.errorMessage);
      if(e.errorCode === 7){
        this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Login" }]
            })
        )
      }
    }
  }

  changeConversationStatus = (ticketId,value,index) => {
    let text = value ? 'aktifleştirmek' : 'pasifleştirmek'
    if(confirm(`Bu konuşmayı ${text} istediğinize emin misiniz?`)){
      API.changeConversationStatus(ticketId,value).then((response)=>{
        let arr = [...this.state.data]
        arr[index].isConversationGoing = value
        this.setState({data: arr})
        console.log(response)
      }).catch((e)=>{
        alert(e.errorMessage)
        if(e.errorCode === 7){
          this.props.navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: "Login" }]
              })
          )
        }
      })
    }
  }

  refreshMessages = () => {
    this.refreshTimeout = setTimeout(() => {
      if(this.state.data.length !== 0 && this.shouldRefreshMessage){
        API.getMessages(this.state.data[this.state.selectedRow].id).then((response)=>{
          this.setState({messages:response.messages.reverse()},()=>{
            this.refreshMessages()
          });
        }).catch((e)=>{
          alert(e.errorMessage)
          this.shouldRefreshMessage = false;
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          }
        })
      }
    }, 20000);
  }

  getMessages = (ticketId) => {
    API.getMessages(ticketId).then(messages=>{
      this.setState({messages:messages.messages.reverse()})
    }).catch((e)=>{
      alert(e.errorMessage);
      if(e.errorCode === 7){
        this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Login" }]
            })
        )
      }
    })
  }

  sendMessage = (ticketId,text) => {
    if(text.length !== 0 && text.length < 2000){
      let selectedRow = this.state.selectedRow
      this.setState({messageSendLoading: true},()=>{
        API.sendMessage(ticketId,text).then(()=>{
          this.messageInput.clear();
          let messageObj = {createdAt: new Date().getTime(),text:this.trimMultilineText(text),isFromTeam:true};
          let messages = [...this.state.messages,messageObj];
          let tickets = [...this.state.data];
          tickets[selectedRow].isLastMessageFromTeam = true;
          this.setState({messages:messages,messageSendLoading:false,data:tickets},()=>{
            this.message = "";
          });
        }).catch((e)=>{
          this.setState({messageSendLoading: false})
          this.messageInput.clear();
          alert(e.errorMessage)
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          }
        })
      })
    }else{
      alert("Mesajın uzunluğu 0 karakterden fazla ve 2000 karakterden az olmalıdır.");
    }
  }

  changeTicketStatus = (ticketId,isOpen,index)=>{
    let action = isOpen ? "kullanıcının banını kaldırmak" : "kullanıcıyı banlamak"
    if(confirm("Bu " + action + " istediğnize emin misiniz?")){
      API.changeSupportTicketStatus(ticketId,isOpen).then(()=>{
        let arr = [...this.state.data];
        let obj = arr[index];
        obj.isOpen = !obj.isOpen;
        arr[index] = obj;
        this.setState({data:arr});
      }).catch((e)=>{
        alert(e.errorMessage);
        if(e.errorCode === 7){
          this.props.navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: "Login" }]
              })
          )
        }
      })  
    }
  }

  messageHeader = () => {
    return (
      <View style={styles.messageHeaderContainer}>
        <TouchableOpacity style={[styles.banButton,{backgroundColor: this.state.data[this.state.selectedRow] && this.state.data[this.state.selectedRow].isOpen ? Colors.red.alpha1 : Colors.greenLight.alpha1 }]} onPress={()=>this.changeTicketStatus(this.state.data[this.state.selectedRow].id,!this.state.data[this.state.selectedRow].isOpen,this.state.selectedRow)}>
          <Text style={[FontStyles.caption1,{color:Colors.white.alpha1}]}>
            { this.state.data[this.state.selectedRow] && this.state.data[this.state.selectedRow].isOpen ? 'Banla' : 'Banı Kaldır'}
          </Text>
        </TouchableOpacity>
        <View style={{flexDirection:"row",alignItems:"center"}}>
          <Text selectable style={[FontStyles.caption1, { color: Colors.dark.alpha1 }]}>
            {this.state.data.length === 0 ? " " : this.state.data[this.state.selectedRow].client.username }
          </Text>
          <TouchableOpacity style={{marginLeft: 20,backgroundColor:Colors.greenLight.alpha1,paddingVertical:10,paddingHorizontal:16,borderRadius:5}} onPress={()=>this.props.navigation.navigate("UserDetail",{userId:this.state.data[this.state.selectedRow].client.id})} >
            <Text style={[FontStyles.caption1,{color:Colors.white.alpha1}]}>
              {"İncele"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  messageFooter=() => {
    return (
      <View style={{flexDirection:"row",paddingVertical:20}}>
        <TextInput
          style={[styles.messageInput, FontStyles.footnote, { color: Colors.dark.alpha1, fontWeight: "500", lineHeight: 24 }]}
          placeholder={"Mesaj Yazınız..."}
          onChangeText={(text)=>this.message = text}
          multiline
          ref={ref => this.messageInput = ref}
        />
        <TouchableOpacity style={styles.sendButton} activeOpacity={0.9} onPress={()=> this.sendMessage(this.state.data[this.state.selectedRow].id,this.message)} disabled={this.state.messageSendLoading}>
          {
            this.state.messageSendLoading ? 
            <ActivityIndicator color={Colors.greenLight.alpha1}/>
            :
            <Feather name={"send"} size={this.state.device === "PHONE" ? 24 :30} color={Colors.greenLight.alpha1} />
          }
        </TouchableOpacity>
      </View>
    )
  }

  renderMessages = ({ item, index }) => {
    return (
      <View style={{ alignSelf: item.isFromTeam ? "flex-end":"flex-start", marginTop: index==0? "5%":10, flexDirection: "row", alignItems: "center", width: "80%" }}>
        <Text 
          selectable={true}
          style={[FontStyles.caption1, { color: item.isFromTeam ? Colors.white.alpha1:Colors.dark.alpha1, flex: 1, lineHeight: 24, borderRadius: 8, padding: 10, backgroundColor: item.isFromTeam ? Colors.greenLight.alpha1:Colors.grayLight.alpha1 }]}
          dataDetectorType={"all"}>
          {item.text}
        </Text>
        <Text style={[{ fontSize: 10, color: Colors.gray.alpha05, marginLeft: 5,textAlign:"center"}]} >
          {this.convertDate(item.createdAt, true)}
        </Text>
      </View>
    )
  }

  renderRow=({ item, index }) => {
    let isSelected = this.state.selectedRow === index;
    if(this.state.device === "PHONE"){
      return(
        <TouchableOpacity activeOpacity={0.9} onPress={() => {this.ticketOnPress(item.id,index)}} style={[styles.userInfoRow, {maxWidth:300,flexDirection: "column",backgroundColor:isSelected ? Colors.lavender.alpha06 :  !item.isLastMessageFromTeam ? Colors.lightGreen.alpha1 : Colors.white.alpha1, borderRightWidth: 2,}]}>
          <View style={{flexDirection: "row", alignItems:"center",justifyContent:"space-between"}}>
            {
              item.client.photo ? 
              <Image style={{ width: 50, height: 50, borderRadius: 25 }} source={{uri:this.state.mediaBasePath + "~" + item.client.photo}} resizeMode={"cover"} />
              :
              <View style={{ width: 50, height: 50,  borderRadius: 25,justifyContent:"center",alignItems:"center",borderWidth:1,borderColor:Colors.grayLight.alpha1 }}>
                <Feather name={"user"} size={24} color={Colors.grayLight.alpha1}/>
              </View>
            }
            <View style={{marginLeft: 10,flex:1}}>
              <Text style={[FontStyles.caption2, { color: Colors.gray.alpha07,fontWeight:"500", }]}>
                {item.client.username + " • " + this.convertDate(item.lastMessageAt, false)}
              </Text>
              <Text style={[FontStyles.footnote, { color: Colors.dark.alpha1, fontWeight: "600",marginTop:8 }]} numberOfLines={2}>
                {item.title} 
              </Text>
            </View>
          </View>
          <Switch
            trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
            value={item.isConversationGoing}
            onValueChange={value=>{this.changeConversationStatus(item.id,value,index)}}
            style={{marginTop:8}}
          />
        </TouchableOpacity>
      )
    }else{
      if(this.state.ticketFilterLoading){
        if(index === 0){
          return(
            <View style={{marginTop:32,alignItems:'center'}}>
              <ActivityIndicator />
              <Text style={[FontStyles.footnote,{marginTop:16}]}>
                Yükleniyor
              </Text>
            </View>

          )
        }
        return null
      }
      return (
        <TouchableOpacity activeOpacity={0.9} onPress={() => {this.ticketOnPress(item.id,index)}} style={[styles.userInfoRow, {backgroundColor: isSelected ? Colors.lavender.alpha06 : !item.isLastMessageFromTeam? Colors.lightGreen.alpha1 : Colors.white.alpha1, borderBottomWidth: 2,}]}>
          {
            item.client.photo ? 
            <Image style={{ width: 50, height: 50, borderRadius: 25 }} source={{uri:this.state.mediaBasePath + "~" + item.client.photo}} resizeMode={"cover"} />
            :
            <View style={{ width: 50, height: 50,  borderRadius: 25,justifyContent:"center",alignItems:"center",borderWidth:1,borderColor:Colors.grayLight.alpha1 }}>
              <Feather name={"user"} size={24} color={Colors.grayLight.alpha1}/>
            </View>
          }
          <View style={[styles.messageInfo,{flexDirection:'row',justifyContent:"space-between"}]}>
            <View>
              <Text style={[FontStyles.caption2, { color: Colors.gray.alpha07,fontWeight:"500" }]}>
                {item.client.username || item.client.email }
              </Text>
                <Text style={[FontStyles.footnote, { color: Colors.dark.alpha1, fontWeight: "600",marginTop:10 }]} numberOfLines={2}>
                {this.convertDate(item.lastMessageAt,false)}
              </Text>
            </View>
            <View style={{justifyContent:'space-between'}}>
              <Text style={[FontStyles.footnote,{color:Colors.dark.alpha1}]}>
                {item.isConversationGoing ? 'Aktif' : 'İnaktif'}
              </Text>
              <Switch
                trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                value={item.isConversationGoing}
                onValueChange={value=>{this.changeConversationStatus(item.id,value,index)}}
              />
            </View>
          </View>
        </TouchableOpacity>
      )
    }
  }

  emptyMessage = () => {
    return(
      <Text style={[FontStyles.body,{color:Colors.dark.alpha1,marginTop:24,textAlign:'center'}]}>
        {'Mesaj bulunmamaktadır.'}
      </Text>
    )
  }

  searchTicket = (text) => {
    let filteredTickets = this.filteredTicketSearch.filter(ticket => {
      let result = ticket.client.email.toLowerCase().includes(text.toLowerCase())
      if(ticket.client.username && !result){
        result = ticket.client.username.toLowerCase().includes(text.toLowerCase())
      }
      return result
    });
    this.setState({ data: filteredTickets,selectedRow:0},()=>{
      if(this.state.data[0] !== this.prevTicket && this.state.data.length !== 0){
        this.getMessages(this.state.data[this.state.selectedRow].id)
        this.prevTicket = this.state.data[0]
      }else if(this.state.data.length === 0){
        this.setState({messages:[]})
      }
    })
  }
  
  filterTicket = () => {
    let filteredTickets = []
    if(this.state.selectedTicketFilter === 1){
      filteredTickets = this.ticketsForSearch.filter(ticket => { return (ticket.isConversationGoing)});
      this.filteredTicketSearch = filteredTickets
      this.setState({ data: filteredTickets, selectedRow: 0, ticketFilterLoading: false },() => {
        if(this.state.data.length !== 0){
          this.getMessages(this.state.data[this.state.selectedRow].id)
          this.prevTicket = this.state.data[0]
        }else{
          this.setState({messages:[]})
        }
      })
    }else if(this.state.selectedTicketFilter === 2){
      filteredTickets = this.ticketsForSearch.filter(ticket => { return ticket.isConversationGoing && !ticket.isLastMessageFromTeam});
      this.filteredTicketSearch = filteredTickets
      this.setState({ data: filteredTickets, selectedRow: 0 , ticketFilterLoading: false},() => {
        if(this.state.data.length !== 0){
          this.getMessages(this.state.data[this.state.selectedRow].id)
          this.prevTicket = this.state.data[0]
        }else{
          this.setState({messages:[]})
        }
      })  
    }else{
      if(this.shouldGetAllTickets){
        this.getSupportTickets().then(()=>{
          this.shouldGetAllTickets = false
          this.filteredTicketSearch = this.ticketsForSearch
          this.setState({ticketFilterLoading:false, selectedRow: 0})
        })
      }else{
        this.filteredTicketSearch = this.ticketsForSearch
        this.setState({ data: this.ticketsForSearch, selectedRow: 0 , ticketFilterLoading: false},() => {
          if(this.state.data.length !== 0){
            this.getMessages(this.state.data[this.state.selectedRow].id)
            this.prevTicket = this.state.data[0]
          }else{
            this.setState({messages:[]})
          }
        })
      }

    }
  }

  ticketsEmpty = () => {
    return(
      <Text style={[FontStyles.body,{color:Colors.dark.alpha05,textAlign:"center",marginTop:20}]}>
        {"Ticket bulunamadı."}
      </Text>
    )
  }

  createTicketComponent = () => {
    return(
      <View style={{padding:16,height:"100%",borderBottomWidth:this.state.device === "PHONE" ? 0 : 2,borderColor:Colors.grayLight.alpha1,flexDirection:this.state.device === "PHONE" ? "row" : "column", borderRightWidth:this.state.device === "PHONE" ? 2 : 0}}>
        <TouchableOpacity style={[styles.createTicketContainer,{}]} activeOpacity={0.9} onPress={this.newTicketPressed}>
          <View style={styles.createTicket}>
            <Feather name={"plus"} size={30} color={Colors.gray.alpha1}/>
            <Text style={[FontStyles.footnote,{color:Colors.gray.alpha1,marginTop:16,textAlign:"center"}]}>
              Mesaj Gönder
            </Text>
          </View>
        </TouchableOpacity>
        <TextInput
          style={[FontStyles.callout,{color:Colors.gray.alpha1,marginTop:this.state.device==="PHONE" ? 0 : 16,padding:16,outlineWidth:0,backgroundColor:Colors.grayLight.alpha1,borderRadius:8,marginLeft: this.state.device === "PHONE" ? 16 : 0,alignSelf: this.state.device === "PHONE" ? "center" : null}]}
          placeholder={"Kullanıcı Ara"}
          placeholderTextColor={Colors.gray.alpha1}
          onChangeText={this.searchTicket}
          ref = {ref => this.userSearch = ref}
          autoCompleteType={false}
          autoCapitalize={false}
          autoCorrect={false}
        />
        <View style={{flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: 20, paddingHorizontal: 20}}>
          <Text style={[FontStyles.callout, {color: Colors.greenLight.alpha1, fontWeight: "600",marginTop:20}]}>
            {this.state.selectedTicketFilter === 2 ? "Cevapsızlar (" + this.state.data.length + ")" : this.state.selectedTicketFilter === 1 ?  "Aktifler (" + this.state.data.length + ")" : "Tümü (" + this.state.data.length + ")"}
          </Text>
          <View style={{alignItems:'center'}}>
            <Text style={[FontStyles.caption1,{marginBottom:6}]}>
              {'T'}
            </Text>
            <TouchableOpacity activeOpacity={0.9} disabled={this.state.ticketFilterLoading} onPress={() => this.setState({selectedTicketFilter: 0,ticketFilterLoading: true}, () => this.filterTicket())} style={{width: 30, height: 30, borderRadius: 5, backgroundColor: this.state.selectedTicketFilter === 0 ? Colors.greenLight.alpha1 : Colors.red.alpha1}}/>
          </View>
          <View style={{alignItems:'center'}}>
            <Text style={[FontStyles.footnote,{marginBottom:6}]}>
              {'A'}
            </Text>
            <TouchableOpacity activeOpacity={0.9} disabled={this.state.ticketFilterLoading} onPress={() => this.setState({selectedTicketFilter: 1,ticketFilterLoading: true}, () => this.filterTicket())} style={{width: 30, height: 30, borderRadius: 5, backgroundColor: this.state.selectedTicketFilter === 1 ? Colors.greenLight.alpha1 : Colors.red.alpha1}}/>
          </View>
          <View style={{alignItems:'center'}}>
            <Text style={[FontStyles.footnote,{marginBottom:6}]}>
              {'C'}
            </Text>
            <TouchableOpacity activeOpacity={0.9} disabled={this.state.ticketFilterLoading} onPress={() => this.setState({selectedTicketFilter: 2,ticketFilterLoading: true}, () => this.filterTicket())} style={{width: 30, height: 30, borderRadius: 5, backgroundColor: this.state.selectedTicketFilter === 2 ? Colors.greenLight.alpha1 : Colors.red.alpha1}}/>
          </View>
        </View>
      </View>
    )
  }

  newTicketPressed = () => {
    this.setState({newTicket: true})
  }

  emptySearch = () => {
    return (
      <View style={{height:0}}/>
    )
  }

  renderUsers = ({item,index}) => {
    return(
      <TouchableOpacity style={styles.userSearchRow} onPress={()=>this.chooseUser(index)} activeOpacity={0.9}>
        <Text style={[FontStyles.footnote,{color:Colors.dark.alpha1}]}>
          {item.username}
        </Text>
      </TouchableOpacity>
    )
  }

  chooseUser = (index) => {
    this.setState({userToSend:this.state.users[index] ,users: []},()=>{
    })
    this.userSearch.clear()
  }

  deleteSelectedUser = () => {
    if(this.state.userToSend){
      this.setState({userToSend: null})
    }
  }

  newTicket = () => {
    return(
      <View style={[styles.newTicketContainer,{flex: this.state.device === "PHONE" ? 4 : 2,}]}>
        <View style={{flexDirection:"row",width:"100%"}}>
          <View style={styles.searchUser}>
            <Feather name="search" size={this.state.device === "PHONE" ? 20 : 24} color={Colors.gray.alpha1}/>
            <TextInput
              style={[FontStyles.callout,{color:Colors.gray.alpha1,marginLeft:8,flex:1,paddingVertical:16,outlineWidth:0}]}
              placeholder={"Kullanıcı Ara"}
              placeholderTextColor={Colors.gray.alpha1}
              onChangeText={this.filterByName}
              ref = {ref => this.userSearch = ref}
            />
          </View>
          <FlatList
            style={styles.searchList}
            ListEmptyComponent={this.emptySearch}
            data={this.state.users}
            renderItem={this.renderUsers}
            keyExtractor={(item)=>item.id}
          />
          <TouchableOpacity style={styles.sendNewTicket} onPress={this.createNewTicket} disabled={this.state.newTicketLoading}>
            {
              this.state.newTicketLoading ?
              <ActivityIndicator color={Colors.white.alpha1}/> 
              :
              <Feather name="send" size={ this.state.device === "PHONE" ? 20 : 24} color={Colors.white.alpha1}/>
            }
            {
              this.state.device !== "PHONE" && !this.state.newTicketLoading &&
              <Text style={[FontStyles.body,{color:Colors.white.alpha1,marginLeft:8}]}>
                Gönder
              </Text>
            }
          </TouchableOpacity>
        </View>
        <View style={styles.newTicketBodyInput}>
          <View style={{flexDirection:"row",alignItems:"center",flexWrap:"wrap-reverse"}}>
            <TextInput
              style={[FontStyles.body,{lineHeight:24,color:Colors.gray.alpha1,paddingVertical:16,borderBottomWidth:1,borderColor:Colors.gray.alpha05,outlineWidth:0,flex:1,minWidth:SCREEN_WIDTH * 0.1}]}
              placeholder={"Başlık yazınız..."}
              placeholderTextColor={Colors.gray.alpha1}
              onChangeText={text => this.newTicketTitle = text}
              ref={ref=>this.newTicketTitleRef = ref}
              multiline
            />  
            <TouchableOpacity style={styles.deleteUser} onPress={this.deleteSelectedUser} activeOpacity={0.9}>
              <Feather name="x" size={16} color={Colors.white.alpha1}/>
            </TouchableOpacity>
            <Text style={[FontStyles.footnote,{color:Colors.gray.alpha1}]}>
              {this.state.userToSend ? "Kime: " + this.state.userToSend.username : "Kime: -"}
            </Text>
          </View>
          <TextInput
            style={[FontStyles.body,{color:Colors.gray.alpha1,flex:1,marginTop:16,outlineWidth:0}]}
            placeholder={"Mesaj yazınız..."}
            placeholderTextColor={Colors.gray.alpha1}
            onChangeText={text => this.newTicketBody = text}
            multiline
            ref={ref=>this.newTicketBodyRef = ref}
          />
          
        </View>
      </View>
    )
  }

  render() {
    return (
      <View style={[styles.container,{flexDirection: this.state.device === "PHONE" ? "column" : "row"}]}>
        <FlatList
          renderItem={this.renderRow}
          data={this.state.data}
          showsVerticalScrollIndicator={false}
          style={{ flex: 1,backgroundColor: Colors.white.alpha1, marginLeft: 10, marginTop: 20, borderTopRightRadius:15,borderTopLeftRadius:15,marginRight:this.state.device === "PHONE" ? 10 : 0,borderBottomLeftRadius: this.state.device === "PHONE" ? 15 : 0,borderBottomRightRadius: this.state.device === "PHONE" ? 15 : 0 }}
          keyExtractor={(item,index)=> "f" + item.id + "t" + index}
          ListHeaderComponent={this.createTicketComponent}
          horizontal={this.state.device === "PHONE" ? true : false}
          ListEmptyComponent={this.ticketsEmpty}
        />
        {
          this.state.newTicket ? 
          this.newTicket()
          :
          <View style={{flex:this.state.device === "PHONE" ? 4 : 2,marginLeft: 10, marginTop: 20, marginRight: 20,borderTopRightRadius: 15, borderTopLeftRadius: 15,backgroundColor:Colors.white.alpha1,padding:16}}>
            <FlatList
              renderItem={this.renderMessages}
              data={this.state.messages}
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, backgroundColor: Colors.white.alpha1}}
              ListHeaderComponent={this.messageHeader}
              ListFooterComponent={this.messageFooter}
              ListEmptyComponent={this.emptyMessage}
              keyExtractor={(item, index) => "msg" + index + "f"}
            />
          </View>
          
        }
        
        <Loading ref={ref => this.loading = ref} text={"Destek yazışmaları alınıyor"}/>
      </View>
    )
  }
};


const styles=StyleSheet.create({
  container: {
    flex: 1,
  },
  userInfoRow: {
    flexDirection: "row",
    padding: 16,
    borderColor: Colors.grayLight.alpha05,    
  },
  createTicketContainer:{
    borderColor: Colors.grayLight.alpha05,
    justifyContent:"center",
    alignItems:"center"
  },
  createTicket:{
    borderStyle:"dashed",
    borderColor:Colors.gray.alpha05,
    borderWidth:2,
    flex:1,
    justifyContent:"center",
    alignItems:"center",
    padding:10,
    borderRadius:10,
    width:"100%"
  },
  isAnsweredContainer: {
    marginTop: 10,
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  messageInfo: {
    flex: 1,
    height: "100%",
    paddingLeft:8
  },
  messageInput: {
    paddingHorizontal: 16,
    height: 200,
    width: "90%",
    borderWidth: 1,
    borderColor: Colors.grayLight.alpha1,
    borderRadius: 15,
    backgroundColor: Colors.grayLight.alpha02,
    paddingVertical: 20,
    outlineWidth: 0,
  },
  messageHeaderContainer: {
    backgroundColor: "white",
    paddingBottom:8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayLight.alpha1,
    flexDirection: "row",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    justifyContent: "space-between",
    alignItems: "center"
  },
  sendButton:{
    justifyContent:"center",
    alignItems:"center",
    height:"50%",
    alignSelf:"center",
    paddingHorizontal:20
  },
  ticketTitleInput:{
    width:"60%",
    paddingVertical:16,
    paddingHorizontal: 16,
    backgroundColor:Colors.grayLight.alpha1,
    borderRadius:10,
    outlineWidth:0
  },
  newTicketContainer:{
    backgroundColor: Colors.white.alpha1, 
    marginLeft: 10, 
    marginTop: 20, 
    marginRight: 20, 
    borderTopRightRadius: 15, 
    borderTopLeftRadius: 15, 
    padding: 16
  },
  searchUser:{
    paddingHorizontal:16,
    backgroundColor:Colors.grayLight.alpha1,
    borderRadius:10,
    outlineWidth:0,
    flexDirection:"row",
    alignItems:"center",
    flex:1
  },
  newTicketBodyInput:{
    width:"100%",
    backgroundColor:Colors.grayLight.alpha05,
    flex:1,
    borderRadius:10,
    outlineWidth:0,
    paddingVertical:16,
    paddingHorizontal:16,
    marginTop:20,
    marginBottom:30,
    zIndex:-100
  },
  sendNewTicket:{
    paddingVertical:10,
    paddingHorizontal:10,
    flexDirection:"row",
    backgroundColor:Colors.greenLight.alpha1,
    borderRadius:10,
    alignItems:"center",
    marginLeft:16,
    borderWidth:2,
    borderColor:Colors.white.alpha03
  },
  userSearchRow:{
    paddingVertical:12,
    paddingHorizontal:16,
    borderBottomWidth:1,
    borderColor:Colors.gray.alpha06,
    flexDirection:"row",
    alignItems:"center"
    
  },
  searchList:{
    maxHeight:SCREEN_HEIGHT *0.4, 
    position: "absolute",
    backgroundColor:Colors.grayLight.alpha1,
    zIndex:100,
    top:48,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10
  },
  deleteUser:{
    width:30,
    height:30,
    borderRadius:15,
    borderWidth:2,
    borderColor:Colors.white.alpha05,
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:Colors.red.alpha1,
    marginRight:8
  },
  banButton:{
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderRadius:4
  }
})
