import * as React from 'react';
import { Image, View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons"
import { G, Line } from 'react-native-svg'
import { PieChart, YAxis, XAxis } from 'react-native-svg-charts'
import * as shape from 'd3-shape'
import moment from 'moment'  
import { Text } from 'react-native-svg'

function TrackingGamePieChart(props) {
  let data = props.data
  let pieData = []
  let totalGame = 0
  for (let j = 0; j < data.length; j++) {

    totalGame += data[j].data.length;
  }

  for (let i = 0; i < data.length; i++) {
    let obj = {}
    obj["arc"] = { outerRadius: (80 + ((data[i].data.length/totalGame)*30)) + '%', padAngle: 0, innerRadius: 30 }
    obj["key"] = (i + 1)
    obj["amount"] = data[i].data.length
    obj["svg"] = { fill: [Colors.greenLight.alpha1,Colors.greenLight.alpha05][i%2] }
    pieData.push(obj)
  }
  pieData.sort((a,b) => {return b.amount - a.amount})

  const Labels=({ slices, height, width }) => {
    return slices.map((slice, index) => {
      const { labelCentroid, pieCentroid, data }=slice;
      return (
        <Text
          key={index}
          x={pieCentroid[0]}
          y={pieCentroid[1]}
          fill={'white'}
          textAnchor={'middle'}
          alignmentBaseline={'middle'}
          fontSize={20}
        >
          {data.amount}
        </Text>
      )
    })
  }

  return (
    <PieChart
      style={{ flex: 1, width: "100%", height: "100%" }}
      valueAccessor={({ item }) => {return item.amount}}
      data={pieData}
      spacing={2}
      outerRadius={'90%'}
      
    >
      <Labels />
    </PieChart>
  )
}


const styles=StyleSheet.create({
  chartContainer: {
    backgroundColor: Colors.white.alpha1,
    paddingHorizontal: 15,
    paddingVertical: 15,
    margin: 15,
    alignSelf: "baseline",
    borderRadius: 15,
    flex: 1,
    minWidth: 300
  },
})

export default TrackingGamePieChart;