import React, { PureComponent } from 'react';
import {FlatList, StyleSheet, View, Text, TouchableOpacity, ScrollView, ActivityIndicator} from 'react-native';
import * as Device from "expo-device";
import Loading from "../components/Loading";
import API from "../utils/API";
import {CommonActions} from "@react-navigation/native";
import Colors from "../utils/Colors";
import {Feather} from "@expo/vector-icons";
import FontStyles from "../utils/FontStyles";
import {PushNotificationTextInput} from "../components/PushNotificationTextInput";

const languages = [{
    key: 'en',
    trName: 'İngilizce'
},{
    key: 'tr',
    trName: 'Türkçe',
}]

export default class NewAssessmentPushNotification extends PureComponent {
    constructor(props) {
        super(props)
        this.state={
            device: "DESKTOP",
            newAssessmentNotifications: {'en': {standard: [""], stacked: [""], activeLifeQuestions: [""]}, 'tr': {standard: [""], stacked: [""], activeLifeQuestions: [""]}},
            anyChanges: false,
            selectedLanguage: 'en',
            selectedLanguageData: {standard: [""], stacked: [""], activeLifeQuestions: [""]},
            loading: false,
        }
    }

    getNewAssessmentPushNotifications = () => {
        this.loading && this.loading.show();
        setTimeout(()=>{
            API.getNewAssessmentPushNotifications()
                .then((res)=>{
                    res = this.transformDataFromApiFormat(res.newAssessmentPushNotifications);

                    console.log(res);

                    for(let {key: lang} of languages){
                        console.log(lang);
                        if(!res.hasOwnProperty(lang)){
                            res[lang] = {standard: [""], stacked: [""], activeLifeQuestions: [""]};
                        }
                        else{
                            if(res[lang].standard.length === 0){
                                res[lang].standard = [""];
                            }
                            if(res[lang].stacked.length === 0){
                                res[lang].stacked = [""];
                            }
                            if(res[lang].activeLifeQuestions.length === 0){
                                res[lang].activeLifeQuestions = [""];
                            }
                        }
                    }

                    this.setState({
                        newAssessmentNotifications: res,
                        anyChanges: false,
                        selectedLanguageData: res[this.state.selectedLanguage],
                    }, ()=>{
                        this.loading && this.loading.hide();
                    })

                }).catch(e => {
                this.loading && this.loading.hide();
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                }else{
                    console.log(e.errorMessage);
                }
            })
        }, 500);
    }

    componentDidMount() {
        this.getDeviceType();
        this.getNewAssessmentPushNotifications();
    }

    getDeviceType = async () => {
        let device=await Device.getDeviceTypeAsync();
        this.setState({ device: Device.DeviceType[device] })
    }

    changeLanguage = (lang) => {
        if(this.state.anyChanges && !confirm("Kaydedilmemiş değişiklikler var. Dili değiştirmek ve değişiklikleri kaybetmek istediğinizden emin misiniz?")){
            return;
        }

        this.setState({
            selectedLanguage: lang,
            anyChanges: false,
            selectedLanguageData: JSON.parse(JSON.stringify(this.state.newAssessmentNotifications[lang]))
        })
    }

    displayHeader = () => {
        return <View>
            <View style={{flexDirection: "row", flexWrap: 'wrap', paddingHorizontal: 12, borderBottomWidth: 1, borderColor: Colors.grayDark.alpha02}}>
                {
                    languages.map((item, idx)=>{
                        return <TouchableOpacity key={"tab"+idx} activeOpacity={0.7} style={{marginRight: 40, marginBottom: 12}} disabled={this.state.selectedLanguage === item.key} onPress={()=>this.changeLanguage(item.key)}>
                            <Text style={{
                                color: this.state.selectedLanguage === item.key ? Colors.greenLightDark.alpha1 : Colors.grayDark.alpha1,
                                fontSize: 20,
                                fontWeight: this.state.selectedLanguage === item.key ? 'bold' : "500",
                            }}>
                                {item.trName}
                            </Text>
                        </TouchableOpacity>
                    })
                }
            </View>
        </View>;
    }

    submitChanges = () => {
        this.setState({loading: true});

        let mainData = {};

        for(let {key: lang} of languages){
            let data;
            if(lang === this.state.selectedLanguage){
                data = JSON.parse(JSON.stringify(this.state.selectedLanguageData));
            }
            else{
                data = JSON.parse(JSON.stringify(this.state.newAssessmentNotifications[lang]));
            }

            for(let i = 0; i < data.standard.length; i++){
                data.standard[i] = data.standard[i].replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '');
                if(data.standard[i].length === 0){
                    data.standard.splice(i,1);
                }
            }
            for(let i = 0; i < data.stacked.length; i++){
                data.stacked[i] = data.stacked[i].replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '');
                if(data.stacked[i].length === 0){
                    data.stacked.splice(i,1);
                }
            }
            for(let i = 0; i < data.activeLifeQuestions.length; i++){
                data.activeLifeQuestions[i] = data.activeLifeQuestions[i].replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '');
                if(data.activeLifeQuestions[i].length === 0){
                    data.activeLifeQuestions.splice(i,1);
                }
            }


            mainData[lang] = data;
        }



        let dataInApiFormat = this.transformDataToApiFormat(mainData);
        console.log(dataInApiFormat);
        API.updateAllNewAssessmentPushNotifications(dataInApiFormat)
            .then(()=>{
                this.setState({loading: false})
                this.getNewAssessmentPushNotifications();
            })
            .catch(e=>{
                this.setState({loading: false})
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                }
                else{
                    alert("Bir hata oluştu: " + e.errorMessage);
                }
            })
    }

    transformDataToApiFormat = (data) => {
        let newFormat = [];
        for(let lang of Object.keys(data)){
            newFormat.push({
                language: lang,
                standard: data[lang].standard,
                stacked: data[lang].stacked,
                activeLifeQuestions: data[lang].activeLifeQuestions,
            })
        }
        return newFormat;
    }

    transformDataFromApiFormat = (data) => {
        let newFormat = {};
        for(let d of data){
            newFormat[d.language] = {standard: d.standard, stacked: d.stacked, activeLifeQuestions: d.activeLifeQuestions};
        }
        return newFormat;
    }


    render() {

        return <View style={{flex:1, paddingTop: this.state.device === "PHONE" ? 10 : 20 }}>
            <ScrollView style={{flex: 1, alignSelf: 'stretch'}}
                      contentContainerStyle={styles.container}
                      ref={ref=>this.scrollView=ref}>

                {this.displayHeader()}

                <Text style={[FontStyles.body, {marginTop: 40, marginBottom:20, fontWeight: '600'}]}>
                    Normal Durumda
                </Text>

                {
                    this.state.selectedLanguageData.standard.map((item, index)=>{
                        return <PushNotificationTextInput
                            key={"texts-standard-"+index}
                            value={this.state.selectedLanguageData.standard[index] || ""}
                            onChange={(text)=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.standard[index] = text;
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            }}
                            onRemove={(this.state.selectedLanguageData.standard.length > 1 || index !== 0) ? ()=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.standard.splice(index, 1);
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            } : null}
                        />;
                    })
                }
                <TouchableOpacity style={{alignSelf: 'flex-end', marginTop: 4, marginRight: 8}} activeOpacity={0.7}
                                   onPress={()=>{
                                       let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                       data.standard = [...data.standard, ""]
                                       this.setState({
                                           selectedLanguageData: data,
                                           anyChanges: true,
                                       })
                                   }}>
                    <Feather name={"plus"} size={20} color={Colors.green.alpha1}/>
                </TouchableOpacity>

                <View style={{height: 1, marginTop: 30, alignSelf: 'stretch'}} />

                <Text style={[FontStyles.body, {marginTop: 40, marginBottom:20, fontWeight: '600'}]}>
                    Testlerin Biriktiği Durumda
                </Text>

                {
                    this.state.selectedLanguageData.stacked.map((item, index)=>{
                        return <PushNotificationTextInput
                            key={"texts-standard-"+index}
                            value={this.state.selectedLanguageData.stacked[index] || ""}
                            onChange={(text)=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.stacked[index] = text;
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            }}
                            onRemove={(this.state.selectedLanguageData.stacked.length > 1 || index !== 0) ? ()=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.stacked.splice(index, 1);
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            } : null}
                        />;
                    })
                }
                <TouchableOpacity style={{alignSelf: 'flex-end', marginTop: 4, marginRight: 8}} activeOpacity={0.7}
                                  onPress={()=>{
                                      let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                      data.stacked = [...data.stacked, ""]
                                      this.setState({
                                          selectedLanguageData: data,
                                          anyChanges: true,
                                      })
                                  }}>
                    <Feather name={"plus"} size={20} color={Colors.green.alpha1}/>
                </TouchableOpacity>

                <View style={{height: 1, marginTop: 30, alignSelf: 'stretch'}} />

                <Text style={[FontStyles.body, {marginTop: 40, marginBottom:20, fontWeight: '600'}]}>
                    Aktif Yaşam Anketi Geldiğinde
                </Text>

                {
                    this.state.selectedLanguageData.activeLifeQuestions.map((item, index)=>{
                        return <PushNotificationTextInput
                            key={"texts-standard-"+index}
                            value={this.state.selectedLanguageData.activeLifeQuestions[index] || ""}
                            onChange={(text)=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.activeLifeQuestions[index] = text;
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            }}
                            onRemove={(this.state.selectedLanguageData.activeLifeQuestions.length > 1 || index !== 0) ? ()=>{
                                let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                data.activeLifeQuestions.splice(index, 1);
                                this.setState({
                                    selectedLanguageData: data,
                                    anyChanges: true,
                                });
                            } : null}
                        />;
                    })
                }
                <TouchableOpacity style={{alignSelf: 'flex-end', marginTop: 4, marginRight: 8}} activeOpacity={0.7}
                                  onPress={()=>{
                                      let data = JSON.parse(JSON.stringify(this.state.selectedLanguageData))
                                      data.activeLifeQuestions = [...data.activeLifeQuestions, ""]
                                      this.setState({
                                          selectedLanguageData: data,
                                          anyChanges: true,
                                      })
                                  }}>
                    <Feather name={"plus"} size={20} color={Colors.green.alpha1}/>
                </TouchableOpacity>


                <TouchableOpacity style={[styles.sendButton, {marginTop: 36, backgroundColor: this.state.anyChanges ? Colors.greenLight.alpha1 : Colors.grayDark.alpha07,}]} onPress={this.submitChanges} disabled={!this.state.anyChanges} activeOpacity={0.9}>
                    {
                        this.state.loading ?
                            <ActivityIndicator color={"white"}/>
                            :
                            <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                                Kaydet
                            </Text>
                    }
                </TouchableOpacity>


                <View style={{height: 50, width: 1}}/>

            </ScrollView>

            <Loading ref={ref => this.loading = ref} text={"Yeni değerlendirme oyunu bildirimleri getiriliyor"} />
        </View>;
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        marginLeft: 12,
        paddingTop: 20,
    },
    sendButton: {
        justifyContent: "center",
        alignItems: "center",
        alignSelf: 'flex-end',
        height: 40,
        width: 120,
        borderRadius: 5,
        paddingHorizontal: 10,
        marginRight: 12,
    },
})