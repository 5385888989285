import React from "react";
import {Animated, TextInput, TouchableOpacity, View} from "react-native";
import Colors from "../utils/Colors";
import {Feather} from "@expo/vector-icons";

export class PushNotificationTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        let value = this.props.value || "";

        this.remainingCharAnimValue = new Animated.Value(178 - value.length);
        this.remainingCharColorAnim = this.remainingCharAnimValue.interpolate({
            inputRange: [0, 60, 178],
            outputRange: ['rgb(217, 52, 52)', 'rgb(191, 148, 46)', 'rgb(54, 181, 47)'],
            extrapolate: 'clamp',
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.remainingCharAnimValue.setValue(Math.max(178 - this.props.value.length, 0));
    }

    render() {
        return <View style={{
            marginBottom: 10,
            marginTop: 0,
            borderRadius: 10,
            paddingRight: 12,
            paddingTop: 12,
        }}>
            <TextInput
                style={{
                    borderRadius: 4,
                    width: '100%',
                    fontSize: 16,
                    backgroundColor: Colors.white.alpha1,
                    borderWidth: 0.5,
                    borderColor: '#cccccc',
                    padding: 8,
                    minHeight: 100,
                    paddingRight: 32,
                    paddingLeft: 12,
                    paddingVertical: 12,
                    color: Colors.dark.alpha1,
                    fontWeight: "400"
                }}
                value={this.props.value}
                maxLength={178}
                multiline
                onChangeText={(text) => {
                    text = text.replace(/\s/g, ' ')
                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(text);
                    }

                    this.remainingCharAnimValue.setValue(Math.max(178 - this.props.value.length, 0));
                }}
            />
            <Animated.Text style={{
                color: this.remainingCharColorAnim,
                position: 'absolute',
                fontWeight: '600',
                fontSize: 16,
                right: 20,
                bottom: 4
            }}>
                {Math.max(178 - this.props.value.length, 0)}
            </Animated.Text>
            {
                (typeof this.props.onRemove === 'function') &&
                <TouchableOpacity onPress={this.props.onRemove}
                                  activeOpacity={0.7}
                                  style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      height: 24,
                                      width: 24,
                                      borderRadius: 12,
                                      backgroundColor: Colors.red.alpha08,
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                  }}
                >
                    <Feather name={"x"} size={16} color={Colors.white.alpha1}/>
                </TouchableOpacity>
            }

        </View>
    }

}