import * as React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, FlatList } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import * as Device from 'expo-device';
import API from '../utils/API';
import { CommonActions } from '@react-navigation/native';
import Loading from "../components/Loading";
import {Hoverable} from "react-native-web-hover";
import {Feather} from "@expo/vector-icons";
import moment from "moment";

export default class Blog extends React.PureComponent {
    constructor(props) {
        super(props)
        this.text = ""
        this.state = {
            device: "DESKTOP",
            blogPosts: [],
            mediaBasePath: "",
        }
    }

    componentDidMount() {
        this.getDeviceType().then(()=>{}).catch(()=>{});
        this.loadBlogPosts().then(()=>{}).catch(()=>{});
    }

    getDeviceType = async () => {
        let device= await Device.getDeviceTypeAsync();
        this.setState({ device: Device.DeviceType[device] })
    }

    loadBlogPosts = async () => {
        API.getAllBlogPostsSummary()
            .then((response)=>{
                this.loading && this.loading.hide();
                this.setState({
                    blogPosts: response.posts,
                    mediaBasePath: response.mediaBasePath,
                }, () => {
                    if(this.flatList && this.state.blogPosts.length > 0){
                        let lastIndexToHavePublished = 0;
                        for(let i = 0; i < this.state.blogPosts.length; i++){
                            let blogPost = this.state.blogPosts[i];
                            if(new Date(blogPost.createdAt) < new Date()){ // first unpublished item is found
                                lastIndexToHavePublished = i;
                            }
                        }
                        this.flatList.scrollToIndex({index: lastIndexToHavePublished, viewPosition: 0, viewOffset: 50, animated: true});
                    }
                })
            })
            .catch((e)=>{
                this.loading && this.loading.hide();
                if(e.errorCode === 7){
                    alert(e.errorMessage);
                    this.props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: "Login" }]
                        })
                    )
                }
            })
    }

    displayTimeWithTimezone = (time) => {
        let m = moment(time);
        let timezone = m.format('Z').split(":")[0];
        if (timezone.substr(1,1) === "0") {
            timezone = timezone.substr(0,1) + timezone.substr(2);
        }
        return m.format("Do MMMM YYYY , HH:mm") + " (" + timezone + ")"
    }

    displayHeader = () => {
        return (
            <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12,}}>
                <Text style={[FontStyles.body, {fontWeight: '500'}]}>
                    Toplam: {this.state.blogPosts.length}
                </Text>
                <TouchableOpacity activeOpacity={0.7} onPress={()=>{this.props.navigation.navigate('BlogDetail',{slug:'create'})}}>
                    <Feather name={"plus"} size={28} color={Colors.green.alpha1}/>
                </TouchableOpacity>
            </View>
        );
    }
    
    displayFooter = () => {
        return this.state.blogPosts.length >= 10 ? this.displayHeader() : null
    }

    renderBlogPostRow = ({item}) => {
        let post = item;
        return <View style={{marginTop: 20, marginBottom: 20,}}>
            <Hoverable key={post.id}>
                {({ hovered }) => (
                    <TouchableOpacity activeOpacity={0.7} onPress={() => {this.props.navigation.navigate("BlogDetail", {slug: post.language + "---" + post.slug})}} style={[styles.rowContainer,
                        hovered ? {backgroundColor: Colors.greenLight.alpha01} :
                            { backgroundColor: Colors.white.alpha1}
                    ]}
                    >
                        <Image resizeMode="cover" style={{flex:3}} source={this.state.mediaBasePath + post.media}/>
                        <View style={{flex:7, alignItems: 'center', justifyContent: 'center', padding: 40,}}>
                            <Text style={[FontStyles.body, {lineHeight:'150%', textAlign: 'center'}]}>
                                {post.title}
                            </Text>
                            <Text style={[FontStyles.caption1, {color: Colors.gray.alpha06, marginTop: 20, textAlign: 'center'}]}>
                                <Text style={{fontWeight: '600'}}>Yayınlanma Tarihi: </Text>
                                {this.displayTimeWithTimezone(post.createdAt)}
                                {
                                    new Date(post.createdAt) > new Date() ?
                                        <View style={{marginLeft: 10}}>
                                            <Feather name={"clock"} size={16} color={Colors.green.alpha08}/>
                                        </View>
                                        : null
                                }
                            </Text>

                            <View style={{position: 'absolute', bottom: 10, right: 10}}>
                                <Text style={[FontStyles.caption2,{color: Colors.gray.alpha07}]}>{post.subtitle}</Text>
                            </View>
                            <View style={{position: 'absolute', top: 10, right: 10}}>
                                <Text style={[FontStyles.caption2,{color: Colors.gray.alpha09}]}>{post.language.toUpperCase()}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                )}
            </Hoverable>
        </View>
    }

    render() {
        return (
            <View style={{flex: 1}}>
                <FlatList style={{flex: 1, alignSelf: 'stretch'}}
                          contentContainerStyle={styles.container}
                          ref={ref=>this.flatList=ref}
                          data={this.state.blogPosts}
                          renderItem={this.renderBlogPostRow}
                          keyExtractor={(post)=>post.id}
                          ListHeaderComponent={this.displayHeader()}
                          ListFooterComponent={this.displayFooter()}
                          maxToRenderPerBatch={10000}
                          initialNumToRender={10000}
                           getItemLayout={(data, index) => (
                               {length: 200, offset: 240 * index, index} // this enables us to scroll to the last published item. for scroll to work, we need to give the item heights to flat list
                           )}
                />
                <Loading ref={ref => this.loading = ref} text={"Blog yazıları isteniyor"} />
            </View>
        )
    }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20,
        alignItems: 'stretch',
        alignSelf: 'center',
        maxWidth: 900,
        minWidth: 600,
    },

    blogContainer: {
        marginBottom: 20,
        backgroundColor: Colors.white.alpha1,
        marginTop: 20,
        borderRadius: 10
    },
    rowContainer: {
        height: 200,
        flexDirection: 'row',
        padding: 0,
        borderRadius: 5,
        alignItems: 'stretch',
        overflow: 'hidden',
        borderColor: 'transparent',
    }
})