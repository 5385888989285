import * as React from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { G, Line } from 'react-native-svg'
import { LineChart, YAxis, XAxis } from 'react-native-svg-charts'
import moment from 'moment'
const axesSvg={ fontSize: 9, fill: 'grey' };
const CustomGrid=({ x, y, data, ticks }) => (
  <G>
    {// Horizontal grid
      ticks.map((tick) => (
        <Line key={tick} x1={'0%'} x2={'100%'} y1={y(tick)} y2={y(tick)} stroke={'rgba(0,0,0,0.1)'} />
      ))}
    {// Vertical grid
      data.map((_, index) => (
        <Line key={index} y1={'0%'} y2={'100%'} x1={x(data[index].date)} x2={x(data[index].date)} stroke={'rgba(0,0,0, 0.1)'} />
      ))}
  </G>
)

function ActiveLifeLineChart(props) {
  return (
    <View style={[styles.chartContainer, props.containerStyle]}>
      <Text style={[FontStyles.caption1, {flex: 1, fontWeight: "600", color: Colors.grayDark.alpha1 }]}>
        {props.title}
      </Text>

      {
        !props.data?
          <ActivityIndicator style={{ height: 150 }} color={Colors.greenLight.alpha1} size="large" />
        :
        <View style={{ flexDirection: "row", height: 300, overflow: "visible", marginTop: 20, }}>
            <View style={{ alignItems: "center", marginHorizontal: 5}}>
              {
                [10,9,8,7,6,5,4,3,2,1].map((item, index) => {
                  return (
                    <Text key={item+"q"} style={[FontStyles.caption1, {fontSize: 11, flex: 1, color: Colors.gray.alpha1,}]}>
                      {item}
                    </Text>
                  )
                })
              }
            </View>  
            <View style={{ flex: 1, marginLeft: 5 }}>
              <LineChart
                style={{ flex: 1 }}
                data={props.data}
                numberOfTicks={10}
                yMax={10}
                yMin={1}
                yAccessor={({ item,index }) => { return item.value }}
                svg={{ stroke: Colors.greenLight.alpha1, strokeWidth: 3 }}
                contentInset={{ top: 10, left: 5, right: 5, bottom: 2 }}
              >
                <CustomGrid belowChart={true} />
              </LineChart>
              
              <View style={{flexDirection: "row", alignItems: "center", width: "100%", height: 20, marginTop: 5, marginHorizontal: 5}}>
                {
                  props.data.map((item, index) => {
                    return (
                      <Text key={item.date+"qr"+index} style={[FontStyles.caption1, {fontSize: 9, flex: 1, color: Colors.gray.alpha1,}]}>
                        {moment(item.date).format('D/M')}
                      </Text>
                    )
                  })
                }
              </View>  
            </View>
          </View>
      }
      
    </View>
  );
}

const styles=StyleSheet.create({
  chartContainer: {
    backgroundColor: Colors.white.alpha1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 15,
    borderRadius: 15,
    flex: 1,
    minWidth: 250
  },
})

export default ActiveLifeLineChart;