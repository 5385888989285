export default {
  white: {
    alpha1: 'rgba(255,255,255, 1.0)',
    alpha09: 'rgba(255,255,255, .9)',
    alpha08: 'rgba(255,255,255, .8)',
    alpha07: 'rgba(255,255,255, .7)',
    alpha06: 'rgba(255,255,255, .6)',
    alpha05: 'rgba(255,255,255, .5)',
    alpha04: 'rgba(255,255,255, .4)',
    alpha03: 'rgba(255,255,255, .3)',
    alpha02: 'rgba(255,255,255, .2)',
    alpha01: 'rgba(255,255,255, .1)'
  },
  backgroundLight: {
    alpha1: 'rgba(247,249,251, 1)',
  },
  cream: {
    alpha1: 'rgba(247,249,251, 1)',
    alpha09: 'rgba(247,249,251, .9)',
    alpha08: 'rgba(247,249,251, .8)',
    alpha07: 'rgba(247,249,251, .7)',
    alpha06: 'rgba(247,249,251, .6)',
    alpha05: 'rgba(247,249,251, .5)',
    alpha04: 'rgba(247,249,251, .4)',
    alpha03: 'rgba(247,249,251, .3)',
    alpha02: 'rgba(247,249,251, .2)',
    alpha01: 'rgba(247,249,251, .1)'
  },
  red: {
    alpha1: 'rgba(237, 85, 101, 1)',   //#ED5665
    alpha09: 'rgba(237, 85, 101, .9)',
    alpha08: 'rgba(237, 85, 101, .8)',
    alpha07: 'rgba(237, 85, 101, .7)',
    alpha06: 'rgba(237, 85, 101, .6)',
    alpha05: 'rgba(237, 85, 101, .5)',
    alpha04: 'rgba(237, 85, 101, .4)',
    alpha03: 'rgba(237, 85, 101, .3)',
    alpha02: 'rgba(237, 85, 101, .2)',
    alpha01: 'rgba(237, 85, 101, .1)',
    alpha015: 'rgba(237, 85, 101, .15)',
  },
  dark: {
    alpha1: 'rgba(47, 46, 65, 1.0)',
    alpha09: 'rgba(47, 46, 65, .9)',
    alpha08: 'rgba(47, 46, 65, .8)',
    alpha07: 'rgba(47, 46, 65, .7)',
    alpha06: 'rgba(47, 46, 65, .6)',
    alpha05: 'rgba(47, 46, 65, .5)',
    alpha04: 'rgba(47, 46, 65, .4)',
    alpha03: 'rgba(47, 46, 65, .3)',
    alpha02: 'rgba(47, 46, 65, .2)',
    alpha01: 'rgba(47, 46, 65, .1)',
    alpha005: 'rgba(47, 46, 65, .05)',
    alpha025: 'rgba(47, 46, 65, .025)'
  },


  
  //secondary dark colors
  grayLight: {
    alpha1: 'rgba(230, 233, 237, 1.0)',
    alpha09: 'rgba(230, 233, 237, .9)',
    alpha08: 'rgba(230, 233, 237, .8)',
    alpha07: 'rgba(230, 233, 237, .7)',
    alpha06: 'rgba(230, 233, 237, .6)',
    alpha05: 'rgba(230, 233, 237, .5)',
    alpha04: 'rgba(230, 233, 237, .4)',
    alpha03: 'rgba(230, 233, 237, .3)',
    alpha02: 'rgba(230, 233, 237, .2)',
    alpha01: 'rgba(230, 233, 237, .1)',
    alpha005: 'rgba(230, 233, 237, .05)',
    alpha025: 'rgba(230, 233, 237, .025)'
  },
  gray: {
    alpha1: 'rgba(101, 109, 120, 1.0)',
    alpha09: 'rgba(101, 109, 120, .9)',
    alpha08: 'rgba(101, 109, 120, .8)',
    alpha07: 'rgba(101, 109, 120, .7)',
    alpha06: 'rgba(101, 109, 120, .6)',
    alpha05: 'rgba(101, 109, 120, .5)',
    alpha04: 'rgba(101, 109, 120, .4)',
    alpha03: 'rgba(101, 109, 120, .3)',
    alpha02: 'rgba(101, 109, 120, .2)',
    alpha01: 'rgba(101, 109, 120, .1)',
    alpha005: 'rgba(101, 109, 120, .05)',
    alpha025: 'rgba(101, 109, 120, .025)'
  },
  grayDark: {
    alpha1: 'rgba(67, 74, 84, 1.0)',
    alpha09: 'rgba(67, 74, 84, .9)',
    alpha08: 'rgba(67, 74, 84, .8)',
    alpha07: 'rgba(67, 74, 84, .7)',
    alpha06: 'rgba(67, 74, 84, .6)',
    alpha05: 'rgba(67, 74, 84, .5)',
    alpha04: 'rgba(67, 74, 84, .4)',
    alpha03: 'rgba(67, 74, 84, .3)',
    alpha02: 'rgba(67, 74, 84, .2)',
    alpha01: 'rgba(67, 74, 84, .1)',
    alpha005: 'rgba(67, 74, 84, .05)',
    alpha025: 'rgba(67, 74, 84, .025)'
  },



  //other colors
  green: {
    alpha1: 'rgba(41, 178, 88, 1.0)',
    alpha09: 'rgba(41, 178, 88, .9)',
    alpha09: 'rgba(41, 178, 88, .8)',
    alpha08: 'rgba(41, 178, 88, .7)',
    alpha07: 'rgba(41, 178, 88, .6)',
    alpha06: 'rgba(41, 178, 88, .5)',
    alpha05: 'rgba(41, 178, 88, .4)',
    alpha04: 'rgba(41, 178, 88, .4)',
    alpha03: 'rgba(41, 178, 88, .3)',
    alpha02: 'rgba(41, 178, 88, .2)',
    alpha01: 'rgba(41, 178, 88, .1)'
  },
  greenDark: {
    alpha1: 'rgba(140, 193, 82, 1.0)',
    alpha09: 'rgba(140, 193, 82, .9)',
    alpha09: 'rgba(140, 193, 82, .8)',
    alpha08: 'rgba(140, 193, 82, .7)',
    alpha07: 'rgba(140, 193, 82, .6)',
    alpha06: 'rgba(140, 193, 82, .5)',
    alpha05: 'rgba(140, 193, 82, .4)',
    alpha04: 'rgba(140, 193, 82, .4)',
    alpha03: 'rgba(140, 193, 82, .3)',
    alpha02: 'rgba(140, 193, 82, .2)',
    alpha01: 'rgba(140, 193, 82, .1)'
  },
  yellow: {
    alpha1: 'rgba(255, 206, 84, 1)',
    alpha09: 'rgba(255, 206, 84, .9)',
    alpha08: 'rgba(255, 206, 84, .8)',
    alpha07: 'rgba(255, 206, 84, .7)',
    alpha06: 'rgba(255, 206, 84, .6)',
    alpha05: 'rgba(255, 206, 84, .5)',
    alpha04: 'rgba(255, 206, 84, .4)',
    alpha03: 'rgba(255, 206, 84, .3)',
    alpha02: 'rgba(255, 206, 84, .2)',
    alpha01: 'rgba(255, 206, 84, .1)'
  },
  yellowDark: {
    alpha1: 'rgba(246, 187, 66, 1.0)',
    alpha09: 'rgba(246, 187, 66, .9)',
    alpha08: 'rgba(246, 187, 66, .8)',
    alpha07: 'rgba(246, 187, 66, .7)',
    alpha06: 'rgba(246, 187, 66, .6)',
    alpha05: 'rgba(246, 187, 66, .5)',
    alpha04: 'rgba(246, 187, 66, .4)',
    alpha03: 'rgba(246, 187, 66, .3)',
    alpha02: 'rgba(246, 187, 66, .2)',
    alpha01: 'rgba(246, 187, 66, .1)'
  },
  blue: {
    alpha1: 'rgba(93, 156, 236, 1.0)',
    alpha09: 'rgba(93, 156, 236, .9)',
    alpha09: 'rgba(93, 156, 236, .8)',
    alpha08: 'rgba(93, 156, 236, .7)',
    alpha07: 'rgba(93, 156, 236, .6)',
    alpha06: 'rgba(93, 156, 236, .5)',
    alpha05: 'rgba(93, 156, 236, .4)',
    alpha04: 'rgba(93, 156, 236, .4)',
    alpha03: 'rgba(93, 156, 236, .3)',
    alpha02: 'rgba(93, 156, 236, .2)',
    alpha01: 'rgba(93, 156, 236, .1)'
  },
  blueDark: {
    alpha1: 'rgba(74, 137, 220, 1.0)',
    alpha09: 'rgba(74, 137, 220, .9)',
    alpha09: 'rgba(74, 137, 220, .8)',
    alpha08: 'rgba(74, 137, 220, .7)',
    alpha07: 'rgba(74, 137, 220, .6)',
    alpha06: 'rgba(74, 137, 220, .5)',
    alpha05: 'rgba(74, 137, 220, .4)',
    alpha04: 'rgba(74, 137, 220, .4)',
    alpha03: 'rgba(74, 137, 220, .3)',
    alpha02: 'rgba(74, 137, 220, .2)',
    alpha01: 'rgba(74, 137, 220, .1)'
  },
  aqua: {
    alpha1: 'rgba(79, 193, 233, 1.0)',
    alpha09: 'rgba(79, 193, 233, .9)',
    alpha09: 'rgba(79, 193, 233, .8)',
    alpha08: 'rgba(79, 193, 233, .7)',
    alpha07: 'rgba(79, 193, 233, .6)',
    alpha06: 'rgba(79, 193, 233, .5)',
    alpha05: 'rgba(79, 193, 233, .4)',
    alpha04: 'rgba(79, 193, 233, .4)',
    alpha03: 'rgba(79, 193, 233, .3)',
    alpha02: 'rgba(79, 193, 233, .2)',
    alpha01: 'rgba(79, 193, 233, .1)'
  },
  aquaDark: {
    alpha1: 'rgba(59, 175, 218, 1.0)',
    alpha09: 'rgba(59, 175, 218, .9)',
    alpha09: 'rgba(59, 175, 218, .8)',
    alpha08: 'rgba(59, 175, 218, .7)',
    alpha07: 'rgba(59, 175, 218, .6)',
    alpha06: 'rgba(59, 175, 218, .5)',
    alpha05: 'rgba(59, 175, 218, .4)',
    alpha04: 'rgba(59, 175, 218, .4)',
    alpha03: 'rgba(59, 175, 218, .3)',
    alpha02: 'rgba(59, 175, 218, .2)',
    alpha01: 'rgba(59, 175, 218, .1)'
  },
  orange: {
    alpha1: 'rgba(252, 110, 81, 1.0)',
    alpha09: 'rgba(252, 110, 81, .9)',
    alpha09: 'rgba(252, 110, 81, .8)',
    alpha08: 'rgba(252, 110, 81, .7)',
    alpha07: 'rgba(252, 110, 81, .6)',
    alpha06: 'rgba(252, 110, 81, .5)',
    alpha05: 'rgba(252, 110, 81, .4)',
    alpha04: 'rgba(252, 110, 81, .4)',
    alpha03: 'rgba(252, 110, 81, .3)',
    alpha02: 'rgba(252, 110, 81, .2)',
    alpha01: 'rgba(252, 110, 81, .1)'
  },
  orangeDark: {
    alpha1: 'rgba(233, 87, 63, 1.0)',
    alpha09: 'rgba(233, 87, 63, .9)',
    alpha09: 'rgba(233, 87, 63, .8)',
    alpha08: 'rgba(233, 87, 63, .7)',
    alpha07: 'rgba(233, 87, 63, .6)',
    alpha06: 'rgba(233, 87, 63, .5)',
    alpha05: 'rgba(233, 87, 63, .4)',
    alpha04: 'rgba(233, 87, 63, .4)',
    alpha03: 'rgba(233, 87, 63, .3)',
    alpha02: 'rgba(233, 87, 63, .2)',
    alpha01: 'rgba(233, 87, 63, .1)'
  },
  pink: {
    alpha1: 'rgba(236, 135, 192, 1.0)',
    alpha09: 'rgba(236, 135, 192, .9)',
    alpha09: 'rgba(236, 135, 192, .8)',
    alpha08: 'rgba(236, 135, 192, .7)',
    alpha07: 'rgba(236, 135, 192, .6)',
    alpha06: 'rgba(236, 135, 192, .5)',
    alpha05: 'rgba(236, 135, 192, .4)',
    alpha04: 'rgba(236, 135, 192, .4)',
    alpha03: 'rgba(236, 135, 192, .3)',
    alpha02: 'rgba(236, 135, 192, .2)',
    alpha01: 'rgba(236, 135, 192, .1)'
  },
  pinkDark: {
    alpha1: 'rgba(215, 112, 173, 1.0)',
    alpha09: 'rgba(215, 112, 173, .9)',
    alpha09: 'rgba(215, 112, 173, .8)',
    alpha08: 'rgba(215, 112, 173, .7)',
    alpha07: 'rgba(215, 112, 173, .6)',
    alpha06: 'rgba(215, 112, 173, .5)',
    alpha05: 'rgba(215, 112, 173, .4)',
    alpha04: 'rgba(215, 112, 173, .4)',
    alpha03: 'rgba(215, 112, 173, .3)',
    alpha02: 'rgba(215, 112, 173, .2)',
    alpha01: 'rgba(215, 112, 173, .1)'
  },
  greenLight: {
    alpha1: 'rgba(72, 207, 173, 1.0)', //#48cfad
    alpha09: 'rgba(72, 207, 173, .9)', 
    alpha08: 'rgba(72, 207, 173, .8)',
    alpha07: 'rgba(72, 207, 173, .7)',
    alpha06: 'rgba(72, 207, 173, .6)',
    alpha05: 'rgba(72, 207, 173, .5)',
    alpha04: 'rgba(72, 207, 173, .4)',
    alpha03: 'rgba(72, 207, 173, .3)',
    alpha02: 'rgba(72, 207, 173, .2)',
    alpha01: 'rgba(72, 207, 173, .1)',
  },
  greenLightDark: {
    alpha1: 'rgba(55, 188, 155, 1.0)', //#37bc9b
    alpha09: 'rgba(55, 188, 155, .9)',
    alpha08: 'rgba(55, 188, 155, .8)',
    alpha07: 'rgba(55, 188, 155, .7)',
    alpha06: 'rgba(55, 188, 155, .6)',
    alpha05: 'rgba(55, 188, 155, .5)',
    alpha04: 'rgba(55, 188, 155, .4)',
    alpha03: 'rgba(55, 188, 155, .3)',
    alpha02: 'rgba(55, 188, 155, .2)',
    alpha01: 'rgba(55, 188, 155, .1)',
  },
  lightGreen: {
    alpha1: 'rgba(236, 250, 247, 1.0)',
    alpha09: 'rgba(236, 250, 247, .9)',
    alpha08: 'rgba(236, 250, 247, .8)',
    alpha07: 'rgba(236, 250, 247, .7)',
    alpha06: 'rgba(236, 250, 247, .6)',
    alpha05: 'rgba(236, 250, 247, .5)',
    alpha04: 'rgba(236, 250, 247, .4)',
    alpha03: 'rgba(236, 250, 247, .3)',
    alpha02: 'rgba(236, 250, 247, .2)',
    alpha01: 'rgba(236, 250, 247, .1)',
  },
  lightGreenDark: {
    alpha1: 'rgba(218, 245, 239, 1.0)',
    alpha09: 'rgba(218, 245, 239, .9)',
    alpha09: 'rgba(218, 245, 239, .8)',
    alpha08: 'rgba(218, 245, 239, .7)',
    alpha07: 'rgba(218, 245, 239, .6)',
    alpha06: 'rgba(218, 245, 239, .5)',
    alpha05: 'rgba(218, 245, 239, .4)',
    alpha04: 'rgba(218, 245, 239, .4)',
    alpha03: 'rgba(218, 245, 239, .3)',
    alpha02: 'rgba(218, 245, 239, .2)',
    alpha01: 'rgba(218, 245, 239, .1)'
  },
  lavanderDark: {
    alpha1: 'rgba(150, 122, 220, 1.0)',
    alpha09: 'rgba(150, 122, 220, .9)',
    alpha09: 'rgba(150, 122, 220, .8)',
    alpha08: 'rgba(150, 122, 220, .7)',
    alpha07: 'rgba(150, 122, 220, .6)',
    alpha06: 'rgba(150, 122, 220, .5)',
    alpha05: 'rgba(150, 122, 220, .4)',
    alpha04: 'rgba(150, 122, 220, .4)',
    alpha03: 'rgba(150, 122, 220, .3)',
    alpha02: 'rgba(150, 122, 220, .2)',
    alpha01: 'rgba(150, 122, 220, .1)'
  },
  lavender: {
    alpha1: 'rgba(172, 146, 236,  1.0)',
    alpha09: 'rgba(172, 146, 236,  .9)',
    alpha09: 'rgba(172, 146, 236,  .8)',
    alpha08: 'rgba(172, 146, 236,  .7)',
    alpha07: 'rgba(172, 146, 236,  .6)',
    alpha06: 'rgba(172, 146, 236,  .5)',
    alpha05: 'rgba(172, 146, 236,  .4)',
    alpha04: 'rgba(172, 146, 236,  .4)',
    alpha03: 'rgba(172, 146, 236,  .3)',
    alpha02: 'rgba(172, 146, 236,  .2)',
    alpha01: 'rgba(172, 146, 236,  .1)'
  }
};

