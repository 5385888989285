import * as React from 'react';
import { Image, View, Text, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons"
import { G, Line } from 'react-native-svg'
import { LineChart, YAxis, XAxis } from 'react-native-svg-charts'
import * as shape from 'd3-shape'
import moment from 'moment'
const axesSvg={ fontSize: 9, fill: 'grey' };
const CustomGrid=({ x, y, data, ticks }) => (
  <G>
    {// Horizontal grid
      ticks.map((tick) => (
        <Line key={tick} x1={'0%'} x2={'100%'} y1={y(tick)} y2={y(tick)} stroke={'rgba(0,0,0,0.1)'} />
      ))}
    {// Vertical grid
      data.map((_, index) => (
        <Line key={index} y1={'0%'} y2={'100%'} x1={x(data[index].date)} x2={x(data[index].date)} stroke={'rgba(0,0,0, 0.1)'} />
      ))}
  </G>
)

function NewUserChartCard(props) {
  return (
    <View style={styles.chartContainer}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={[FontStyles.subhead, { flex: 1, fontWeight: "600", color: Colors.grayDark.alpha1 }]}>
          {props.title}
        </Text>
        <TouchableOpacity onPress={() => props.reloadData()} activeOpacity={0.9} style={{ paddingLeft: 50 }}>
          <Feather size={22} color={Colors.greenLightDark.alpha1} name={"refresh-cw"} />
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: "row", marginTop: 10, justifyContent: "space-evenly", alignItems: "center" }}>
        <View style={{ alignItems: "center" }}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            Toplam
          </Text>
          <Text style={[FontStyles.body, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats.totalUserCount||"-"}
          </Text>
        </View>

        <View style={{ alignItems: "center" }}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            Bu Hafta
          </Text>
          <Text style={[FontStyles.body, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats.thisWeekNewUserCount||"-"}
          </Text>
        </View>

        <View style={{ alignItems: "center" }}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            Son 24s
          </Text>
          <Text style={[FontStyles.body, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats.last24HoursNewUserCount||"-"}
          </Text>
        </View>

        <View style={{ alignItems: "center" }}>
          <Text style={[FontStyles.caption2, { color: Colors.gray.alpha05, fontWeight: "600" }]}>
            Bugün
          </Text>
          <Text style={[FontStyles.body, { color: Colors.gray.alpha1, marginTop: 5, fontWeight: "900" }]}>
            {props.genericStats.todayNewUserCount||"0"}
          </Text>
        </View>
      </View>

      {
        !props.data?
          <ActivityIndicator style={{ height: 300 }} color={Colors.greenLight.alpha1} size="large" />
          :
          <View style={{ flexDirection: "row", height: 300, overflow: "visible", marginTop: 20, }}>
            <YAxis
              data={props.data}
              style={{ marginBottom: 15 }}
              contentInset={{ top: 10, bottom: 2}}
              svg={axesSvg}
              min={0}
              yAccessor={({ item }) => { return item.value }}
              formatLabel={(value) => `${value}`}
            />
            <View style={{ flex: 1, marginLeft: 5 }}>
              <LineChart
                style={{ flex: 1 }}
                data={props.data}
                yMin={0}
                animate={false}
                yAccessor={({ item }) => item.value}
                xAccessor={({ item }) => item.date}
                numberOfTicks={15}
                svg={{ stroke: Colors.greenLight.alpha1, strokeWidth: 3 }}
                contentInset={{ top: 10, left: 5, right: 5, bottom: 2 }}
              >
                <CustomGrid belowChart={true} />
              </LineChart>
              <XAxis
                style={{ marginTop: 5 }}
                data={props.data}
                xAccessor={({ item }) => item.date}
                formatLabel={(value) => { return moment.unix(value).format('DD') }}
                contentInset={{ left: 5, right: 5 }}
                svg={axesSvg}
              // svg={{
              //   fill: '#A4AAB3',
              //   fontSize: 9,
              //   fontWeight: '500',
              //   originY: 30,
              //   y: 5,
              // }}
              />
            </View>
          </View>
      }
    </View>
  );
}

const styles=StyleSheet.create({
  chartContainer: {
    backgroundColor: Colors.white.alpha1,
    paddingHorizontal: 15,
    paddingVertical: 15,
    margin: 15,
    alignSelf: "baseline",
    borderRadius: 15,
    flex: 1,
    minWidth: 300
  },
})

export default NewUserChartCard;