import * as React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, TextInput, ActivityIndicator, Animated, Image} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import * as Device from 'expo-device';
import { ScrollView } from 'react-native-gesture-handler';
import API from '../utils/API';
import { CommonActions } from '@react-navigation/native';
import {Feather} from "@expo/vector-icons";
import {Hoverable} from "react-native-web-hover";

export default class PushNotification extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state={
      device: "DESKTOP",
      language: "tr",
      loading: false,
      audience: "EVERYBODY",
      checked: false,
      text: ""
    }

    this.remainingCharAnimValue = new Animated.Value(178);
    this.remainingCharColorAnim = this.remainingCharAnimValue.interpolate({
      inputRange: [0, 60, 178],
      outputRange: ['rgb(217, 52, 52)', 'rgb(191, 148, 46)', 'rgb(54, 181, 47)'],
      extrapolate: 'clamp',
    })
  }

  componentDidMount() {
    this.getDeviceType()
  }

  sendPush = () => {
    this.setState({checked: false});
    if(confirm("Bildirimi göndermek istediğinize emin misiniz?\nBu aksiyon geri alınamaz!\nİyice kontrol ediniz.")){
      this.setState({loading: true}, () => {
        let textReplaced = this.state.text.replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '');
        API.sendPush(textReplaced, this.state.language, this.state.audience).then((response) => {
          this.setState({loading: false}, () => {
            alert("Bildirim başarıyla gönderildi!")
          })
        }).catch((e) => {
          this.setState({loading: false}, () => {
            if(e.errorCode === 7){
              alert('Oturumunuz kapatılmış veya süresi dolmuş. Tekrar giriş yapmak için yönlendiriliyorsunuz.');
              this.props.navigation.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{ name: "Login" }]
                  })
              )
            }
            else{
              alert(e.errorMessage);
            }
          })
        })
      })
    }
  }

  getDeviceType = async () => {
    let device=await Device.getDeviceTypeAsync();
    this.setState({ device: Device.DeviceType[device] })
  }

  onChangeText = (text) => {
    this.setState({text:text.replace(/\s/g, ' ')});
    if(this.state.checked){
      this.setState({checked:false})
    }

    this.remainingCharAnimValue.setValue(Math.max(178-text.length, 0));
  }

  render() {
    return (
      <View style={{flex: 1}}>
        <ScrollView
          style={{flex: 1}}
          contentContainerStyle={{paddingBottom: 30}}
        >
          <View style={[styles.container, { paddingTop: this.state.device==="PHONE"? 10:20 }]}>
            <View style={{flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap', marginBottom: 12,}}>

              <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity style={{marginTop: 10,
                      marginBottom: 12,
                      justifyContent: "center",
                      alignItems: "center",
                      height: 40,
                      width: 120,
                      borderRadius: 5,
                      borderWidth: 0.5,
                      borderColor: hovered ? Colors.grayDark.alpha01 : Colors.grayDark.alpha05,
                      marginLeft: 16,
                      backgroundColor: hovered ? Colors.grayDark.alpha01 : 'transparent',
                      textAlign: 'center'
                    }}
                                      onPress={()=>{this.props.navigation.navigate("PlannedPushNotification")}} activeOpacity={0.7}>

                      <Text style={[FontStyles.caption1, {color: Colors.grayDark.alpha06, fontWeight: "600" }]}>
                        {"Planlanmış Bildirimler"}
                      </Text>
                    </TouchableOpacity>
                )}
              </Hoverable>

              <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity style={{marginTop: 10,
                      marginBottom: 12,
                      justifyContent: "center",
                      alignItems: "center",
                      height: 40,
                      width: 160,
                      borderRadius: 5,
                      borderWidth: 0.5,
                      borderColor: hovered ? Colors.grayDark.alpha01 : Colors.grayDark.alpha05,
                      marginLeft: 16,
                      backgroundColor: hovered ? Colors.grayDark.alpha01 : 'transparent',
                      textAlign: 'center'
                    }}
                                      onPress={()=>{this.props.navigation.navigate("NewAssessmentPushNotification")}} activeOpacity={0.7}>

                      <Text style={[FontStyles.caption1, {color: Colors.grayDark.alpha06, fontWeight: "600" }]}>
                        {"Yeni Değerlendirme Oyunu Bildirimleri"}
                      </Text>
                    </TouchableOpacity>
                )}
              </Hoverable>


            </View>

            <View style={styles.pushContainer}>
              <TextInput
                multiline
                placeholder={"Bildirim metnini buraya yazınız. (Maksimum 178 karakter)"}
                style={styles.textInput}
                maxLength={178}
                selectionColor={Colors.grayLight.alpha1}
                onChangeText={this.onChangeText}
              />
              <Animated.Text ref={ref=>this.remainingCharText=ref} style={{color: this.remainingCharColorAnim, position:'absolute', fontWeight: '600', fontSize: 16, right: 14, bottom: 10}}>
                {Math.max(178 - this.state.text.length, 0)}
              </Animated.Text>
            </View>
            <View style={styles.languageContainer}>
              <Text style={[FontStyles.subhead, {flex: 1, color:Colors.dark.alpha1}]}>
                Bildirim Dili:
              </Text>

              <View style={styles.buttonContainer}>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({language: "tr",checked:false})} style={[styles.languageButton, {backgroundColor: this.state.language == "tr" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.language == "tr" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Türkçe
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({language: "en",checked:false})} style={[styles.languageButton, {backgroundColor: this.state.language == "en" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.language == "en" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    İngilizce
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.audienceContainer}>
              <Text style={[FontStyles.subhead, {flex: 1, color:Colors.dark.alpha1}]}>
                Kitle:
              </Text>

              <View style={styles.buttonContainer}>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({audience: "EVERYBODY",checked:false})} style={[styles.audienceButton, {backgroundColor: this.state.audience == "EVERYBODY" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.audience == "EVERYBODY" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Herkes
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({audience: "PREMIUM",checked:false})} style={[styles.audienceButton, {backgroundColor: this.state.audience == "PREMIUM" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.audience == "PREMIUM" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Premium Üyeler
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({audience: "NON-PREMIUM",checked:false})} style={[styles.audienceButton, {backgroundColor: this.state.audience == "NON-PREMIUM" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.audience == "NON-PREMIUM" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Ücretsiz Üyeler
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.lastBitsContainer}>
              <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}} activeOpacity={0.9} onPress={()=>{this.setState({checked: !this.state.checked})}}>
                <Text style={FontStyles.caption1}>Bildirim metnini ve parametreleri kontrol ettim.</Text>
                  <View style={{marginLeft: 8, borderRadius: 5, height: 20, width: 20, paddingBottom: 4, paddingHorizontal: 2, borderColor: Colors.dark.alpha03, borderWidth: 0.5}}>
                    {
                      this.state.checked ? <Text style={[{fontSize: 17, fontWeight: 'bold', color: Colors.greenLight.alpha1}]}>✓</Text> : <Text> </Text>
                    }
                  </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.sendButton, {marginTop: 10}]} onPress={this.sendPush} activeOpacity={0.9}>
                {
                  this.state.loading ?
                      <ActivityIndicator color={"white"}/>
                      :
                      <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                        {"Gönder"}
                      </Text>
                }
              </TouchableOpacity>
            </View>
            
          </View>
        </ScrollView>
      </View>
    )
  }
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20
  },
  buttonContainer: {
    backgroundColor: Colors.grayLight.alpha05,
    borderRadius: 5, 
    flexDirection: "row",
    alignItems: "center",
  },
  languageButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 34,
    width: 100,
    borderRadius: 5,
  },
  audienceButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 34,
    width: 140,
    borderRadius: 5,
  },

  sendButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 120,
    borderRadius: 5,
    backgroundColor: Colors.greenLight.alpha1,
  },
  languageContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.white.alpha1,
  },
  pushContainer: {
    marginBottom: 20,
    backgroundColor: Colors.white.alpha1,
    marginTop: 20,
    borderRadius: 10
  },
  textInput: {
    minHeight: 100,
    paddingHorizontal: 15,
    paddingVertical: 15,
    color:Colors.dark.alpha1,
    fontWeight: "400"
  },
  
  header: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    alignSelf: "center",
    justifyContent: "flex-end",
    overflow: "visible"
  },
  goBackButton: {
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  audienceContainer: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.white.alpha1,
  },
  lastBitsContainer: {
    marginTop: 20,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.white.alpha1,
  },

})