import * as React from "react";

export default function DatePicker({onChange, style, value, min}) {

    let props = {
        type: 'date',
        onInput: onChange,
        style: style || {},
        pattern: "\d{4}-\d{2}-\d{2}",
        placeholder: "yyyy-mm-dd",
    }
    if(value){
        props.value = value;
    }
    if(min){
        props.min = min;
    }

    return React.createElement('input', props)
}