import * as React from 'react';
import { Image, View, Text, TouchableOpacity, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import { Feather, Entypo, AntDesign } from "@expo/vector-icons";
import API from "../utils/API.js";
import {CommonActions} from "@react-navigation/native";

export default class Login extends React.PureComponent {
  constructor(props) {
    super(props)
    this.email = ""
    this.password = ""
    this.mfaCode = ""

    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    API.getCurrentUser()
        .then(()=>{
          // API.getUserFromServer()
          //     .then(()=>{
          //       this.props.navigation.dispatch(
          //           CommonActions.reset({
          //             index: 0,
          //             routes: [{ name: "Home" }]
          //           })
          //       )
          //     })
          //     .catch(()=>{

          //     });
        })
        .catch(()=>{

        });
  }

  login = () => {
    console.log(this.email)
    console.log(this.password)
    console.log(this.mfaCode)
    if(/^[0-9]{6,8}$/.test(this.mfaCode) === false){
      alert('OTP Kodu 6, 7 veya 8 haneli olmalıdır.');
      return;
    }

    this.setState({loading: true}, () => {
      API.login(this.email, this.password, this.mfaCode).then((response) => {
        if(response.user.role == "ADMIN"){
          this.props.navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: "Home" }]
              })
          )
        }else{
          alert("Sadece admin yetkisi olanlar giriş yapabilir.\nLütfen admin yetkisi olan bir hesap ile giriş yapmayı deneyin.")
          API.logout().then(() => {}).catch(() => {})
          this.setState({loading: false})
        }
      }).catch((e) => {
        alert(e.errorMessage)
        this.setState({loading: false})
      })
    })
  }


  render() {
    return (
      <View style={styles.container}>
        <Image style={styles.backImage} blurRadius={10} resizeMode="cover" source={require("../assets/images/loginBg.png")}/>
        <View style={styles.loginContainer}>
          <Text style={[FontStyles.largeText, {color: Colors.white.alpha1, fontWeight: "800"}]}>
            Beynex
          </Text>
          <Text style={[FontStyles.body, {color: Colors.white.alpha1, textAlign: "center", marginTop: "5%", fontWeight: "400"}]}>
            Beynex admin paneline hoş geldiniz.
          </Text>
          <View style={{flexDirection: "row", width: "100%", marginTop: "10%", alignItems: "center", borderRadius: 5, paddingHorizontal: "5%", backgroundColor: Colors.white.alpha1}}>
            <Feather name={"user"} size={20} color={Colors.gray.alpha1}/>
            <TextInput
              selectionColor={Colors.greenLightDark.alpha1}
              placeholderTextColor={Colors.gray.alpha07}
              style={[FontStyles.footnote, styles.emailInput]}
              placeholder={"E-posta veya Kullanıcı Adı"}
              keyboardType={"email-address"}
              onChangeText={text => this.email = text}
              returnKeyType={"next"}
              onSubmitEditing={()=>{this.passwordInput.focus();}}
              textContentType={"username"}
              importantForAutofill={"yes"}
            />
          </View>
          <View style={{flexDirection: "row", width: "100%", marginTop: "5%", alignItems: "center", borderRadius: 5, paddingHorizontal: "5%", backgroundColor: Colors.white.alpha1}}>
            <Feather name={"lock"} size={20} color={Colors.gray.alpha1}/>
            <TextInput
                ref={ref=>{this.passwordInput=ref}}
              selectionColor={Colors.greenLightDark.alpha1}
              placeholderTextColor={Colors.gray.alpha07}
              style={[FontStyles.footnote, styles.emailInput]}
              placeholder={"Şifre"}
              secureTextEntry
              keyboardType={"default"}
              onChangeText={text => this.password = text}
              returnKeyType={"next"}
                onSubmitEditing={()=>this.mfaInput.focus()}
                textContentType={"password"}
                importantForAutofill={"yes"}
            />
          </View>
          <View style={{flexDirection:'row',width: '100%',marginTop: "5%", alignItems:'center'}}>
            <View style={{flexDirection: "row", width: "100%",  alignItems: "center", borderRadius: 5, paddingHorizontal: "5%", backgroundColor: Colors.white.alpha1}}>
              <Entypo name={"dial-pad"} size={20} color={Colors.gray.alpha1}/>
              <TextInput
                  ref={ref=>this.mfaInput=ref}
                selectionColor={Colors.greenLightDark.alpha1}
                placeholderTextColor={Colors.gray.alpha07}
                style={[FontStyles.footnote, styles.emailInput]}
                placeholder={"OTP Kodu"}
                keyboardType={"number-pad"}
                onChangeText={text => this.mfaCode = text}
                returnKeyType={"send"}
                onKeyPress={(event)=> {if(event.nativeEvent.key === 'Enter'){this.login()}}}
                  textContentType={"oneTimeCode"}
                  importantForAutofill={"no"}
              />
              
            </View>
            <TouchableOpacity style={{width: 28, height: 28, marginLeft: 12 ,backgroundColor:'white',borderRadius: 14, justifyContent: 'center', alignItems:'center'}} onPress={()=>alert("Beynex'te yükseltilmiş yetkilere sahip hesapların Çok Faktörlü Kimlik Doğrulama ile korunması zorunludur. Bu özelliği aktifleştirmediyseniz ofisimizle iletişime geçin.")}>
              <AntDesign name='question' size={20}/>
            </TouchableOpacity>
          </View>

          
          <View style={{marginTop: "5%", width: "100%"}}>
            <TouchableOpacity activeOpacity={0.9} onPress={this.login} disabled={this.state.loading} style={styles.loginButton}>
              {
                this.state.loading ? 
                <ActivityIndicator color={"white"}/>
                :
                <Text style={[FontStyles.footnote, {color: Colors.white.alpha1, textAlign: "center", fontWeight: "600"}]}>
                  Giriş Yap
                </Text>
              }
            </TouchableOpacity>
          </View>
          
        </View>
      </View>
    )
  }
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "black",
  },
  backImage: {
    position: "absolute",
    zIndex: -20,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  emailInput: {
    color: Colors.dark.alpha1,
    marginLeft: "5%",
    width: "100%",
    outline: "none",
    outlineWidth: 0,
    paddingVertical: 15,
    paddingHorizontal : "2.5%",
  },
  loginContainer: {
    paddingHorizontal: "5%",
    paddingVertical: "2.5%",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  loginButton: {
    backgroundColor: Colors.greenLight.alpha1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: "5%",
    paddingHorizontal: "5%",
    borderRadius: 40,
    width: "100%",
    marginTop: 16,
    minHeight: 52,
  }

})